import React, {
    useEffect,
    useCallback,
    useState,
    useReducer,
    useRef,
    useMemo,
    forwardRef,
  } from "react";
  import ReactDOM from "react-dom";
  import moment from "moment";
  import { useNavigate } from "react-router-dom";
  // import { useProSidebar } from "react-pro-sidebar";
  import { CompSideBar } from "../components/sidebar";
  import { Row, Col, Card, Button } from "react-bootstrap";
  import reach_us_img from "../images/contact-us.png";
  // import AddBox from '@material-ui/icons/AddBox';
  import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@material-ui/icons";
  import MaterialTable from "material-table";
  import product1 from "../images/products/product-image.png";

  import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
  import {
    WalletDisconnectButton,
    WalletMultiButton,
  } from "@solana/wallet-adapter-react-ui";
  
  import "./my-product.css";


  interface RowData {
    productName: string;
    id: number; // Assuming 'id' is of type number
    selectedSize: string;
    selectedColor: string;
    createdDate: string; // You may need to specify the correct type
    unitSold: number; // Assuming 'unitSold' is of type number
    profitMargin: number; // Assuming 'profitMargin' is of type number
    productStatus: string; // You may need to specify the correct type
    imagesFront:string;
    // Add more properties as needed
  }
  

export const MyProducts = ({publicKey, userData}) => {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    // const { collapseSidebar } = useProSidebar();
    
    
    const tableIcons:any = {
      Add: forwardRef((props:any, ref:any) => <AddBox {...props} {...ref} />),
      Check: forwardRef((props:any, ref:any) => <Check {...props} {...ref} />),
      Clear: forwardRef((props:any, ref:any) => <Clear {...props} {...ref} />),
      Delete: forwardRef((props:any, ref:any) => <DeleteOutline {...props} {...ref} />),
      DetailPanel: forwardRef((props:any, ref:any) => <ChevronRight {...props} {...ref} />),
      Edit: forwardRef((props:any, ref:any) => <Edit {...props} {...ref} />),
      Export: forwardRef((props:any, ref:any) => <SaveAlt {...props} {...ref} />),
      Filter: forwardRef((props:any, ref:any) => <FilterList {...props} {...ref} />),
      FirstPage: forwardRef((props:any, ref:any) => <FirstPage {...props} {...ref} />),
      LastPage: forwardRef((props:any, ref:any) => <LastPage {...props} {...ref} />),
      NextPage: forwardRef((props:any, ref:any) => <ChevronRight {...props} {...ref} />),
      PreviousPage: forwardRef((props:any, ref:any) => <ChevronLeft {...props} {...ref} />),
      ResetSearch: forwardRef((props:any, ref:any) => <Clear {...props} {...ref} />),
      Search: forwardRef((props:any, ref:any) => <Search {...props} {...ref} />),
      SortArrow: forwardRef((props:any, ref:any) => <ArrowDownward {...props} {...ref} />),
      ThirdStateCheck: forwardRef((props:any, ref:any) => <Remove {...props} {...ref} />),
      ViewColumn: forwardRef((props:any, ref:any) => <ViewColumn {...props} {...ref} />)
    };
    const [storeId, setStoreId] = useState('');
    const [allProduct, setAllProduct] = useState([]);
    

    const getProducts = async () => {
      console.log(userData);
      if(publicKey){
          try {
          const response = await fetch(process.env.REACT_APP_API_URL + "GetMyDesignProduct",
              { 
                  method: "POST",
                  mode: "cors",
                  headers: new Headers({ "content-type": "application/json" }),
                  body: JSON.stringify({ "role": userData['role'], 'walletID': userData['walletID'], 'user_id': userData['id'] }),
              }
          );
          if (response.status === 200) {
              const data = await response.json();
              setAllProduct(data['products']);
              console.log(data);
          } else {
              console.log(response.status);
          }
          } catch (e) {
              console.log(e);
          }
      }
  };


    const DeleteRowProduct = async (e, productID) => {
      console.log(productID);
      // const response = await fetch(process.env.REACT_APP_API_URL + "DeleteDesignProduct", {
      //     method: "POST",
      //     mode: "cors",
      //     headers: new Headers({ "content-type": "application/json" }),
      //     body: JSON.stringify({id:productID}),
      // });
      // if (response.status === 200) {
      //   getProductByStoreID(storeId);
      // } else {
      //     console.log();
      // }
    }
    const EditRowProduct = async (e, productID) => {
      navigate("/edit-product/"+productID);
    }

    const AddNewProduct  = () => {
      navigate('/add-products')
    }

    useEffect(() => {
      async function data() {
        await getProducts()
      }
      data();
    }, [publicKey]);
    
return (
    <>
    {true ? (
    <>
        <div>
            <CompSideBar publicKey={publicKey} />
        </div>
        <div className="mainpage-dashboard">
            <div className="container-fluid">
                <div className="dashboard-title">
                    <h1 className="sora-bold">My Products</h1>
                </div>
                <div className="dashboard-design">
                    <Row>
                        <Col sm={7}>
                            <div className="prize-background-div">
                                <div className="prize-background">
                                    <Card className="yellow-card fixed-height">
                                        <Card.Body>
                                            <div className="design-content">
                                                <Row>
                                                    <Col sm={7}>
                                                        <h4 className="sora-bold">Design and Sell Products</h4>
                                                        <p className="sora-regular">Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </p>
                                                    </Col>
                                                    <Col sm={5}>
                                                        <Button className="shawdow-btn" onClick={AddNewProduct} variant="primary">+ Create Product</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                        <Col sm={5}>
                        </Col>
                    </Row>
                </div>
                <div className="order-details-header">
                    <h2 className="sora-bold">All Products</h2>
                </div>
                <div className="order-details-cards">
                    <Row>
                        <Col sm={12}>
                        <MaterialTable 
                          title="Product Details" 
                          data={allProduct} 
                          columns={[
                            { title: 'Product', field: 'productName', width: "30%", render: (rowData: RowData) => 
                              <div style={{ display: "inline-flex" }}>
                                <div className="table-product-image" style={{ marginRight: "10px" }}>
                                  <img src={rowData.imagesFront} />
                                </div>
                                <div className="table-product-detail">
                                  <a className="table-product-name" href={`/store-product-detail/${rowData.id}`}>{rowData.productName}</a>
                                  <h6>Size: {rowData.selectedSize} </h6>
                                  <h6>Colour: {rowData.selectedColor}</h6>
                                </div>
                              </div>
                            },
                            { title: 'Created Date', field: 'createdDate', width: "10%", render: (rowData: RowData) => <>{rowData.createdDate.split("T")[0]}</>},
                            { title: 'Unit Sold', field: 'unitSold', width: "10%" },
                            { title: 'Profit Margin', field: 'profitMargin', width: "10%" },
                            { title: 'Product Status', field: 'productStatus', width: "10%" },
                            { title: 'Action', width: "30%", render: (rowData: RowData) => 
                              <div className="table-actions">
                                <Button className="tableEdit" variant="primary" onClick={(event) => EditRowProduct(event, rowData.id)} >
                                  Edit 
                                </Button>
                                <Button className="tableEdit" variant="primary" onClick={(event) => DeleteRowProduct(event, rowData.id)} >
                                  Delete 
                                </Button>
                              </div>
                            },
                          ]}
                          icons={tableIcons} 
                          options={{
                            exportButton: true
                          }}
                        />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    </>
    ): (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${
                isMobile
                  ? "px-5"
                  : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
              }`}
            >
              <div
                className={`${
                  isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                }`}
              >
                <div
                  className={`${
                    isMobile
                      ? ""
                      : "d-flex flex-center flex-column flex-column-fluid"
                  }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
)}