import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
} from "react";

import { useNavigate } from "react-router-dom";

import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import reach_us_img from "../images/contact-us.png";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "./create-product-list.css";
import { Country } from "country-state-city";
import { State } from "country-state-city";
import S3 from "react-aws-s3";
import getAccessToken from "../util/access-token.js";

interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}
interface ProductData {
  baseName: string;
  walletID:string;
  availableColors:string;
  availableSize:string;
  imageFront:string;
  imageBack:string;
  sizeChart:string;
  displayImage:string;
  printFrontDimension:string;
  printBackDimension:string;
  // Add other properties here
}

export const CreateProductList = () => {
  const [whiteList, setWhiteList] = useState({});
  const [currencyDecimal, setCurrencyDecimal] = useState("");
  const [currencyAddress, setCurrencyAddress] = useState("");
  const navigate = useNavigate();
  const [allCategories, setallCategories] = useState([]);
  const [allCurrency, setallCurrency] = useState([]);
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [product_data, setData] = useState<ProductData>({
    baseName: "",
    walletID:"",
    availableColors:"",
    availableSize:"",
    imageFront:"",
    imageBack:"",
    sizeChart:"",
    displayImage:"",
    printFrontDimension:"",
    printBackDimension:"",
    // Initialize other properties here
  });
  const animatedComponents = makeAnimated();
  const colorList = [
    { value: "Red", label: "Red" },
    { value: "Blue", label: "Blue" },
    { value: "Green", label: "Green" },
    { value: "Black", label: "Black" },
    { value: "White", label: "White" },
    { value: "Yellow", label: "Yellow" },
  ];
  const sizeList = [
    { value: "32", label: "32" },
    { value: "34", label: "34" },
    { value: "36", label: "36" },
    { value: "38", label: "38" },
    { value: "40", label: "40" },
    { value: "42", label: "42" },
    { value: "44", label: "44" },
  ];
  const [selectedColors, setSelectedColors] = useState([]);
  const [selectedSize, setSelectedSize] = useState([]);
  const [availableColors, setAvailableColors] = useState<string[]>([]);
  const [availableSize, setAvailableSize] = useState<string[]>([]);

  const [selectedFilName, setselectedFilName] = useState("");
  const [uploadedFile, setuploadedFile] = useState<File | undefined>(undefined);

  const address = new PublicKey("wEEdop2S1t7TLLeikEJ3awAWq9ty6wmf9v8wiS7beBB");
  const amount = 1000000000; // Adjust for SPL decimals

  const [file, setFile] = useState<any | null>(null);
  const fileInput: any = useRef();
  const [fileNameFrReq, setFileNameFrReq] = useState<any | null>(null);

  /* Mockup Image */
  // Front Mockup
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [rect, setRect] = useState<Rect>({ x: 0, y: 0, width: 0, height: 0 });
  const canvasRef = useRef(null);

  // Back Mockup
  const [image1, setImage1] = useState<HTMLImageElement | null>(null);
  const [rect1, setRect1] = useState<Rect>({ x: 0, y: 0, width: 0, height: 0 });
  const canvasRef1 = useRef(null);

  const [displayImage, setDisplayImage] = useState("");
  const [imageFront, setImageFront] = useState("");
  const [sizeChart, setSizeChart] = useState("");
  const [imageBack, setImageBack] = useState("");

  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    async function fetchAccessToken() {
      const token = await getAccessToken(publicKey?.toString());
      console.log(token);
      setAccessToken(token);
    }
    fetchAccessToken();
  }, [publicKey]);

  useEffect(() => {
    const canvas = canvasRef.current as unknown as HTMLCanvasElement;
    if (canvas instanceof HTMLCanvasElement) {
      const context = canvas.getContext("2d");
      if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        if (image) {
          context.drawImage(image, 0, 0, canvas.width, canvas.height);
        }
        // Draw the rectangle on the canvas
        if (rect && 'x' in rect && 'y' in rect && 'width' in rect && 'height' in rect) {
          context.strokeStyle = "#FFC300";
          context.lineWidth = 2;
          context.setLineDash([2, 2]); // set the line dash pattern
          context.strokeRect(rect.x, rect.y, rect.width, rect.height);
        }
      }
    }
  }, [image, rect, canvasRef]);

  useEffect(() => {
    const canvas1 = canvasRef1.current as unknown as HTMLCanvasElement; 
    if (canvas1 instanceof HTMLCanvasElement) {
      const context1 = canvas1.getContext("2d");
      if (context1) {
        context1.clearRect(0, 0, canvas1.width, canvas1.height);
        if (image1) {
          context1.drawImage(image1, 0, 0, canvas1.width, canvas1.height);
        }
        // Draw the rectangle on the canvas
        if (rect1 && 'x' in rect1 && 'y' in rect1 && 'width' in rect1 && 'height' in rect1) {
          context1.strokeStyle = "#FFC300";
          context1.lineWidth = 2;
          context1.setLineDash([2, 2]); // set the line dash pattern
          context1.strokeRect(rect1.x, rect1.y, rect1.width, rect1.height);
        }
      }
    }
  }, [image1, rect1, canvasRef1]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, image_type:any) => {
    console.log(image_type);
    if (event.target.files && event.target.files.length > 0) {
      let fileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );

      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        setuploadedFile(event.target.files[0]);

        if (image_type == "display_image") {
          setDisplayImage(event.target.files[0].name);
        } else if (image_type == "front_mockup_image") {
          setImageFront(event.target.files[0].name);
        } else if (image_type == "size_chart_image") {
          setSizeChart(event.target.files[0].name);
        }

        setselectedFilName(event.target.files[0].name);
        console.log(event.target.files[0].name);
        setFile(event.target.files[0].name);

        let uploadedImage = event.target.files[0];
        let imageObjectURL = URL.createObjectURL(uploadedImage);
        let image = new Image(); // create a new Image object
        if (image_type == "front_mockup_image") {
          image.onload = () => {
            setImage(image); // set the state with the new Image object
          };
        }
        image.src = imageObjectURL; // set the src property of the Image object
        console.log("ddd");
        imageUpload();
      } else {
        event.target.value = "";
      }
    }
  };

  const handleImageUpload1 = (event: React.ChangeEvent<HTMLInputElement>, image_type:any) => {
    console.log(image_type);
    if (event.target.files && event.target.files.length > 0) {
      let fileExtension = event.target.files[0].name.substring(
        event.target.files[0].name.lastIndexOf(".") + 1
      );

      if (
        fileExtension.toLowerCase() === "gif" ||
        fileExtension.toLowerCase() === "jpg" ||
        fileExtension.toLowerCase() === "jpeg" ||
        fileExtension.toLowerCase() === "png"
      ) {
        setuploadedFile(event.target.files[0]);

        if (image_type == "back_mockup_image") {
          setImageBack(event.target.files[0].name);
        }

        console.log(product_data);
        setselectedFilName(event.target.files[0].name);
        setFile(event.target.files[0].name);
        let uploadedImage1 = event.target.files[0];
        let imageObjectURL1 = URL.createObjectURL(uploadedImage1);
        let image1 = new Image(); // create a new Image object
        if (image_type == "back_mockup_image") {
          image1.onload = () => {
            setImage1(image1); // set the state with the new Image object
          };
        }
        image1.src = imageObjectURL1; // set the src property of the Image object
        console.log("ddd");
        imageUpload();
      } else {
        event.target.value = "";
      }
    }
  };

  const handleRectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRect((prevRect) => ({ ...prevRect, [name]: Number(value) }));
  };

  const handleRectChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    setRect1((prevRect) => ({ ...prevRect, [name]: Number(value) }));
  };

  /* Mockup Image End */

  const handleMultiSelectColor = (selectedOptions:any) => {
    let selectedOptionList:string[] = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      selectedOptionList.push(selectedOptions[i]["value"]);
    }
    setSelectedColors(selectedOptions);
    setAvailableColors(selectedOptionList);
  };
  const handleMultiSelectSize = (selectedOptions:any) => {
    let selectedOptionList:string[] = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      selectedOptionList.push(selectedOptions[i]["value"]);
    }
    setSelectedSize(selectedOptions);
    setAvailableSize(selectedOptionList);
  };

  const updateCurrencyData = (e : React.ChangeEvent<HTMLSelectElement>) => {
    const el = e.target.childNodes[e.target.selectedIndex] as HTMLOptionElement;
    const decimal = el.getAttribute("id");
    const address = el.getAttribute("data-address");
    setData({
      ...product_data,
      [e.target.name]: e.target.value,
    });
    if (decimal !== null) {
      setCurrencyDecimal(decimal);
    }
    if(address !== null){
      setCurrencyAddress(address);
    }
   
    // setData({
    //   ...product_data,
    //   ['currencyDecimal']: decimal,
    // });
  };

  const updateData = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...product_data,
      [e.target.name]: e.target.value,
    });
    console.log(product_data);
  };
  const updateSelectData = (e : React.ChangeEvent<HTMLSelectElement>) => {
    setData({
      ...product_data,
      [e.target.name]: e.target.value,
    });
    console.log(product_data);
  };
  // const categoryChange = (event) => {
  //   const category = event.target.value;
  //   setData({
  //     ...product_data,
  //     [event.target.name]: category,
  //   });
  // };

  const imageUpload = () => {
    console.log(file);
    if (file) {
      const folderPath = "base_product/";
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_ACCESS_ID,
        secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
        s3Url: process.env.REACT_APP_S3URL,
      };

      const ReactS3Client = new S3(config);
      ReactS3Client.uploadFile(
        uploadedFile,
        folderPath + product_data["baseName"] + "/" + file
      )
        .then((data:any) => {
          console.log("File uploaded successfully:", data.location);
        })
        .catch((error:any) => {
          console.error("Error uploading file:", error);
        });
    }
  };

  const formSubmit = (event: React.FormEvent) => {
    createProduct(product_data, availableColors, availableSize);
    // depositVaultX(product_data);
  };
  const createProduct = async (
    product_data:ProductData,
    availableColors:string[],
    availableSize:string[]
  ) => {
    if (!publicKey) return;
    try {
      product_data["walletID"] = publicKey.toString();
      product_data["availableColors"] = JSON.stringify(availableColors);
      product_data["availableSize"] = JSON.stringify(availableSize);
      product_data["imageFront"] = imageFront;
      product_data["imageBack"] = imageBack;
      product_data["sizeChart"] = sizeChart;
      product_data["displayImage"] = displayImage;
      product_data["printFrontDimension"] = JSON.stringify(rect);
      product_data["printBackDimension"] = JSON.stringify(rect1);
      console.log("product data");
      console.log(product_data);
      console.log(rect, rect1);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "create_baseProduct",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify(product_data),
        }
      );
      if (response.status === 200) {
        let product_resp = await response.json();
        console.log(product_resp);
        var r = window.confirm("Product Saved Successfully!");
        if (r == true) {
          // window.location.reload();
        }
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const getallCategories = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_category",
        {
          mode: "cors",
        }
      );
      if (response.status === 200) {
        let data = await response.json();
        await setallCategories(data["categories"]);
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const getallCurrency = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_currency",
        {
          mode: "cors",
        }
      );
      if (response.status === 200) {
        let data = await response.json();
        await setallCurrency(data["currency"]);
      } else {
        console.log("error");
      }
    } catch (e) {
      console.log(e);
      return;
    }
  };

 


  useEffect(() => {
    async function data() {
      await getallCategories();
      await getallCurrency();
    }
    data();
  }, [navigate]);

  return (
    <>
      {publicKey ? (
        <>
          <main className="main">
            <section className="container">
              <div className="row">
                <div className="col-12">
                  <div className="content-header">
                    <h2 className="content-title">Add New Product</h2>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <h4>Basic</h4>
                    </div>
                    <div className="card-body">
                      <form id="form1">
                        <div className="mb-4">
                          <label className="form-label">Listed By</label>
                          
                              <p>Wallet ID: {publicKey.toString()}</p>
                              {/* <div className="row">
                              <div className="col-lg-6">
                              <button className="btn btn-md rounded font-sm hover-up">Link Twitter</button>
                              </div>
                          </div> */}
                            
                          {/* <select
                            name="listingType"
                            className="form-select"
                            onChange={updateData}
                          >
                            <option selected disabled>
                              Select Listing Type
                            </option>
                            <option>DAO</option>
                            <option>Individual</option>
                          </select> */}
                        </div>
                        <div className="mb-4">
                          <label htmlFor="baseName" className="form-label">
                            Product Name
                          </label>
                          <input
                            name="baseName"
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="baseName"
                            onChange={updateData}
                          />
                        </div>
                        <div className="mb-4">
                          <label className="form-label">Gender</label>
                          <select
                            name="baseCategory"
                            className="form-select"
                            onChange={updateSelectData}
                          >
                            <option selected disabled>
                              Select Category
                            </option>

                            <option>Men</option>
                            <option>Women</option>
                            <option>Unisex</option>
                          </select>
                        </div>

                        <div className="mb-4">
                          <label htmlFor="baseName" className="form-label">
                            SKU Code
                          </label>
                          <input
                            name="sku"
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="sku"
                            onChange={updateData}
                          />
                        </div>

                        <div className="mb-4">
                          <label className="form-label">Product Category</label>
                          <select
                            name="baseCategory"
                            className="form-select"
                            onChange={updateSelectData}
                          >
                            <option selected disabled>
                              Select Category
                            </option>
                            {allCategories.map((category, index) => (
                              <option>{category["categoryName"]}</option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-4">
                          <label
                            htmlFor="designGuidelines"
                            className="form-label"
                          >
                            Design Guidelines
                          </label>
                          <input
                            name="designGuidelines"
                            type="text"
                            placeholder="Type here"
                            className="form-control"
                            id="designGuidelines"
                            onChange={updateData}
                          />
                        </div>

                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label className="form-label">Price</label>
                              <div className="row gx-2">
                                <input
                                  name="price"
                                  type="text"
                                  className="form-control"
                                  onChange={updateData}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">Currency</label>
                            <select
                              name="currency"
                              className="form-select"
                              onChange={updateCurrencyData}
                            >
                              <option selected disabled>
                                Select Currency
                              </option>
                              {allCurrency.map((currency, index) => (
                                <option
                                  id={currency["decimal"]}
                                  data-address={currency["address"]}
                                >
                                  {currency["tokenName"]}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        {/* <div className="mb-4">
                                        <label className="form-label">Tax rate</label>
                                        <input type="text" placeholder="%" className="form-control" id="product_name" />
                                    </div>
                                    <label className="form-check mb-4">
                                        <input className="form-check-input" type="checkbox" value="" />
                                        <span className="form-check-label"> Make a template </span>
                                    </label> */}
                      </form>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="card-header">
                      <h4>Media</h4>
                    </div>
                    <div className="card-body">
                      <div className="mb-4">
                        <div className="input-upload">
                          <label className="form-label">Display Image</label>
                          <img
                            src="../assets-v1/imgs/theme/upload.svg"
                            alt=""
                          />
                          <input
                            className="form-control"
                            type="file"
                            name="displayImage"
                            onChange={(e) =>
                              handleImageUpload(e, "display_image")
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="input-upload">
                          <label className="form-label">
                            Mock up Image Front
                          </label>
                          <img
                            src="../assets-v1/imgs/theme/upload.svg"
                            alt=""
                          />
                          <input
                            className="form-control"
                            type="file"
                            name="imageFront"
                            onChange={(e) =>
                              handleImageUpload(e, "front_mockup_image")
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="input-upload">
                          <label className="form-label">
                            Mock up Image Back
                          </label>
                          <img
                            src="../assets-v1/imgs/theme/upload.svg"
                            alt=""
                          />
                          <input
                            className="form-control"
                            type="file"
                            name="imageBack"
                            onChange={(e) =>
                              handleImageUpload1(e, "back_mockup_image")
                            }
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="input-upload">
                          <label className="form-label">Size Chart</label>
                          <img
                            src="../assets-v1/imgs/theme/upload.svg"
                            alt=""
                          />
                          <input
                            className="form-control"
                            type="file"
                            name="sizeChart"
                            onChange={(e) =>
                              handleImageUpload(e, "size_chart_image")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="card mb-4">
                    <div className="card-header">
                      <h4>Shipping</h4>
                    </div>
                    <div id="shipping-fields" className="card-body">
                      <div className="mb-4">
                        <label
                          htmlFor="shippingCostIndia"
                          className="form-label"
                        >
                          Shipping Cost For India
                        </label>
                        <input
                          name="shippingCostIndia"
                          type="text"
                          placeholder="Type here"
                          className="form-control"
                          id="shippingCostIndia"
                          onChange={updateData}
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="shippingCostOtherCountry"
                          className="form-label"
                        >
                          Shipping Cost For Other Country
                        </label>
                        <input
                          name="shippingCostOtherCountry"
                          type="text"
                          placeholder="Type here"
                          className="form-control"
                          id="shippingCostOtherCountry"
                          onChange={updateData}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="card mb-4">
                    <div className="card-header">
                      <h4>Specifications</h4>
                    </div>
                    <div className="card-body">
                      <form id="form2">
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label
                                htmlFor="availableColors"
                                className="form-label"
                              >
                                Available Colors
                              </label>
                              <Select
                                options={colorList}
                                isMulti
                                onChange={handleMultiSelectColor}
                                value={selectedColors}
                                components={animatedComponents}
                                maxMenuHeight={100}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label
                                htmlFor="availableSize"
                                className="form-label"
                              >
                                Available Size
                              </label>
                              <Select
                                options={sizeList}
                                isMulti
                                onChange={handleMultiSelectSize}
                                value={selectedSize}
                                components={animatedComponents}
                                maxMenuHeight={100}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label
                                htmlFor="printFrontDimension"
                                className="form-label"
                              >
                                Print Front Dimension (inches)
                              </label>
                              <input
                                placeholder="X Axis"
                                className="form-control"
                                type="number"
                                name="x"
                                id="rectX"
                                value={rect?.x || 0}
                                onChange={handleRectChange}
                              />
                              <input
                                placeholder="Y Axis"
                                className="form-control mt-4"
                                type="number"
                                name="y"
                                id="rectY"
                                value={rect?.y || 0}
                                onChange={handleRectChange}
                              />
                              <input
                                placeholder="Width (inches)"
                                className="form-control mt-4"
                                type="number"
                                name="width"
                                id="rectWidth"
                                value={rect?.width || 0}
                                onChange={handleRectChange}
                              />
                              <input
                                placeholder="Height (inches)"
                                className="form-control mt-4"
                                type="number"
                                name="height"
                                id="rectHeight"
                                value={rect?.height || 0}
                                onChange={handleRectChange}
                              />
                              <canvas
                                ref={canvasRef}
                                width={280}
                                height={408}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-4">
                              <label
                                htmlFor="printBackDimension"
                                className="form-label"
                              >
                                Print Back Dimension (inches)
                              </label>
                              <input
                                placeholder="X Axis"
                                className="form-control"
                                type="number"
                                name="x"
                                id="rectX1"
                                value={rect1?.x || 0}
                                onChange={handleRectChange1}
                              />
                              <input
                                placeholder="Y Axis"
                                className="form-control mt-4"
                                type="number"
                                name="y"
                                id="rectY1"
                                value={rect1?.y || 0}
                                onChange={handleRectChange1}
                              />
                              <input
                                placeholder="Width (inches)"
                                className="form-control mt-4"
                                type="number"
                                name="width"
                                id="rectWidth1"
                                value={rect1?.width || 0}
                                onChange={handleRectChange1}
                              />

                              <input
                                placeholder="Height (inches)"
                                className="form-control mt-4"
                                type="number"
                                name="height"
                                id="rectHeight1"
                                value={rect1?.height || 0}
                                onChange={handleRectChange1}
                              />
                              <canvas
                                ref={canvasRef1}
                                width={280}
                                height={408}
                              />
                            </div>
                          </div>
                        </div>
                        <div></div>
                      </form>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn btn-md rounded font-sm hover-up create-product-btn"
                      onClick={formSubmit}
                    >
                      Create
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${
                isMobile
                  ? "px-5"
                  : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
              }`}
            >
              <div
                className={`${
                  isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                }`}
              >
                <div
                  className={`${
                    isMobile
                      ? ""
                      : "d-flex flex-center flex-column flex-column-fluid"
                  }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
