import React, {
    useEffect,
    useState,
    CSSProperties
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Row, Col, Card } from "react-bootstrap";
import { useNavigate, Link, useParams } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import solanalogo from "../images/icons/solanalogo.png";
import usdclogo from "../images/icons/USD_Coin_icon.webp";
import "./stores.css";
const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#fff",
};

export const Stores = () => {
    const [color, setColor] = useState('White');
    const [size, setSize] = useState('32');
    const [allStores, setAllStores] = useState([]);
    let [loading, setLoading] = useState(true);
    const { email } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function data() {
            await getAllStores();
        }
        data();
    }, []);

    const getAllStores = async () => {

        try {
            const response = await fetch(process.env.REACT_APP_API_URL + "get_allStores",
                {
                    method: "GET",
                    mode: "cors",
                    headers: new Headers({ "content-type": "application/json" })
                }
            );
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setAllStores(data['stores']);
                setLoading(false)
            } else {

                setLoading(false)
            }
        } catch (e) {
            console.log(e);
            setLoading(false)
        }
    };

    const redirect_to_store = (id) => {
        if (email) {
            navigate("/store-detail/" + id + "/" + email);
        } else {
            navigate("/store-detail/" + id);
        }
    };


    const back_to_home = () => {
        if (email) {
            navigate("/" + email);
        } else {
            navigate("/");
        }
    };

    return (

        <>
            <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader"
            />
            {!loading && (
                <div className="mainPage">
                    <Row>
                        <Col sm={12}>
                            <div className="back-to-home" onClick={back_to_home}>
                                <Icon.ArrowLeft size={30} className="pr-5"></Icon.ArrowLeft> Back
                            </div>
                            <div className="page-heading">
                                All Stores
                            </div>
                        </Col>
                    </Row>
                    <Row className="store-list">
                        {allStores.map((store, index) => (
                            <Col sm={2} className="mt-4">
                                <Card key={store['id']} className="r-c-s-card" onClick={() => redirect_to_store(store['id'])}>
                                    <Card.Body>
                                        <div className="r-c-s-img">
                                            <img src={store['storeAvatar']} />
                                        </div>
                                        <div className="r-c-s-name">
                                            {store['storeName']}
                                        </div>
                                        <div className="r-c-s-category">
                                            {store['design_product_count']} Products
                                        </div>
                                        <div className="r-c-s-currency-logo">
                                            <img src={usdclogo} />
                                            <img src={solanalogo} />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </>
    )
}

