import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Designer from "../mock-up/designer";
import { tshirts } from "../mock-up/tshirtcolor";
import "./mock-up.css";
import html2canvas from "html2canvas";
import EXIF from "exif-js";
import { ToastContainer, toast } from "react-toastify";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { HelioPay } from "@heliofi/react";
import S3 from "react-aws-s3";
import f_side_tshirt_img from "../assets/front-side-t-shirt.png";
import b_side_tshirt_img from "../assets/back-side-t-shirt.png";
import step_icon from "../assets/Group 253.png";
import back_icon from "../assets/Group 83.svg";
import step_3_icon from "../assets/Group 261.png";
import step_2_icon from "../assets/Group 254.png";
import t_shirt_preview from "../assets/t-shirt-demo.png";
import edit_icon from "../assets/edit-icon.png";

const initial = {
    direction: "front",
    color: "black",
    size: "m",
    designs: {
        front: {
            asset: "/assets/img/plus-sign-button.png",
            preview: "/assets/img/plus-sign-button.png",
            positions: {
                isDragging: false,
                width: (2 * 96) / 6,
                height: (2 * 96) / 6,
                x: 90,
                y: 90,
            },
        },
        back: {
            asset: "https://i.ibb.co/sskfDr1/lover-4992877-640.png",
            preview: "https://i.ibb.co/sskfDr1/lover-4992877-640.png",
            positions: {
                isDragging: false,
                width: 343 / 3,
                height: 329 / 3,
                x: 0,
                y: 0,
            },
        },
    },
};

const dim_initial = {
    height: "",
    rotation: "",
    width: "",
    x: "",
    y: "",
};

export const TShirtDesigner_1 = () => {
        const [appLoaded, setAppLoaded] = React.useState(false);
        const [tshirt, setTshirt] = React.useState(initial);
        const [dimention, setDimention] = React.useState(dim_initial);
        const [rectWidth, setRectWidth] = React.useState(0);
        const [rectHeight, setRectHeight] = React.useState(0);
        const [xaxis, setXaxis] = React.useState(0);
        const [yaxis, setYaxis] = React.useState(0);
        const [selected, setSelected] = React.useState(false);
        const [currentStep, setCurrentStep] = useState(2);
        const [productTitle, setProductTitle] = useState("");
        const [productDescription, setProductDescription] = useState("");
        const [noOfItems, setNoOfItems] = useState("");
        const [purchaseLimit, setPurchaseLimit] = useState("");
        const [network, setNetwork] = useState("Solana");
        const [currency, setCurrency] = useState("");
        const [sellingPrice, setSellingPrice] = useState("");
        const [profit, setProfit] = useState("");
        const [margin, setMargin] = useState("");
        const [tags, setTags] = useState("");
        const [baseProduct, setBaseProduct] = useState({});
        const [errors, setErrors] = useState({
            storeName: "",
            storeUrl: "",
            storeDescription: "",
            storeBanner: "",
            storeProfile: "",
            website: "",
            discord: "",
            twitter: "",
            telegram: "",
            medium: "",
            youtube: "",
        });
        const [modal, setModal] = React.useState({
            isOpen: false,
            message: "anjay",
        });
        const navigate = useNavigate();
        const [fileUpload, setFileUpload] = React.useState({});
        const [dlImage, setDLimage] = React.useState(false);
        const elStage = useRef(null);
        const animatedComponents = makeAnimated();
        const currencyList = [
            { value: "SOL", label: "SOL" },
            { value: "USDC", label: "USDC" },
        ];
        const [isSuccess, setIsSuccess] = useState(false);
        const [selectedCurrency, setSelectedCurrency] = useState([]);

        const [availableCurrency, setAvailableCurrency] = useState([]);

        const checkDeselect = (e: any) => {
            // deselect when clicked on empty area
            const clickedOnEmpty = e.target === e.target.getStage();
            if (clickedOnEmpty) {
                setSelected(false);
            }
        };

        function closeModal() {
            setModal({
                isOpen: false,
                message: null,
            });
        }

        useEffect(() => {
            // preload images
            const imagesToLoad: HTMLImageElement[] = [];
            for (let i = 0; i < tshirts.length; i++) {
                const image = new Image();
                image.src = tshirts[i];
                imagesToLoad.push(image);
            }

            Promise.all(
                imagesToLoad.map(
                    (img) =>
                    new Promise((resolve) => {
                        img.onload = resolve;
                    })
                )
            ).then(() => {
                setAppLoaded(true);
                let fisrtLoad = document.getElementById("fisrtLoad");
                if (fisrtLoad) {
                    fisrtLoad.classList.add("fade-out");
                    setTimeout(() => {
                        if (fisrtLoad) {
                            fisrtLoad.style.display = "none";
                        }
                    }, 500);
                }
            });
        }, []);

        //Editor File

        function changeColor(color) {
            console.log(color);
            setTshirt({
                ...tshirt,
                color: color,
            });
        }

        function changeSize(size) {
            setTshirt({
                ...tshirt,
                size: size,
            });
        }

        function chnageDirection(dir) {
            setTshirt({
                ...tshirt,
                direction: dir,
            });
            if (dir == 'front') {
                let front_dim = JSON.parse(baseProduct['printFrontDimension'])
                setXaxis(front_dim['x'])
                setYaxis(front_dim['y'])
                setRectHeight(front_dim['height'])
                setRectWidth(front_dim['width'])
            } else if (dir == 'back') {
                let back_dim = JSON.parse(baseProduct['printBackDimension'])
                setXaxis(back_dim['x'])
                setYaxis(back_dim['y'])
                setRectHeight(back_dim['height'])
                setRectWidth(back_dim['width'])
            }

        }

        function _designLabel(val, direction) {
            let setVal = val;
            let dl_front = document.getElementById("dl_front");
            let dl_back = document.getElementById("dl_back");
            if (setVal === null) {
                if (direction === "front") {
                    setVal = "Front Design";
                } else {
                    setVal = "Back Design";
                }
            }

            if (direction === "front") {
                dl_front.innerHTML = setVal;
            } else {
                dl_back.innerHTML = setVal;
            }
        }

        function changeDesign(e) {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                const exif = EXIF.readFromBinaryFile(reader.result);
                const xResolution = exif.XResolution;
                const yResolution = exif.YResolution;
                const unit = exif.ResolutionUnit;
                let dpi = null;
                if (xResolution && yResolution && unit === 2) {
                    dpi = xResolution;
                } else if (xResolution && yResolution && unit === 3) {
                    dpi = Math.round(xResolution * 2.54);
                }
                console.log(dpi);
            };
            reader.readAsArrayBuffer(file);

            console.log("dddd");
            const input_name = e.target.name;
            const acceptedImageTypes = ["image/jpeg", "image/png", "image/jpg"];

            // check is file an image
            if (file && acceptedImageTypes.includes(file["type"])) {
                const design = URL.createObjectURL(file);
                // set label image
                _designLabel(file.name, input_name);

                // Get image width
                let img = new Image();
                img.src = design;
                img.onload = function() {
                    // console.log(canvas_width)
                    // console.log(this.width + " " + this.height);
                    setFileUpload({
                        stream: design,
                        direction: input_name,
                        width: this.width,
                        height: this.height,
                        x: 50,
                        y: 50,
                    });
                };
            } else {
                // console.log('anajay bukan gambar')
                setModal({
                    isOpen: true,
                    message: "Please upload an image file (jpg, jpeg, png)",
                });
                e.target.value = null;
            }
        }

        React.useEffect(() => {
            function _calculate_image_size(type, originalWidth, originalHeight) {
                originalWidth = 160;
                originalHeight = 160;
                console.log(originalHeight, originalWidth);
                const canvas = Math.round((40 * elStage.current.clientWidth) / 100);
                setDimention({ x: "", y: "", width: 160, height: 160, rotation: "" });
                console.log(canvas);
                if (originalWidth >= canvas) {
                    const maxWidth = canvas;
                    const maxHeight = Math.round((55 * elStage.current.clientWidth) / 100);
                    var ratio = 0; // Used for aspect ratio
                    var width = originalWidth; // Current image width
                    var height = originalHeight; // Current image height

                    let newWidth = maxWidth;
                    let newHeight = maxWidth;

                    if (width > maxWidth && width > height) {
                        ratio = width / height;
                        newHeight = maxWidth / ratio;
                        newWidth = maxWidth;
                    } else if (height > maxHeight && height > width) {
                        ratio = height / width;
                        newWidth = maxHeight / ratio;
                        newHeight = maxHeight;
                    }

                    if (type === "width") {
                        return newWidth;
                    } else {
                        return newHeight;
                    }
                }
                return originalWidth;
            }

            if (Object.keys(fileUpload).length > 0) {
                setTshirt({
                    ...tshirt,
                    designs: {
                        ...tshirt.designs,
                        [fileUpload.direction]: {
                            ...tshirt.designs[fileUpload.direction],
                            preview: fileUpload.stream,
                            positions: {
                                ...tshirt.designs[fileUpload.direction].positions,
                                width: _calculate_image_size(
                                    "width",
                                    fileUpload.width,
                                    fileUpload.width
                                ),
                                height: _calculate_image_size(
                                    "height",
                                    fileUpload.width,
                                    fileUpload.height
                                ),
                            },
                        },
                    },
                });

                setFileUpload({});
            }
        }, [fileUpload, tshirt, setTshirt, elStage]);

        const downloadURI = (uri, name) => {
            const link = document.createElement("a");
            link.download = name;
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        };

        React.useEffect(() => {
            if (dlImage && !selected) {
                setXaxis(0)
                setYaxis(0)
                setRectHeight(0)
                setRectWidth(0)
                setTimeout(() => {
                    saveImage();
                    setDLimage(false);
                }, 5000);


            }

            function saveImage() {


                let element = document.getElementById("myDesign");
                const windowW = window.innerWidth;
                if (windowW < 1024) {
                    element.style.position = "fixed";
                    element.style.zIndex = 999;
                    element.style.left = 0;
                }

                html2canvas(element, {
                    allowTaint: true,
                    removeContainer: false,
                    backgroundColor: null,
                }).then((canvas) => {
                    if (windowW < 1024) {
                        element.style.position = null;
                        element.style.zIndex = null;
                        element.style.left = null;
                    }
                    //downloadURI(canvas.toDataURL("image/png"), "tes");

                    // Convert the canvas to a Blob with the correct content type
                    canvas.toBlob(async(blob) => {
                        if (!blob) {
                            console.error("Failed to convert canvas to Blob.");
                            return;
                        }

                        // Define the S3 bucket and object key where you want to save the image
                        const bucketName = process.env.REACT_APP_BUCKET_NAME;
                        let objectKey;
                        if (tshirt["direction"] == "front") {
                            objectKey = "front.png"; // Change the object key as needed
                        } else if (tshirt["direction"] == "back") {
                            objectKey = "back.png"; // Change the object key as needed
                        }

                        const folderPath = "temp/";
                        const config = {
                            bucketName,
                            region: process.env.REACT_APP_REGION,
                            accessKeyId: process.env.REACT_APP_ACCESS_ID,
                            secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
                            s3Url: process.env.REACT_APP_S3URL,
                        };

                        const ReactS3Client = new S3(config);

                        // Upload the Blob directly to S3 with the correct content type
                        const result = await ReactS3Client.uploadFile(
                            blob,
                            folderPath + objectKey, {
                                contentType: "image/png", // Set the correct content type
                            }
                        );

                        console.log("File uploaded successfully:", result.location);
                    }, "image/png"); // Set the content type here as well
                });
            }
        }, [dlImage, setDLimage, selected]);

        const validateStep1 = () => {
            let isValid = true;
            const newErrors = {...errors };

            // if (!storeName) {
            //   newErrors.storeName = "Store Name is required.";
            //   isValid = false;
            // } else {
            //   newErrors.storeName = "";
            // }

            // if (!storeUrl) {
            //   newErrors.storeUrl = "Store URL is required.";
            //   isValid = false;
            // } else {
            //   newErrors.storeUrl = "";
            // }

            // if (!storeDescription) {
            //   newErrors.storeDescription = "Store Description is required.";
            //   isValid = false;
            // } else {
            //   newErrors.storeDescription = "";
            // }

            setErrors(newErrors);
            return isValid;
        };

        const validateStep2 = () => {
            let isValid = true;
            const newErrors = {...errors };

            // if (!storeBanner) {
            //   newErrors.storeBanner = "Store Banner is required.";
            //   isValid = false;
            // } else {
            //   newErrors.storeBanner = "";
            // }

            // if (!storeProfile) {
            //   newErrors.storeProfile = "Store Profile is required.";
            //   isValid = false;
            // } else {
            //   newErrors.storeProfile = "";
            // }

            setErrors(newErrors);
            return isValid;
        };

        const validateStep3 = () => {
            let isValid = true;
            const newErrors = {...errors };

            // Add validation for social links if required

            setErrors(newErrors);
            return isValid;
        };

        const handleNext = (event) => {
            console.log(currentStep);
            event.preventDefault();
            switch (currentStep) {
                case 1:
                    if (validateStep1()) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                    break;
                case 2:
                    if (validateStep2()) {
                        setCurrentStep((prevStep) => prevStep + 1);
                    }
                    break;
                case 3:
                    if (validateStep3()) {
                        setCurrentStep((prevStep) => prevStep + 1);
                        createDesignProduct()
                    }
                    break;
                default:
                    break;
            }
        };

        const createDesignProduct = async() => {
            // if (!publicKey) return;
            try {
                // let product_data: ProductData = {
                //   walletID: publicKey.toString(),
                //   storeID:,
                //   productName:,
                //   productCategory:,
                //   selectedColor:,
                //   selectedSize:,
                //   acceptedCurrency:,
                //   price:,
                //   profitMargin:,
                //   imagesFront:,
                //   imagesBack:,
                //   shippingCostIndia:,
                //   shippingCostOtherCountry:,
                //   anyoneCanBuy:true,
                //   productStatus:,
                //   unitSold:,
                // };


                // console.log("product data");
                // console.log(product_data);


                // const response = await fetch(process.env.REACT_APP_API_URL + "create_store", {
                //   method: "POST",
                //   mode: "cors",
                //   headers: new Headers({ "content-type": "application/json" }),
                //   body: JSON.stringify(product_data),
                // });
                // if (response.status === 200) {
                //   let product_resp = await response.json();
                //   console.log(product_resp);
                //   var r = window.confirm("Product Saved Successfully!");
                //   if (r == true) {
                //     window.location.reload();
                //   }
                // } else {
                //   console.log("error");
                // }
            } catch (e) {
                console.log(e);
                return;
            }
        };


        const handleInputFocus = (field) => {
            // Clear the error message when the input field is focused
            const newErrors = {...errors };
            newErrors[field] = "";
            setErrors(newErrors);
        };

        useEffect(() => {
            let url = window.location.href.split("/");
            if (url.length > 2) {
                if (url[3] === "mockup") {
                    let id = url[4];
                    get_base_by_id(id);
                }
            }
        }, []);

        const get_base_by_id = async(id) => {
            let post_data = {
                id: id,
            };
            const response = await fetch(
                process.env.REACT_APP_API_URL + "get_baseProductById", {
                    method: "POST",
                    mode: "cors",
                    headers: new Headers({ "content-type": "application/json" }),
                    body: JSON.stringify(post_data),
                }
            );
            if (response.status === 200) {
                let product_resp = await response.json();
                console.log(product_resp);
                setBaseProduct(product_resp["store_details"]);
                let front_dim = JSON.parse(product_resp['store_details']['printFrontDimension'])
                setXaxis(front_dim['x'])
                setYaxis(front_dim['y'])
                setRectWidth(front_dim['width'])
                setRectHeight(front_dim['height'])
                    // if ("product_details" in product_resp) {
                    //   let temp = [] as any;

                //   temp.push(product_resp["product_details"]);
                //   setProductList(temp);
                //   console.log(product_resp);
                //   console.log(productList);
                // }
            } else {
                console.log("error");
            }
        };

        const calculate_profit_and_selling_price = (margin) => {
            let selling_price =
                Number(baseProduct["price"]) +
                (margin / 100) * Number(baseProduct["price"]);
            setSellingPrice(selling_price);
            setProfit((selling_price - Number(baseProduct["price"])).toFixed(2));
        };

        const handleMultiSelectCurrency = (selectedOptions: any) => {
            let selectedOptionList: string[] = [];
            for (let i = 0; i < selectedOptions.length; i++) {
                selectedOptionList.push(selectedOptions[i]["value"]);
            }
            setSelectedCurrency(selectedOptions);
            setAvailableCurrency(selectedOptionList);
        };

        const back_to_products = async() => {
            navigate("/seller-products");
        };

        function handleEntailmentRequest(e) {
            e.preventDefault();

            console.log("handle request ");
        }
        return ( <
            div className = "design-engine" >
            <
            ToastContainer pauseOnFocusLoss = { false }
            /> <
            div className = "stepper-container " >
            <
            div className = "stepper-progress-bar" >
            <
            div className = "step" >
            <
            div className = { `bullet ${currentStep >= 1 ? "active" : ""}` } >
            <
            div className = "step-inner" >
            <
            span >
            <
            img alt = ""
            src = { currentStep > 1 ? step_2_icon : step_icon }
            /> <
            /span> <
            span className = "step-inner-label" > Create Product < /span> <
            /div> <
            /div> <
            div className = { `check fas fa-check ${
                currentStep >= 1 ? "active" : ""
              }` } >
            < /div> <
            /div> <
            div className = "step" >
            <
            div className = { `bullet ${currentStep >= 2 ? "active" : ""}` } >
            <
            div className = "step-inner" >
            <
            span >
            <
            img alt = ""
            src = { currentStep > 2 ? step_2_icon : step_icon }
            /> <
            /span> <
            span className = "step-inner-label" > Create Design < /span> <
            /div> <
            /div> <
            div className = { `check fas fa-check ${
                currentStep >= 2 ? "active" : ""
              }` } >
            < /div> <
            /div> <
            div className = "step" >
            <
            div className = { `bullet ${currentStep >= 3 ? "active" : ""}` } >
            <
            div className = "step-inner" >
            <
            span >
            <
            img alt = ""
            src = { currentStep > 3 ? step_2_icon : step_icon }
            /> <
            /span> <
            span className = "step-inner-label" > Product Details < /span> <
            /div> <
            /div> <
            div className = { `check fas fa-check ${
                currentStep >= 3 ? "active" : ""
              }` } >
            < /div> <
            /div> <
            /div> <
            /div> {
                currentStep === 2 && ( <
                    >
                    <
                    div className = "row designer-heading-container" >
                    <
                    div className = "col-md-5" >
                    <
                    div className = "back-to-pick-product"
                    onClick = {
                        () => back_to_products() } >
                    <
                    span className = "arrow-icon" >
                    <
                    i className = "fi fi-rs-arrow-left" > < /i> <
                    /span>{" "} <
                    span className = "text" > { baseProduct["baseName"] } < /span> <
                    /div> <
                    /div> <
                    div className = "col-md-2" > < /div> <
                    div className = "col-md-5" >
                    <
                    div className = "d-flex right" >
                    <
                    div >
                    <
                    p className = "price" >
                    Price: { baseProduct["price"] } { baseProduct["currency"] } { " " } <
                    /p> <
                    p className = "price-info" > (Base Price + shipping) < /p> <
                    /div> <
                    div className = "ml-10" >
                    <
                    button className = "btn design-continue-btn"
                    onClick = { handleNext } >
                    { " " }
                    Continue <
                    /button> <
                    /div> <
                    /div> <
                    /div> <
                    /div> <
                    div className = "row design-row" >
                    <
                    div className = "col-md-4" > { " " } <
                    div onClick = {
                        () => setSelected(false) }
                    className = "w-full py-10 lg:py-0 min-h-0 flex items-center justify-center" >
                    <
                    div className = "w-full px-5 lg:px-10" > {
                        /* <h1 className="mb-5 text-2xl lg:text-5xl font-bold text-gray-800">
                                  T-SHIRT DESIGNER
                                </h1> */
                    } <
                    div className = "mb-5" >
                    <
                    div className = "mydesigns-btn" > My Designs < /div> <
                    /div> <
                    div className = "mb-5 mt-5" >
                    <
                    label className = "block design-label tracking-wide text-gray-200 text-xs font-bold mb-2" >
                    Colors Options <
                    /label> <
                    span className = "design-sub-label" >
                    Select the colors you prefer to offer
                    for this product <
                    /span> {
                        baseProduct &&
                            baseProduct["availableColors"] !== undefined && ( <
                                div className = "flex" > {
                                    JSON.parse(baseProduct["availableColors"]).map(
                                        (color, index) => ( <
                                            div onClick = {
                                                () => changeColor(color) }
                                            className = "design-clr-op cursor-pointer border-solid border-2 border-gray-700"
                                            style = {
                                                {
                                                    background: color,
                                                }
                                            } >
                                            < /div>
                                        )
                                    )
                                } <
                                /div>
                            )
                    } <
                    /div> <
                    div className = "mb-5" >
                    <
                    label className = "block design-label tracking-wide text-gray-200 text-xs font-bold mb-2" >
                    Size Options <
                    /label> <
                    span className = "design-sub-label" >
                    Select the sizes you prefer to offer
                    for this product <
                    /span> {
                        baseProduct &&
                            baseProduct["availableSize"] !== undefined && ( <
                                div className = "flex" > {
                                    JSON.parse(baseProduct["availableSize"]).map(
                                        (size, index) => ( <
                                            div onClick = {
                                                () => changeSize(size) }
                                            className = "design-size-op cursor-pointer border-solid border-2 border-gray-700" >
                                            { size } <
                                            /div>
                                        )
                                    )
                                } {
                                    /* <div
                                                            onClick={() => changeSize(size)}
                                                            className="design-size-op  cursor-pointer border-solid border-2 border-gray-700"
                                                          >
                                                            M
                                                          </div>
                                                          <div
                                                            onClick={() => changeSize("white")}
                                                            className="design-size-op  cursor-pointer border-solid border-2 border-gray-700"
                                                          >
                                                            L
                                                          </div>
                                                          <div
                                                            onClick={() => changeSize("white")}
                                                            className="design-size-op  cursor-pointer border-solid border-2 border-gray-700"
                                                          >
                                                            XL
                                                          </div> */
                                } <
                                /div>
                            )
                    } <
                    /div> {
                        (dimention["width"] !== "" || dimention["x"] !== "") && ( <
                            div className = "mb-5" >
                            <
                            label className = "block uppercase tracking-wide text-gray-600 text-xs font-bold mb-2" >
                            Image Dimention <
                            /label> {
                                dimention["x"] !== "" && ( <
                                    div className = "flex -m-3" >
                                    <
                                    div className = "m-3 " >
                                    X axis: { dimention.x.toFixed(2) } <
                                    /div> <
                                    div className = "m-3 " >
                                    Y axis: { dimention.y.toFixed(2) } <
                                    /div> <
                                    /div>
                                )
                            } {
                                dimention["width"] !== "" && ( <
                                    div className = "flex -m-3" >
                                    <
                                    div className = "m-3 " >
                                    Width: { dimention.width.toFixed(2) } <
                                    /div> <
                                    div className = "m-3 " >
                                    Height: { dimention.height.toFixed(2) } <
                                    /div> <
                                    /div>
                                )
                            } <
                            /div>
                        )
                    }

                    <
                    div className = "mb-10" >
                    <
                    label className = "block designs-label tracking-wide text-gray-200 text-xs font-bold mb-2" >
                    Upload Your Designs <
                    /label> <
                    label className = "w-full block cursor-pointer mb-3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    htmlFor = "d_front" >
                    <
                    span className = "truncate block"
                    id = "dl_front" >
                    Front Design <
                    /span> <
                    input id = "d_front"
                    onChange = { changeDesign }
                    className = "w-full hidden"
                    type = "file"
                    name = "front" /
                    >
                    <
                    /label> <
                    label className = "w-full block cursor-pointer mb-3 bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    htmlFor = "d_back" >
                    <
                    span className = "truncate block"
                    id = "dl_back" >
                    Back Design <
                    /span> <
                    input id = "d_back"
                    onChange = { changeDesign }
                    className = "w-full hidden"
                    type = "file"
                    name = "back" /
                    >
                    <
                    /label> <
                    /div> <
                    /div> <
                    /div> <
                    /div> <
                    div className = "col-md-4" > { " " } <
                    Designer selected = { selected }
                    setSelected = { setSelected }
                    checkDeselect = { checkDeselect }
                    elStage = { elStage }
                    tshirt = { tshirt }
                    tshirtOnChange = { setTshirt }
                    dimention = { dimention }
                    setDimention = { setDimention }
                    rectWidth = { rectWidth }
                    setRectWidth = { setRectWidth }
                    rectHeight = { rectHeight }
                    setRectHeight = { setRectHeight }
                    xaxis = { xaxis }
                    setXaxis = { setXaxis }
                    yaxis = { yaxis }
                    setYaxis = { setYaxis }
                    /> <
                    /div> <
                    div className = "col-md-4" >
                    <
                    div onClick = {
                        () => setSelected(false) }
                    className = "w-full py-10 lg:py-0 min-h-0 flex items-center justify-center" >
                    <
                    div className = "w-full px-5 lg:px-10" >
                    <
                    div className = "mb-5" >
                    <
                    div className = "preview-btn" > Preview < /div> <
                    /div> <
                    div className = "mb-5" >
                    <
                    div className = "flex" >
                    <
                    div >
                    <
                    div onClick = {
                        () => chnageDirection("front") }
                    className = { `${
                            tshirt["direction"] == "front"
                              ? "design-side-chooser active-dir cursor-pointer border-solid border-2 border-gray-700"
                              : "design-side-chooser cursor-pointer border-solid border-2 border-gray-700"
                          }` } >
                    <
                    img src = { f_side_tshirt_img }
                    /> <
                    /div> <
                    div className = "design-side-chooser-label" > Front < /div> <
                    /div> <
                    div >
                    <
                    div onClick = {
                        () => chnageDirection("back") }
                    className = { `${
                            tshirt["direction"] == "back"
                              ? "design-side-chooser active-dir cursor-pointer border-solid border-2 border-gray-700"
                              : "design-side-chooser cursor-pointer border-solid border-2 border-gray-700"
                          }` } >
                    <
                    img src = { b_side_tshirt_img }
                    /> <
                    /div> <
                    div className = "design-side-chooser-label" > Back < /div> <
                    /div> <
                    /div> {
                        /* <label className="block uppercase tracking-wide text-gray-200 text-xs font-bold mb-2">
                                          Directon
                                        </label>
                                        <div className="relative">
                                          <select
                                            onChange={chnageDirection}
                                            className="appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                            name="direction"
                                          >
                                            <option value="front">Front</option>
                                            <option value="back">Back</option>
                                          </select>
                                          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                            <svg
                                              className="fill-current h-4 w-4"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 20 20"
                                            >
                                              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                            </svg>
                                          </div>
                                        </div> */
                    } <
                    /div> <
                    div >
                    <
                    button className = "bg-primary w-full rounded-sm text-white p-2 outline-none"
                    onClick = {
                        () => setDLimage(true) } >
                    Save { tshirt["direction"] == "front" ? "Front " : "Back " }
                    Image <
                    /button> <
                    /div> <
                    div className = "mb-10" >
                    <
                    div className = "print-guide" >
                    <
                    div className = "print-guide-label" > Print Guidelines < /div> <
                    ul >
                    <
                    li > JPG, PNG and SVG file types supported < /li> <
                    li > Maximum 100 MB(JPG, PNG) or 20 MB(SVG) < /li> <
                    li > Print area size 3600× 4110 px(300 DPI) < /li> <
                    li > Maximum resolution 25000 x 25000 px < /li> <
                    /ul> <
                    /div> <
                    /div> <
                    /div> <
                    /div> <
                    /div> <
                    /div> <
                    />
                )
            } {
                currentStep === 3 && ( <
                        div className = "row designer-product-container" >
                        <
                        div className = "col-md-6" >
                        <
                        div className = "form-outer" >
                        <
                        form >
                        <
                        div className = "page" >
                        <
                        div className = "field" >
                        <
                        div className = "label" > Product Title < /div> <
                        input value = { productTitle }
                        onChange = {
                            (e) => setProductTitle(e.target.value) }
                        onFocus = {
                            () => handleInputFocus("productTitle") }
                        type = "text"
                        placeholder = "Enter The Product Title" /
                        > {
                            errors.productTitle && ( <
                                div className = "error" > { errors.productTitle } < /div>
                            )
                        } <
                        /div> <
                        div className = "field" >
                        <
                        div className = "label" > Product Description < /div> <
                        input value = { productDescription }
                        onChange = {
                            (e) => setProductDescription(e.target.value) }
                        onFocus = {
                            () => handleInputFocus("productDescription") }
                        type = "text"
                        placeholder = "Enter The Product Description" /
                        > {
                            errors.productDescription && ( <
                                div className = "error" > { errors.productDescription } < /div>
                            )
                        } <
                        /div> <
                        div className = "field" >
                        <
                        div className = "label" > Number Of Items < /div> <
                        input value = { noOfItems }
                        onChange = {
                            (e) => setNoOfItems(e.target.value) }
                        onFocus = {
                            () => handleInputFocus("noOfItems") }
                        type = "text"
                        placeholder = "Enter The Number Of Items" /
                        > {
                            errors.noOfItems && ( <
                                div className = "error" > { errors.noOfItems } < /div>
                            )
                        } <
                        /div> <
                        div className = "field" >
                        <
                        div className = "label" > Purchase Limit < /div> <
                        input value = { purchaseLimit }
                        onChange = {
                            (e) => setPurchaseLimit(e.target.value) }
                        onFocus = {
                            () => handleInputFocus("purchaseLimit") }
                        type = "text"
                        placeholder = "Enter The Purchase Limit" /
                        > {
                            errors.purchaseLimit && ( <
                                div className = "error" > { errors.purchaseLimit } < /div>
                            )
                        } <
                        /div> <
                        div className = "field" >
                        <
                        div className = "row" >
                        <
                        div className = "col-6" >
                        <
                        div className = "label" > Network < /div> <
                        input value = { network }
                        // onChange={(e) => setProductTitle(e.target.value)}
                        // onFocus={() => handleInputFocus("network")}
                        type = "text"
                        readonly = "true" /
                        > {
                            errors.network && ( <
                                div className = "error" > { errors.network } < /div>
                            )
                        } <
                        /div> <
                        div className = "col-6" >
                        <
                        div className = "label" > Currency < /div>

                        <
                        Select options = { currencyList }
                        isMulti onChange = { handleMultiSelectCurrency }
                        value = { selectedCurrency }
                        components = { animatedComponents }
                        maxMenuHeight = { 100 }
                        /> {
                            errors.currency && ( <
                                div className = "error" > { errors.currency } < /div>
                            )
                        }

                        <
                        HelioPay cluster = "mainnet"
                        payButtonTitle = "PAY"
                        paymentRequestId = "64f6cb5d1affdebf9932d1a4"
                        supportedCurrencies = {
                            [
                                "USDC",
                                "SOL",
                                "DUST",
                                "BOO",
                                "HALO",
                            ]
                        }
                        totalAmount = { 0.145 }
                        onClick = {
                            (e) => {
                                handleEntailmentRequest(e);
                            }
                        }
                        onSuccess = {
                            function(event): void {
                                console.log("onSuccess", event);
                                setIsSuccess(true);
                            }
                        }
                        onError = {
                            function(event): void {
                                console.log("onError", event);
                                setIsSuccess(false);
                            }
                        }
                        onPending = {
                            function(event): void {
                                console.log("onPending", event);
                            }
                        }
                        onStartPayment = {
                            function(): void {
                                console.log("onStartPayment");
                            }
                        }
                        /> <
                        /div> <
                        /div> <
                        /div> <
                        div className = "field" >
                        <
                        div className = "row" >
                        <
                        div className = "col-4" >
                        <
                        div className = "label" > Margin < /div> <
                        input value = { margin }
                        onChange = {
                            (e) => {
                                setMargin(e.target.value);
                                calculate_profit_and_selling_price(e.target.value);
                            }
                        }
                        onFocus = {
                            () => handleInputFocus("margin") }
                        type = "text" /
                        > {
                            errors.margin && ( <
                                div className = "error" > { errors.margin } < /div>
                            )
                        } <
                        /div> <
                        div className = "col-4" >
                        <
                        div className = "label" >
                        Set Selling Price < sup > Per Item < /sup> <
                        /div> <
                        input value = { sellingPrice }

                        onFocus = {
                            () => handleInputFocus("sellingPrice") }
                        type = "text"
                        readonly = "true" /
                        > {
                            errors.sellingPrice && ( <
                                div className = "error" > { errors.sellingPrice } < /div>
                            )
                        } <
                        /div> <
                        div className = "col-4" >
                        <
                        div className = "label" >
                        Profit < sup > Per Item < /sup> <
                        /div> <
                        input value = { profit }

                        onFocus = {
                            () => handleInputFocus("profit") }
                        type = "text"
                        readonly = "true" /
                        > {
                            errors.profit && ( <
                                div className = "error" > { errors.profit } < /div>
                            )
                        } <
                        /div> <
                        /div> <
                        /div> <
                        div className = "field" >
                        <
                        div className = "label" > Tags < /div> <
                        input value = { tags }
                        onChange = {
                            (e) => setTags(e.target.value) }
                        onFocus = {
                            () => handleInputFocus("tags") }
                        type = "text" /
                        > {
                            errors.tags && < div className = "error" > { errors.tags } < /div>} <
                            /div> <
                            div className = "step-button-container" >
                            <
                            button
                            className = "btn btn-md rounded font-sm hover-up create-product-btn firstNext next"
                            onClick = { handleNext } >
                            Save <
                            /button> <
                            /div> <
                            /div> <
                            /form> <
                            /div> <
                            /div> <
                            div className = "col-md-6" >
                            <
                            div className = "final-preview-img" >
                            <
                            img src = { t_shirt_preview }
                            /> <
                            span className = "edit-icon" > < /span> <
                            /div> <
                            /div> <
                            /div>
                        )
                    } <
                    /div>
            );
        };