import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
  useMemo,
  forwardRef,
} from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { useProSidebar } from "react-pro-sidebar";
import { CompSideBar } from "../components/sidebar";
import { Row, Col, Card, Button } from "react-bootstrap";
import reach_us_img from "../images/contact-us.png";
// import AddBox from '@material-ui/icons/AddBox';
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@material-ui/icons";
import MaterialTable from "material-table";
import product1 from "../images/products/product-image.png";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "./my-product.css";

interface Product {
  id: string;
  productSelectedPayment: string;
}

interface OrderType {
  id: number;
  orderDate: string;
  transactionHash: string;
  amount: number;
  paymentCurrency: string;
  orderStatus: string;
  profit:number;
  totalProfit:number;
  products:Product[];
}

export const MyOrders = ({ publicKey, userData }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  // const { collapseSidebar } = useProSidebar();


  const tableIcons: any = {
    Add: forwardRef((props: any, ref: any) => <AddBox {...props} {...ref} />),
    Check: forwardRef((props: any, ref: any) => <Check {...props} {...ref} />),
    Clear: forwardRef((props: any, ref: any) => <Clear {...props} {...ref} />),
    Delete: forwardRef((props: any, ref: any) => <DeleteOutline {...props} {...ref} />),
    DetailPanel: forwardRef((props: any, ref: any) => <ChevronRight {...props} {...ref} />),
    Edit: forwardRef((props: any, ref: any) => <Edit {...props} {...ref} />),
    Export: forwardRef((props: any, ref: any) => <SaveAlt {...props} {...ref} />),
    Filter: forwardRef((props: any, ref: any) => <FilterList {...props} {...ref} />),
    FirstPage: forwardRef((props: any, ref: any) => <FirstPage {...props} {...ref} />),
    LastPage: forwardRef((props: any, ref: any) => <LastPage {...props} {...ref} />),
    NextPage: forwardRef((props: any, ref: any) => <ChevronRight {...props} {...ref} />),
    PreviousPage: forwardRef((props: any, ref: any) => <ChevronLeft {...props} {...ref} />),
    ResetSearch: forwardRef((props: any, ref: any) => <Clear {...props} {...ref} />),
    Search: forwardRef((props: any, ref: any) => <Search {...props} {...ref} />),
    SortArrow: forwardRef((props: any, ref: any) => <ArrowDownward {...props} {...ref} />),
    ThirdStateCheck: forwardRef((props: any, ref: any) => <Remove {...props} {...ref} />),
    ViewColumn: forwardRef((props: any, ref: any) => <ViewColumn {...props} {...ref} />)
  };
  const tableData = [{ "id": "1", "orderDate": "23-12-2024", "transactionHash": "dsfgsfsdbvsdvd", "shippingCountry": "India", "amount": "23", "orderStatus": "Order Placed" }]
  const [walletID, setWalletID] = useState('');
  const [allOrders, setAllOrders] = useState<OrderType[]>([]);
  const [allProducts, setAllProducts] = useState([]);
  // const [userData, setUserData] = useState({});
  const [userRole, setUserRole] = useState('')

  const getMyOrders = async (walletID) => {
    console.log(walletID);
    if (walletID) {
      try {
        console.log(userData)
        const response = await fetch(process.env.REACT_APP_API_URL + "GetMyOrders",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ "role": userData['role'], 'walletID': userData['walletID'], 'user_id': userData['id'] }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setAllOrders(data['orders']);
          // setAllProducts(data['orders']['products']);

        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const viewOrderDetails = async (e, orderID) => {
    navigate("/order-detail/" + orderID)
  }
  // const getUserData = async (walletID) => {
  //   console.log(walletID);
  //   if (walletID) {
  //     try {
  //       const response = await fetch(process.env.REACT_APP_API_URL + "getUser",
  //         {
  //           method: "POST",
  //           mode: "cors",
  //           headers: new Headers({ "content-type": "application/json" }),
  //           body: JSON.stringify({ walletID: walletID }),
  //         }
  //       );
  //       if (response.status === 200) {
  //         const data = await response.json();
  //         setUserData(data['userData']);
  //         setUserRole(data['userData']['role']);
  //         console.log(data);
  //       } else {
  //         console.log(response.status);
  //       }
  //     } catch (e) {
  //       console.log(e);
  //     }
  //   }
  // };
  useEffect(() => {
    setWalletID(publicKey);

  }, [navigate]);
  useEffect(() => {
    setUserRole(userData['role']);

  }, [userData]);
  useEffect(() => {
    async function data() {
      await getMyOrders(walletID);
      // await getUserData(walletID);
    }
    data();
  }, [navigate, walletID]);

  return (
    <>
      {true ? (
        <>
          <div>
            <CompSideBar publicKey={publicKey} />
          </div>
          <div className="mainpage-dashboard">
            <div className="container-fluid">
              <div className="order-details-header">
                <h2 className="sora-bold">All Orders</h2>
              </div>
              <div className="order-details-cards">
                <Row>
                  <Col sm={12}>
                    {userRole == 'creator' && (<>
                      <MaterialTable
                        title="Order Details"
                        data={allOrders}
                        columns={[
                          { title: 'Order ID', field: 'id' },
                          { title: 'Order Date', field: 'orderDate', render: rowData => <>{rowData.orderDate.split("T")[0]}</> },
                          { title: 'Transaction Hash', field: 'transactionHash', render: rowData => <><a className="trans-link" target="_blank" href={"https://solscan.io/tx/" + rowData.transactionHash}>View on solscan</a></> },
                          { title: 'Amount', field: 'amount', render: rowData => <>{rowData.amount} {rowData.products[0]['productSelectedPayment']}</> },
                          { title: 'Profit', field: 'profit', render: rowData => <>{rowData.totalProfit.toFixed(5)} {rowData.products[0]['productSelectedPayment']}</>},
                          { title: 'Order Status', field: 'orderStatus' },
                          {
                            title: 'Action',
                            render: rowData => (
                              <div className="table-actions">
                                <Button className="tableEdit" variant="primary" onClick={(event) => viewOrderDetails(event, rowData.id)}>
                                  View Details
                                </Button>
                              </div>
                            ),
                          },
                        ]}
                        icons={tableIcons}

                        options={{
                          exportButton: true
                        }}
                      />
                    </>)}
                    {userRole == 'end_user' && (<>
                      <MaterialTable
                        title="Order Details"
                        data={allOrders}
                        // columns={columns} 

                        columns={[
                          { title: 'Order ID', field: 'id' },
                          //   { title: 'Product', field: 'productName', render: rowData => 
                          //     // <Row>
                          //     //   <Col sm={3}>
                          //     //     <img src={product1} style={{width: "50%"}}/>
                          //     //   </Col>
                          //     //   <Col sm={7}>
                          //     //     <a className="table-product-name" href={"/store-product-detail/"+rowData.id}>{rowData.productName}</a>
                          //     //     <h6>Size:{rowData.selectedSize} <span>Colour:{rowData.selectedColor}</span></h6>
                          //     //   </Col>
                          //     // </Row>
                          //   <div style={{display: "inline-flex"}}>
                          //     {/* <div className="table-product-image" style={{marginRight: "10px"}}>
                          //       <img src={product1} style={{width: 70 }}/>
                          //     </div> */}
                          //     <div className="table-product-detail">
                          //       <a className="table-product-name" href={"/store-product-detail/"+rowData.id}>{rowData.productName}</a>
                          //       <h6>Size:{rowData.selectedSize} <span>Colour:{rowData.selectedColor}</span></h6>
                          //     </div>
                          //   </div>
                          //   },
                          { title: 'Order Date', field: 'orderDate', render: rowData => <>{rowData.orderDate.split("T")[0]}</> },

                          { title: 'Transaction Hash', field: 'transactionHash', render: rowData => <><a className="trans-link" target="_blank" href={"https://solscan.io/tx/" + rowData.transactionHash}>View on solscan</a></> },

                          { title: 'Amount', field: 'amount', render: rowData => <>{rowData.amount} {rowData.products[0]['productSelectedPayment']}</> },
                          { title: 'Order Status', field: 'orderStatus' },
                          {
                            title: 'Action', render: rowData =>
                              <div className="table-actions">
                                <Button className="tableEdit" variant="primary" onClick={(event) => viewOrderDetails(event, rowData.id)} >
                                  View Details
                                </Button>
                              </div>
                          },
                        ]}
                        icons={tableIcons}

                        options={{
                          exportButton: true
                        }}
                      />
                    </>)}
                    {userRole == 'super-admin' && (<>
                      <MaterialTable
                        title="Order Details"
                        data={allOrders}
                        // columns={columns} 

                        columns={[
                          { title: 'Order ID', field: 'id' },
                          //   { title: 'Product', field: 'productName', render: rowData => 
                          //     // <Row>
                          //     //   <Col sm={3}>
                          //     //     <img src={product1} style={{width: "50%"}}/>
                          //     //   </Col>
                          //     //   <Col sm={7}>
                          //     //     <a className="table-product-name" href={"/store-product-detail/"+rowData.id}>{rowData.productName}</a>
                          //     //     <h6>Size:{rowData.selectedSize} <span>Colour:{rowData.selectedColor}</span></h6>
                          //     //   </Col>
                          //     // </Row>
                          //   <div style={{display: "inline-flex"}}>
                          //     {/* <div className="table-product-image" style={{marginRight: "10px"}}>
                          //       <img src={product1} style={{width: 70 }}/>
                          //     </div> */}
                          //     <div className="table-product-detail">
                          //       <a className="table-product-name" href={"/store-product-detail/"+rowData.id}>{rowData.productName}</a>
                          //       <h6>Size:{rowData.selectedSize} <span>Colour:{rowData.selectedColor}</span></h6>
                          //     </div>
                          //   </div>
                          //   },
                          { title: 'Order Date', field: 'orderDate', render: rowData => <>{rowData.orderDate.split("T")[0]}</> },
                          //   { title: 'Unit Sold', field: 'unitSold' },
                          //   { title: 'Profit Margin', field: 'profitMargin' },
                          //   { title: 'Product Status', field: 'productStatus' },
                          { title: 'Transaction Hash', field: 'transactionHash', render: rowData => <><a className="trans-link" target="_blank" href={"https://solscan.io/tx/" + rowData.transactionHash}>View on solscan</a></> },

                          { title: 'Shipping Country', field: 'shippingCountry' },
                          { title: 'Amount', field: 'amount', render: rowData => <>{rowData.amount} {rowData.products[0]['productSelectedPayment']}</> },
                          { title: 'Order Status', field: 'orderStatus' },
                          {
                            title: 'Action', render: rowData =>
                              <div className="table-actions">
                                <Button className="tableEdit" variant="primary" onClick={(event) => viewOrderDetails(event, rowData.id)} >
                                  View Details
                                </Button>
                              </div>
                          },
                        ]}
                        icons={tableIcons}

                        options={{
                          exportButton: true
                        }}
                      />
                    </>)}
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
            >
              <div
                className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                  }`}
              >
                <div
                  className={`${isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                    }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}