import React, {
  useEffect,
  useState,
  CSSProperties
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Slider from "react-slick";
import * as Icon from 'react-bootstrap-icons';
//   import { Carousel } from 'react-grid-carousel';
import reach_us_img from "../images/contact-us.png";
import solanalogo from "../images/icons/solanalogo.png";
import facebook from "../images/icons/facebook-store.png";
import insta from "../images/icons/insta-store.png";
import twitter from "../images/icons/twitter-store.png";
import freeShipping from "../images/icons/free-shipping.png";
import easyReturn from "../images/icons/easy-return.png";
import securePayment from "../images/icons/secure-payment.png";
import support from "../images/icons/support.png";
import cartArrow from "../images/icons/cart-arrow.png";
import searchIcon from "../images/icons/search-icon.png";
import usdclogo from "../images/icons/USD_Coin_icon.webp";

import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import "./store-detail.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#fff",
};

export const StoreDetail = ({ isApp, isAppEmailAddress }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [storeDetail, setStoreDetail] = useState({});
  const [allProduct, setAllProduct] = useState([]);
  const [storeID, SetStoreID] = useState("");
  const [socialLink, setSocialLink] = useState("");
  const { email } = useParams();
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#ffffff");
  const [isFixed, setIsFixed] = useState(false);

  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const productSearchFilter = async (e) => {
    console.log(e.target.value);
    getProductsByStoreID("", e.target.value);
  };

  const productFilter = async (key) => {
    var category = "";
    if (key != "all") {
      category = key;
    }
    getProductsByStoreID(category, "");
  };

  const getProductsByStoreID = async (category, search_key) => {
    if (storeID) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + "GetDesignProductByStoreID",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({
              store_id: storeID,
              category: category,
              search_key: search_key,
            }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setAllProduct(data["product_details"]);
        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  const getStoreByID = async (store_id) => {
    console.log(store_id);
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "get_storeById", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ id: store_id }),
      });
      if (response.status === 200) {

        const data = await response.json();
        console.log("response" + data["store_details"]["storeName"]);
        console.log("response" + data["store_details"]["storeName"]);
        setStoreDetail(data["store_details"]);
        setSocialLink(JSON.parse(data["store_details"]["socialLink"]));

        console.log(storeDetail);
        setLoading(false)
      } else {
        setLoading(false)
      }
    } catch (e) {
      setLoading(false)
    }
  };
  const redirect = (e) => {
    console.log(e.target.id);
    if (email) {
      navigate("/store-product-detail/" + e.target.id + "/" + email);
    } else {
      navigate("/store-product-detail/" + e.target.id);
    }

  };

  const redirect_to_product = (id) => {

    if (email) {
      navigate("/store-product-detail/" + id + "/" + email);
    } else {
      navigate("/store-product-detail/" + id);
    }

  };

  useEffect(() => {
    async function data(store_id) {
      await getStoreByID(store_id);
    }

    var url_path = window.location.pathname;
    var store_id = url_path.split("/")[2];

    data(store_id);
  }, [navigate]);

  useEffect(() => {
    async function data() {
      var url_path = window.location.pathname;
      console.log(url_path);
      var store_id = url_path.split("/")[2];
      console.log(store_id);
      SetStoreID(store_id);
      await getProductsByStoreID("", "");
    }
    console.log("use effect running");
    data();
  }, [navigate, storeID]);


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const headerHeight = 100;

      // Adjust this threshold as needed
      setIsFixed(scrollPosition > headerHeight);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <>
      <ClipLoader
        color={color}
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="loader"
      />
      {!loading && (
        <>
          {true ? (
            <>
              <div className="storeMain">

                <div className="storeBanner">
                  <img
                    className="storeBanner-image"
                    alt="store banner"
                    src={storeDetail["storeBanner"]}
                  />
                  <Card className="avatar-card">
                    <Card.Body className="avatar-card-body">
                      <img className="avatar-img" alt="storeAvatar" src={storeDetail["storeAvatar"]} />
                      {/* <h3>{storeDetail['storeDescription']}</h3> */}
                      <div className="storeName">
                        <h1>{storeDetail["storeName"]}</h1>
                      </div>
                      <h3 className="store-desc">
                        {storeDetail["storeDescription"]}
                      </h3>
                      {socialLink &&
                        <div className="social-icon-div">
                          <ul className="social-icons">
                            <li>
                              <a
                                href={socialLink["twitter"]}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Icon.Facebook size={25} className="page-icons-2" />

                              </a>
                            </li>
                            <li>
                              <a
                                href={socialLink["twitter"]}
                                rel="noreferrer"
                                target="_blank"
                              >

                                <Icon.Discord size={25} className="page-icons-2" />
                              </a>
                            </li>
                            <li>
                              <a
                                href={socialLink["twitter"]}
                                rel="noreferrer"
                                target="_blank"
                              >
                                <Icon.Twitter size={25} className="page-icons-2" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      }
                    </Card.Body>
                  </Card>
                </div>
                <div className="new-arrivals">
                  <h1>
                    New arrivals{" "}
                    {/* <span className="new-view-all">
                      View all <img src={cartArrow} />
                    </span> */}
                  </h1>
                </div>
                <div className="new-arriaval-slider">
                  <Slider {...settings}>
                    {allProduct.map((slide, index) => (
                      <div key={index} onClick={() => redirect_to_product(slide['id'])}>
                        <div className="store-product-card">
                          <div className="store-product-card-body">
                            <img
                              className="store-product-img"
                              src={slide["imagesFront"]}
                            />
                            <h2 className="store-product-name">
                              {slide["productName"]}
                            </h2>
                            <div className="store-product-price">
                              <img src={usdclogo} />
                              <h3>{slide["sellingPrice"]}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>

                  {/* <Carousel
                        responsive={responsive}
                    >
                        {allProduct.map((product, productIndex) => (
                            <Card className="store-product-card">
                                <Card.Body className="store-product-card-body">
                                    <img className="store-product-img" src={storeImg1} />
                                    <h2 className="store-product-name">Crew Neck T-Shirt</h2>
                                    <div className="store-product-price">
                                        <img src={solanalogo}/>
                                        <h3>120</h3>
                                    </div>
                                </Card.Body>
                            </Card>
                        ))}
                    </Carousel> */}
                  {/* <Carousel cols={4} rows={1} gap={20} loop>
                        {allProduct.map((product, productIndex) => (
                            <Carousel.Item>
                                <Card className="store-product-card">
                                    <Card.Body className="store-product-card-body">
                                        <img className="store-product-img" src={storeImg1} />
                                        <h2 className="store-product-name">Crew Neck T-Shirt</h2>
                                        <div className="store-product-price">
                                            <img src={solanalogo}/>
                                            <h3>120</h3>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Carousel.Item>
                        ))}
                    </Carousel> */}
                </div>

                <div className="store-tabs">
                  <div>
                    <div className="search-products">
                      <Icon.Search size={25} className="page-icons search-icon" />

                      <Form.Control
                        className="search-product-input"
                        size="lg"
                        type="text"
                        onChange={productSearchFilter}
                        placeholder="Search"
                      />
                    </div>
                    <Tabs
                      defaultActiveKey="all"
                      id="uncontrolled-tab-example"
                      className="store-tabs-name"
                      onSelect={productFilter}
                    >
                      <Tab eventKey="all" title="All">
                        <Row>
                          {allProduct.map((product, productIndex) => (
                            <Col sm={3} md={3} xs={6} className="pt-2">
                              <Card
                                id={product["id"]}
                                onClick={redirect}
                                className="store-product-card"
                              >
                                <Card.Body
                                  id={product["id"]}
                                  className="store-product-card-body"
                                >
                                  <img
                                    id={product["id"]}
                                    className="store-product-img"
                                    src={product["imagesFront"]}
                                  />
                                  <h2
                                    id={product["id"]}
                                    className="store-product-name"
                                  >
                                    {product["productName"]}
                                  </h2>
                                  <div
                                    id={product["id"]}
                                    className="store-product-price"
                                  >
                                    <img id={product["id"]} src={usdclogo} />
                                    <h3 id={product["id"]}>{product["sellingPrice"]}</h3>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                      <Tab eventKey="t-shirt" title="Tshirts">
                        <Row>
                          {allProduct.map((product, productIndex) => (
                            <Col sm={3} md={3} xs={6} className="pt-2">
                              <Card
                                id={product["id"]}
                                onClick={redirect}
                                className="store-product-card"
                              >
                                <Card.Body
                                  id={product["id"]}
                                  className="store-product-card-body"
                                >
                                  <img
                                    id={product["id"]}
                                    className="store-product-img"
                                    src={product["imagesFront"]}
                                  />
                                  <h2
                                    id={product["id"]}
                                    className="store-product-name"
                                  >
                                    {product["productName"]}
                                  </h2>
                                  <div
                                    id={product["id"]}
                                    className="store-product-price"
                                  >
                                    <img id={product["id"]} src={usdclogo} />
                                    <h3 id={product["id"]}>{product["sellingPrice"]}</h3>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                      <Tab eventKey="hoodie" title="Hoodies">
                        <Row>
                          {allProduct.map((product, productIndex) => (
                            <Col sm={3} md={3} xs={6} className="pt-2">
                              <Card
                                id={product["id"]}
                                onClick={redirect}
                                className="store-product-card"
                              >
                                <Card.Body
                                  id={product["id"]}
                                  className="store-product-card-body"
                                >
                                  <img
                                    id={product["id"]}
                                    className="store-product-img"
                                    src={product["imagesFront"]}
                                  />
                                  <h2
                                    id={product["id"]}
                                    className="store-product-name"
                                  >
                                    {product["productName"]}
                                  </h2>
                                  <div
                                    id={product["id"]}
                                    className="store-product-price"
                                  >
                                    <img id={product["id"]} src={usdclogo} />
                                    <h3 id={product["id"]}>{product["sellingPrice"]}</h3>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                      <Tab eventKey="accessories" title="Accessories">
                        <Row>
                          {allProduct.map((product, productIndex) => (
                            <Col sm={3} md={3} xs={6} className="pt-2">
                              <Card
                                id={product["id"]}
                                onClick={redirect}
                                className="store-product-card"
                              >
                                <Card.Body
                                  id={product["id"]}
                                  className="store-product-card-body"
                                >
                                  <img
                                    id={product["id"]}
                                    className="store-product-img"
                                    src={product["imagesFront"]}
                                  />
                                  <h2
                                    id={product["id"]}
                                    className="store-product-name"
                                  >
                                    {product["productName"]}
                                  </h2>
                                  <div
                                    id={product["id"]}
                                    className="store-product-price"
                                  >
                                    <img id={product["id"]} src={usdclogo} />
                                    <h3 id={product["id"]}>{product["sellingPrice"]}</h3>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                  <div className="no-products">
                    {allProduct.length > 0 ? (
                      <></>
                    ) : (
                      <>
                        <h4>Product not available</h4>
                      </>
                    )}
                  </div>
                </div>
                <div></div>
              </div>
              <div className="product-general-detail">
                <Row>
                  <Col sm={6}>
                    <div className="product-general-detail-col">
                      <div>
                        <img src={freeShipping} />
                      </div>
                      <div>
                        <h2>Free shipping</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut
                          et massa mi.{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="product-general-detail-col">
                      <div>
                        <img src={easyReturn} />
                      </div>
                      <div>
                        <h2>Easy Return</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut
                          et massa mi.{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="product-general-detail-row">
                  <Col sm={6}>
                    <div className="product-general-detail-col">
                      <div>
                        <img src={securePayment} />
                      </div>
                      <div>
                        <h2>100% Secure Payment</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut
                          et massa mi.{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={6}>
                    <div className="product-general-detail-col">
                      <div>
                        <img src={support} />
                      </div>
                      <div>
                        <h2>Support</h2>
                        <p>
                          Lorem ipsum dolor sit amet consectetur adipiscing elit Ut
                          et massa mi.{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <div
                id="kt_content_container"
                className="d-flex flex-column-fluid align-items-start "
              >
                <div
                  className={`${isMobile
                    ? "px-5"
                    : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                    }`}
                >
                  <div
                    className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                      }`}
                  >
                    <div
                      className={`${isMobile
                        ? ""
                        : "d-flex flex-center flex-column flex-column-fluid"
                        }`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="create-raffle-wallet-connet">
                            <img
                              className="reach-us-img"
                              alt=""
                              src={reach_us_img}
                            />
                            <div className="reach-us-to-whitelist">
                              Please connect your wallet.
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="wallet-button">
                            <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
