import React, { useEffect, useState, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import "./seller-products.css";
import t_1 from "../assets/products/t-1.png";
import t_2 from "../assets/products/t-2.png";

export const SellerProducts = () => {
  const [homeActiveTab, setHomeActiveTab] = useState("all");
  const [productList, setProductList] = useState([]);
  const navigate = useNavigate();
  let home_tab_change = async (e) => {
    setHomeActiveTab(e);

    if (e === "active") {
      // await setActiveRaffleCurrentPage(1);
      // await setIsActiveRaffleNextPage(false);
      // await setActiveRaffle([]);

      console.log("dd");
    } else if (e === "past") {
      console.log("dd");
    } else if (e === "cancelled") {
      console.log("dd");
    }
  };

  useEffect(() => {
    createget_master_productsProduct();
  }, []);

  const createget_master_productsProduct = async () => {
    let post_data = {
      category: "t-shirt",
    };
    const response = await fetch(
      process.env.REACT_APP_API_URL + "get_baseProductByCategory",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(post_data),
      }
    );
    if (response.status === 200) {
      let product_resp = await response.json();
      if ("product_details" in product_resp) {
        let temp = [] as any;

        temp.push(product_resp["product_details"]);
        setProductList(product_resp["product_details"]);
        console.log(product_resp);
        console.log(productList);
      }
    } else {
      console.log("error");
    }
  };

  let open_design_engine = (id) => (e) => {
    navigate("/mockup/" + id);
  };

  //
  return (
    <>
      <div className="row my-5">
        <div className="container px-5">
          <div className="col-xl-12">
            <div className="back-to">
              <span className="icon">
                <i className="fi fi-rs-arrow-left"></i>
              </span>{" "}
              <span className="text">Back</span>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="pt-30 pb-30">
              <h2 className="page-title">Pick a product to design!</h2>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="d-flex">
              <div
                onClick={() => home_tab_change("all")}
                className={`${
                  homeActiveTab === "all"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                All
              </div>
              <div
                onClick={() => home_tab_change("men")}
                className={`${
                  homeActiveTab === "men"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Men
              </div>
              <div
                onClick={() => home_tab_change("women")}
                className={`${
                  homeActiveTab === "women"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Women
              </div>
              <div
                onClick={() => home_tab_change("unisex")}
                className={`${
                  homeActiveTab === "unisex"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Unisex
              </div>
              <div
                onClick={() => home_tab_change("kids")}
                className={`${
                  homeActiveTab === "kids"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Kids
              </div>
              <div
                onClick={() => home_tab_change("home")}
                className={`${
                  homeActiveTab === "home"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Home & Living
              </div>
              <div
                onClick={() => home_tab_change("accessories")}
                className={`${
                  homeActiveTab === "accessories"
                    ? "home-tab active px-5 mb-3"
                    : "home-tab px-5 mb-3"
                }`}
              >
                Accessories
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        <div className="col-12">
          <div className="container seller-product-container">
            <main className="grid">
              {productList.map((product, index) => (
                <article onClick={open_design_engine(product["id"])}>
                  <img src={product["displayImage"]} alt="display" />
                  <div className="text">
                    <h3 className="product-name mt-1 mb-1">
                      {product["baseName"]}
                    </h3>
                    <p className="price-text mt-1 mb-1">
                      {product["currency"]} {product["price"]}{" "}
                      <span className="shipping-text">+ Shipping</span>
                    </p>
                    {/* <a
                    href="https://codepen.io/collection/XdWJOQ/"
                    className="btn btn-primary btn-block"
                  >
                    Design
                  </a> */}
                  </div>
                  {product["availableColors"] && (
                    <div className="color-box-container flex -m-3">
                      {JSON.parse(product["availableColors"]).map(
                        (color, index) => (
                          <div
                            // onClick={() => changeColor("white")}
                            className="color-picker "
                            style={{
                              background: color,
                            }}
                          ></div>
                        )
                      )}
                    </div>
                  )}
                  {product["availableSize"] && (
                    <div className="size-text pt-30">
                      Sizes : {JSON.parse(product["availableSize"]).join(",")}
                    </div>
                  )}
                </article>
              ))}
              <article>
                <img src={t_2} alt="Sample photo" />
                <div className="text">
                  <h3 className="product-name mt-1 mb-1">Men's Tshirt (DTF)</h3>
                  <p className="price-text mt-1 mb-1">
                    SOL 12.00 <span className="shipping-text">+ Shipping</span>
                  </p>
                  {/* <a
                    href="https://codepen.io/collection/XdWJOQ/"
                    className="btn btn-primary btn-block"
                  >
                    Design
                  </a> */}
                </div>
                <div className="color-box-container flex -m-3">
                  <div
                    // onClick={() => changeColor("white")}
                    className="color-picker "
                    style={{
                      background: "white",
                    }}
                  ></div>
                  <div
                    // onClick={() => changeColor("white")}
                    className="color-picker "
                    style={{
                      background: "black",
                    }}
                  ></div>
                  <div
                    // onClick={() => changeColor("white")}
                    className="color-picker "
                    style={{
                      background: "#FFC300",
                    }}
                  ></div>
                  <div
                    // onClick={() => changeColor("white")}
                    className="color-picker "
                    style={{
                      background: "#FF8ECF",
                    }}
                  ></div>
                </div>
                <div className="size-text pt-30">Sizes : S, M, L, XL, XXL</div>
              </article>
            </main>
          </div>
        </div>
      </div>
    </>
  );
};
