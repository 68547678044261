import React, {
  useEffect,
  CSSProperties,
  useState,
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
import { HelioPay } from "@heliofi/react";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from 'react-bootstrap-icons';
import "react-toastify/dist/ReactToastify.css";
import reach_us_img from "../images/contact-us.png";
import checkoutImg from "../images/checkout-img.png";
import cartArrow from "../images/icons/cart-arrow.png";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "bootstrap/dist/css/bootstrap.min.css";
import "./check-out.css";


const override: CSSProperties = {
  display: "block",
  margin: "0 auto",
  borderColor: "#fff",
};


export const CheckOut = ({ publicKey, userData, isApp, isAppEmailAddress }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [savedAddress, setSavedAddress] = useState(false);
  const [userAddress, setUserAddress] = useState({});
  const [isEditAddress, setIsEditAddress] = useState(false);
  const [editAddress, setEditAddress] = useState({});
  const [savedUserData, setSavedUserData] = useState([{}]);
  const [activeAddress, setActiveAddress] = useState({});
  const [otherAddress, setOtherAddress] = useState([{}]);
  const [userId, SetUserId] = useState("");
  const [allProduct, setAllProduct] = useState([]);
  const [productQuantity, setProductQuantity] = useState(1);
  const [subTotal, setSubTotal] = useState(0.0);
  const [shippingCost, setShippingCost] = useState(0);
  const [tax, setTax] = useState(18);
  const [taxValue, setTaxValue] = useState(0);
  const [baseProductCurrency, setBaseProductCurrency] = useState("")
  const [orderPaymentCurrency, setOrderPaymentCurrency] = useState('');
  const { email } = useParams();
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  let [loading, setLoading] = useState(true);

  const location = useLocation();
  const isWebView = location.search.includes('view=webview');

  const AddAddress = async (e) => {
    setSavedAddress(false);
  };
  const EditActiveAddress = async (e) => {
    setEditAddress(activeAddress);
    setIsEditAddress(true);
  };
  const EditOtherAddress = async (e) => {
    setEditAddress(otherAddress[parseInt(e.target.id)]);
    setIsEditAddress(true);
  };
  function handleEditAddressData(e) {
    const { name, value } = e.target;
    console.log(name, value);
    const userAddressData = { ...editAddress };
    if (name == "country") {
      console.log(allProduct)
      if (value == "india") {
        setShippingCost(parseInt(allProduct[0]["shippingCostIndia"]));
      } else {
        setShippingCost(parseInt(allProduct[0]["shippingCostOtherCountry"]));
      }
    }
    userAddressData[name] = value;
    setEditAddress(userAddressData);
    console.log(userAddress);
  }
  const saveEditUserAddress = async (e) => {
    console.log(editAddress);
    editAddress["walletID"] = publicKey.toString();
    editAddress['updateActive'] = "0"
    const response = await fetch(
      process.env.REACT_APP_API_URL + "updateUserAddress",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(editAddress),
      }
    );
    if (response.status === 200) {
      let product_resp = await response.json();
      console.log(product_resp);
      setSavedAddress(true);
      setIsEditAddress(false);
      var r = window.confirm("Address Saved Successfully!");
      if (r == true) {
        getUserAddress(true);
      }
    } else {
      console.log("error");
    }
  };
  const updateShippingCost = async (activeAddress) => {
    if (activeAddress['country'] == "india") {
      setShippingCost(parseInt(allProduct[0]["shippingCostIndia"]));
    }
    else {
      setShippingCost(parseInt(allProduct[0]["shippingCostOtherCountry"]));
    }
  }
  const selectAddress = async (address_id) => {
    console.log(address_id)
    let payload = {}
    if (publicKey) {
      payload = { id: address_id, walletID: publicKey.toString(), updateActive: "1" }
    } else if (email) {
      payload = { id: address_id, emailId: email, updateActive: "1" }
    }
    const response = await fetch(
      process.env.REACT_APP_API_URL + "updateUserAddress",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(payload),
      }
    );
    if (response.status === 200) {
      getUserAddress(true);
    } else {
      const data = await response.json();
      console.log(data);
    }
  }
  const RemoveAddress = async (e) => {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "deleteUserAddress",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify({ id: e.target.id }),
      }
    );
    if (response.status === 200) {
      getUserAddress(true);
    } else {
      console.log("error");
    }
  };
  function getTax() {
    var taxCal = (tax * subTotal) / 100;
    setTaxValue(taxCal);
  }

  const getProductsByUserID = async () => {
    console.log(userId);
    if (userId) {
      try {
        let payload;
        if (email && emailRegex.test(email)) {
          payload = {
            emailId: email
          }
        } else {
          payload = { walletId: userId }
        }
        const response = await fetch(
          process.env.REACT_APP_API_URL + "getShoppingCart",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setAllProduct(data["productData"]);
          // setSubTotal(data["cartSubTotal"]);
          let total = 0;
          let tax = 0;
          data['productData'].forEach(product => {
            total = total + Number(product['cartPrice'])
            tax = tax + Number(product['baseProduct']['taxAmount'])
          });
          setSubTotal(total)
          setTax(tax)
          setBaseProductCurrency(data['productData'][0]['baseProduct']['currency'])
          const userdata = data["userAddress"];
          setOrderPaymentCurrency(data["productData"][0]["productSelectedPayment"])
          if (userdata[0]["country"] == "india") {
            setShippingCost(
              parseInt(data["productData"][0]["shippingCostIndia"])
            );
          } else {
            setShippingCost(
              parseInt(data["productData"][0]["shippingCostOtherCountry"])
            );
          }
          setLoading(false)
        } else {
          console.log(response.status);
          setLoading(false)
        }
      } catch (e) {
        console.log(e);
        setLoading(false)
      }
    }
  };

  function handleAddressData(e) {
    const { name, value } = e.target;
    console.log(name, value);
    console.log(allProduct)
    const userAddressData = { ...userAddress };
    if (name == "country") {
      if (value == "india") {
        setShippingCost(parseInt(allProduct[0]["shippingCostIndia"]));
      } else {
        setShippingCost(parseInt(allProduct[0]["shippingCostOtherCountry"]));
      }
    }
    if (name == "isSaved") {
      if (e.target.checked) {
        userAddressData[name] = 1;
      } else {
        userAddressData[name] = 0;
      }
    } else {
      userAddressData[name] = value;
    }

    setUserAddress(userAddressData);
    console.log(userAddress);
  }
  const getUserAddress = async (isUpdate) => {
    let payload;
    if (email && emailRegex.test(email)) {
      payload = {
        emailId: email
      }
    } else {
      payload = { walletId: publicKey.toString() }
    }

    const response = await fetch(process.env.REACT_APP_API_URL + "/getUserAddress", {
      method: "POST",
      mode: "cors",
      headers: new Headers({ "content-type": "application/json" }),
      body: JSON.stringify(payload),
    });
    if (response.status === 200) {
      let user_resp = await response.json();
      console.log(user_resp);
      const otherAdd: Object[] = [];
      user_resp["userAddress"].forEach(function (addressElement, addressIndex) {
        if (addressElement['isActive'] == 1) {
          setActiveAddress(addressElement);
        }
        else {
          otherAdd.push(addressElement);
        }
      })
      console.log(otherAdd)
      setSavedUserData(otherAdd);
      setOtherAddress(otherAdd);
      setSavedAddress(true);
      if (isUpdate) {
        updateShippingCost(activeAddress);
      }

    } else {
      console.log("error");
    }
  };
  const saveUserAddress = async (e) => {
    console.log(userAddress)
    if (!userAddress.hasOwnProperty('name') || !userAddress.hasOwnProperty('addressLine1') || !userAddress.hasOwnProperty('city') || !userAddress.hasOwnProperty('postalCode') || !userAddress.hasOwnProperty('city') || !userAddress.hasOwnProperty('mobileNumber') || !userAddress.hasOwnProperty('emailAddress')) {
      toast.error('Please fill the required fields', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return
    }
    if (userAddress == null || userAddress['name'] == '' || userAddress['addressLine1'] == '' || userAddress['city'] == '' || userAddress['postalCode'] == '' || userAddress['city'] == '' || userAddress['mobileNumber'] == '' || userAddress['emailAddress'] == '') {
      toast.error('Please fill the required fields', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      return
    }
    // if (userAddress["isSaved"] == 1) {
    if (email && emailRegex.test(email)) {
      userAddress["emailId"] = email;
    } else {
      userAddress["walletID"] = publicKey.toString();
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL + "create_userAddress",
      {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(userAddress),
      }
    );
    if (response.status === 200) {
      let product_resp = await response.json();
      console.log(product_resp);
      setSavedAddress(true);
      toast("Address saved successfully", {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
      getUserAddress(true);
    } else {
      console.log("error");
    }
    // }
  };
  useEffect(() => {
    console.log(isWebView)
    // var url_path = window.location.pathname;
    // console.log(url_path);
    // var product_id = url_path.split("/")[2];
    // console.log(product_id);
    if (publicKey) {
      SetUserId(publicKey.toString());
    }
    if (email && emailRegex.test(email)) {
      SetUserId(email);
    }
  }, [navigate, publicKey, email]);
  useEffect(() => {
    async function data() {
      await getProductsByUserID();
    }
    data();
  }, [navigate, userId]);
  useEffect(() => {
    async function data() {
      await getUserAddress(false);
    }
    data();
  }, [navigate, userId, email]);
  useEffect(() => {
    async function data() {
      await getTax();
    }
    data();
  }, [navigate, userId, subTotal]);

  const paymentSuccess = async (e) => {
    console.log(e);
    let total_amt = subTotal + shippingCost + taxValue;
    let order_details = {
      products: allProduct,
      walletID: userId,
      storeID: allProduct[0]['storeID'],
      amount: total_amt,
      transactionHash: e['data']['transactionSignature'],
      paymentID:'',
      paymentCurrency: allProduct[0]["acceptedCurrency"]
    };
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/CreateOrder", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(order_details),
      });
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        toast("Order Placed Successfully", {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      } else {
        console.log(response.status);
        toast.error(response.status, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  };


  const redirect = () => {
    let phantom_url = "https://phantom.app/ul/browse/https://app.monkeycommerce.io/pay/" + email + "?ref=https://app.monkeycommerce.io"
    window.open(phantom_url);
    // navigate(phantom_url);
  }

  const goToMyOrders = () => {
    navigate('/my-orders/' + email)
  }

  const postMessageToApp = () => {
    if (Object.keys(activeAddress).length == 0) {
      toast.error('Please add or select the address', {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    } else {
      let phantom_url = "https://phantom.app/ul/browse/https://app.monkeycommerce.io/pay/" + email + "?ref=https://app.monkeycommerce.io"
      window.postMessage(JSON.stringify({ action: 'openNewWindow', url: phantom_url }));
    }

  }

  const payWithSplToken = async (e) => {
    let total_amt = subTotal + shippingCost + taxValue;
    let order_details = {
      products: allProduct,
      walletID: userId,
      storeID: allProduct[0]['storeID'],
      amount: total_amt,
      transactionHash:"spltoken",
      paymentID:'spltoken',
      paymentCurrency: allProduct[0]["acceptedCurrency"]
    };
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "/CreateOrder", {
        method: "POST",
        mode: "cors",
        headers: new Headers({ "content-type": "application/json" }),
        body: JSON.stringify(order_details),
      });
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
        toast("Order Placed Successfully", {
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
        navigate('/dashboard')
      } else {
        console.log(response.status);
        toast.error(response.status, {
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e: any) {
      console.log(e);
      toast.error(e, {
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
      });
    }
  }
  

  return (
    <>
      <ClipLoader
        loading={loading}
        cssOverride={override}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        className="loader"
      />
      {!loading && (
        <>
          {true ? (
            <>
              <ToastContainer />
              <div className="check-out-main">
                <div className="check-head">
                  <Row>
                    <Col sm={6}>
                      <div className="checkout-heading">
                        <h1>Checkout</h1>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="disclaimer">
                        <p>
                          Your information is collected for shipping purposes only.
                          Monkey Commerce does not store any of the below
                          information.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="checkout-content">
                  <Row>
                    <Col sm={6} className="mobile-address">
                      {isEditAddress == false && savedAddress ? (
                        <>
                          <div className="checkout-subhead">
                            <h2>
                              Saved Address{" "}

                            </h2>
                          </div>
                          {activeAddress && Object.keys(activeAddress).length === 0 && (<h6 className="text-info">No saved address found. please click add new address. </h6>)}
                          <h3 className="address-del">{activeAddress && Object.keys(activeAddress).length > 0 && ("Delivering Order to")} <span onClick={AddAddress}>Add new Address</span></h3>
                          {activeAddress && Object.keys(activeAddress).length > 0 && (
                            <>
                              {/* <div id={activeAddress["id"]} className="address-div active-address" >
                                <div className="address-details">
                                  <div className="address-head-action">
                                    <h3 className="address-edit" onClick={(e) => EditActiveAddress(e)} > Edit </h3>
                                    <h3 onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                  </div>
                                  <h3>{activeAddress["name"]}</h3>
                                  <h3>
                                    {activeAddress["addressLine1"]},{" "}
                                    {activeAddress["addressLine2"]},{" "}
                                    {activeAddress["city"]},{" "} {activeAddress["postalCode"]},{" "}
                                    {activeAddress["country"]},{" "}{activeAddress["mobileNumber"]},{" "}{activeAddress["emailAddress"]}
                                  </h3>
                                </div>
                              </div> */}

                              <div className="address-container">

                                <div className="">
                                  <label id={activeAddress["id"]} htmlFor="radio-card-1" className="radio-card">
                                    <input type="radio" name="radio-card" id="radio-card-1" checked />
                                    <div className="card-content-wrapper">
                                      <div className="address-head-action">
                                        <h3 className="address-edit" onClick={(e) => EditActiveAddress(e)} > Edit </h3>
                                        <h3 onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                      </div>
                                      <span className="check-icon"></span>
                                      <div className="card-content">

                                        <h4>
                                          {activeAddress["name"]},{" "}
                                          {activeAddress["addressLine1"]},{" "}
                                          {activeAddress["addressLine2"]},{" "}
                                          {activeAddress["city"]},{" "} {activeAddress["postalCode"]},{" "}
                                          {activeAddress["country"]},{" "}{activeAddress["mobileNumber"]},{" "}{activeAddress["emailAddress"]}
                                        </h4>

                                      </div>
                                    </div>
                                  </label>




                                </div>

                              </div>


                            </>
                          )}
                          {otherAddress.length > 0 && (<h3 className="address-del">Other Saved Address</h3>)}
                          {otherAddress.map((eachUser, index) => (
                            <>
                              {/* <div key={index} id={eachUser["id"]} className="address-div" onClick={() => selectAddress(eachUser["id"])}>
                                <div className="address-details">
                                  <div className="address-head-action">
                                    <h3 id={index.toString()} className="address-edit" onClick={(e) => EditOtherAddress(e)} > Edit </h3>
                                    <h3 id={eachUser["id"]} onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                  </div>
                                  <h3 id={eachUser["id"]} onClick={() => selectAddress(eachUser["id"])}>{eachUser["name"]}</h3>
                                  <h3 id={eachUser["id"]} onClick={() => selectAddress(eachUser["id"])}>
                                    {eachUser["addressLine1"]},{" "}
                                    {eachUser["addressLine2"]},{" "}
                                    {eachUser["city"]}, {eachUser["postalCode"]},{" "}
                                    {eachUser["country"]},{" "}{eachUser["mobileNumber"]},{" "}{eachUser["emailAddress"]}
                                  </h3>
                                </div>
                              </div> */}
                              <div className="address-container">

                                <div className="">
                                  <label key={index} id={eachUser["id"]} htmlFor={"radio-card-" + index + 1} className="radio-card">
                                    <input type="radio" name="radio-card" id={"radio-card-" + index + 1} onClick={() => selectAddress(eachUser["id"])} />
                                    <div className="card-content-wrapper">
                                      <div className="address-head-action">
                                        <h3 id={index.toString()} className="address-edit" onClick={(e) => EditOtherAddress(e)} > Edit </h3>
                                        <h3 id={eachUser["id"]} onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                      </div>
                                      <span className="check-icon"></span>
                                      <div className="card-content">

                                        <h4 id={eachUser["id"]} onClick={() => selectAddress(eachUser["id"])}>
                                          {eachUser["name"]},{" "}
                                          {eachUser["addressLine1"]},{" "}
                                          {eachUser["addressLine2"]},{" "}
                                          {eachUser["city"]}, {eachUser["postalCode"]},{" "}
                                          {eachUser["country"]},{" "}{eachUser["mobileNumber"]},{" "}{eachUser["emailAddress"]}
                                        </h4>

                                      </div>
                                    </div>
                                  </label>




                                </div>

                              </div>
                            </>
                          ))}
                          {/* <Button
                        className="shadow-btn shadow-btn-width"
                        variant="primary"
                      >
                        PLACE ORDER <img src={cartArrow} />
                      </Button> */}
                          <div className="pt-5 mobile-pay-btn">
                            {/* <Button className="mobile-pay-button" variant="primary" onClick={postMessageToApp}>
                              Continue With Payment <Icon.ArrowRight size={30} className="btn-icons" />
                            </Button> */}
                            <a href={"https://phantom.app/ul/browse/https://app.monkeycommerce.io/pay/" + email + "?ref=https://app.monkeycommerce.io"} className="mobile-pay-button" onClick={goToMyOrders}>
                              Continue With Payment 1 <Icon.ArrowRight size={30} className="btn-icons" />
                            </a>
                            {/* <Button className="shadow-btn shadow-btn-width" variant="primary" onClick={postMessageToApp}>
                              Continue With Payment 1
                            </Button>
                            <a href={"https://phantom.app/ul/browse/https://app.monkeycommerce.io/pay/" + email + "?ref=https://app.monkeycommerce.io"} className="p-5" >
                              Continue With Payment 2
                            </a> */}
                            {/* <HelioPay
                              cluster="mainnet"
                              payButtonTitle="Pay and Place Order1"
                              paymentRequestId="653f49e1f8042a6c66936519"
                              supportedCurrencies={["SOL"]}
                              totalAmount={0.0001}
                              // totalAmount={subTotal + shippingCost + taxValue}
                              onSuccess={function (event): void {
                                console.log("onSuccess", event);
                                paymentSuccess(event);
                              }}
                              onError={function (event: any): void {
                                console.log("onError", event);
                                toast.error(event, {
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: false,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                  theme: "light",
                                });
                              }}
                              onPending={function (event): void {
                                console.log("onPending", event);
                              }}
                              onStartPayment={function (): void {
                                console.log("onStartPayment");
                                toast("Payment Initiated", {
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: false,
                                  pauseOnHover: true,
                                  draggable: false,
                                  progress: undefined,
                                  theme: "light",
                                });
                              }}
                            /> */}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="checkout-subhead">
                            <h2>Shipping Information</h2>
                          </div>
                          {isEditAddress ? (
                            <>
                              <div>
                                <div className="check-out-form">
                                  <Form.Control
                                    className="form-input"
                                    size="lg"
                                    name="name"
                                    type="text"
                                    placeholder="Name *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["name"]}
                                  />
                                  <Form.Control
                                    as="select"
                                    className="form-input"
                                    name="country"
                                    size="lg"
                                    type="text"
                                    placeholder="Select Country *"
                                    onChange={(e) => handleEditAddressData(e)}
                                  >
                                    <option>Select Country *</option>
                                    <option
                                      value="india"
                                      selected={
                                        editAddress["country"] == "india"
                                          ? true
                                          : false
                                      }
                                    >
                                      India
                                    </option>
                                    <option
                                      value="usa"
                                      selected={
                                        editAddress["country"] == "usa"
                                          ? true
                                          : false
                                      }
                                    >
                                      USA
                                    </option>
                                  </Form.Control>
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine1"
                                    type="text"
                                    placeholder="Address Line 1 *"
                                    onChange={(e) => handleAddressData(e)}
                                    value={editAddress["addressLine1"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine2"
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["addressLine2"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="city"
                                    type="text"
                                    placeholder="City *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["city"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="landmark"
                                    type="text"
                                    placeholder="Landmark (Optional)"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["landmark"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="postalCode"
                                    type="text"
                                    placeholder="Postal Code *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["postalCode"]}
                                  />
                                </div>
                                <div className="checkout-subhead checkout-subhead-contact">
                                  <h2>Contact Information</h2>
                                </div>
                                <div className="check-out-form">
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="mobileNumber"
                                    type="text"
                                    placeholder="Mobile Number *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["mobileNumber"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="emailAddress"
                                    type="text"
                                    placeholder="Email Address *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["emailAddress"]}
                                  />
                                </div>
                                {/* <Form.Check 
                                                type="checkbox"
                                                id="save-info"
                                                name="isSaved"
                                                label="Save this information for next time"
                                                className="form-check"
                                                onChange={(e) => handleAddressData(e)}
                                            /> */}

                                <Button
                                  className="add-address-btn"
                                  variant="primary"
                                  onClick={saveEditUserAddress}
                                >
                                  Add Address
                                </Button>
                                <div className="pt-5 mobile-pay-btn">
                                  <Button className="mobile-pay-button" variant="primary" onClick={postMessageToApp}>
                                    Continue With Payment <Icon.ArrowRight size={30} className="btn-icons" />
                                  </Button>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div className="check-out-form">
                                  <Form.Control
                                    className="form-input"
                                    size="lg"
                                    name="name"
                                    type="text"
                                    placeholder="Name *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    as="select"
                                    className="form-input"
                                    name="country"
                                    size="lg"
                                    type="text"
                                    placeholder="Select Country *"
                                    onChange={(e) => handleAddressData(e)}
                                  >
                                    <option selected>Select Country *</option>
                                    <option value="india">India</option>
                                    <option value="usa">USA</option>
                                  </Form.Control>
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine1"
                                    type="text"
                                    placeholder="Address Line 1 *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine2"
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="city"
                                    type="text"
                                    placeholder="City *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="landmark"
                                    type="text"
                                    placeholder="Landmark (Optional)"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="postalCode"
                                    type="text"
                                    placeholder="Postal Code *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                </div>
                                <div className="checkout-subhead checkout-subhead-contact">
                                  <h2>Contact Information</h2>
                                </div>
                                <div className="check-out-form">
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="mobileNumber"
                                    type="text"
                                    placeholder="Mobile Number *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="emailAddress"
                                    type="text"
                                    placeholder="Email Address *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                </div>
                                <Form.Check
                                  type="checkbox"
                                  id="save-info"
                                  name="isSaved"
                                  label="Save this information for next time"
                                  className="form-check"
                                  onChange={(e) => handleAddressData(e)}
                                />
                                <Button
                                  className="add-address-btn"
                                  variant="primary"
                                  onClick={saveUserAddress}
                                >
                                  Add Address
                                </Button>
                                <Button
                                  className="close-address-btn"
                                  variant="primary"
                                  onClick={() => setSavedAddress(true)}
                                >
                                  Close
                                </Button>
                                <div className="pt-5 mobile-pay-btn">
                                  <Button className="mobile-pay-button" variant="primary" onClick={postMessageToApp}>
                                    Continue With Payment <Icon.ArrowRight size={30} className="btn-icons" />
                                  </Button>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                    <Col sm={6}>
                      <div className="checkout-subhead">
                        <h2>Order Summary</h2>
                      </div>
                      <div className="checkout-product desktop-checkout-product">
                        <Card className="checkout-card">
                          <Card.Body className="checkout-card-body">
                            <p>Delivers in 3-7 Days</p>
                            <div>
                              {allProduct.map((product, productIndex) => (
                                <Row className="checkout-each">
                                  <Col sm={3}>
                                    <img src={product['imagesFront']} alt="product-img" />
                                  </Col>
                                  <Col sm={9}>
                                    <h3>{product['productName']}</h3>
                                    <h6>Size: <strong>{product['productSelectedSize']}</strong></h6>
                                    <h6>Quantity: <strong>{product['productQuantity']}</strong></h6>
                                    <h6>Colour: <strong>{product['productSelectedColor']}</strong> <span>{Number(product['cartPrice']).toFixed(4)} {product['productSelectedPayment']}</span></h6>
                                    {/* <h4>800 SOL</h4> */}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                            <div className="product-subtotal">
                              <h5>SubTotal <span>{(subTotal + taxValue).toFixed(2)} {orderPaymentCurrency}</span></h5>
                              <h5>Shipping <span>{shippingCost} {orderPaymentCurrency}</span></h5>
                              <h5>
                                Tax <span>{taxValue.toFixed(4)} {baseProductCurrency}</span>
                              </h5>
                            </div>
                            <div className="product-total">
                              <h3>Total <span>{(subTotal + shippingCost + taxValue).toFixed(2)} {orderPaymentCurrency}</span></h3>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                      <div className="checkout-product mobile-checkout-product">
                        <Card className="checkout-card">
                          <Card.Body className="checkout-card-body">
                            <p>Delivers in 3-7 Days</p>
                            <div>
                              {allProduct.map((product, productIndex) => (
                                <Row className="checkout-each">
                                  <Col sm={3} className="col-width-3">
                                    <img src={product['imagesFront']} alt="product-img" />
                                  </Col>
                                  <Col sm={9} className="col-width-9">
                                    <Link to={`/store-product-detail/${product['id']}`}>
                                      <h3>{product["productName"]}</h3>
                                    </Link>
                                    <h6>
                                      Size:{" "}
                                      <strong>
                                        {product["productSelectedSize"]}
                                      </strong>
                                    </h6>
                                    <h6>
                                      Quantity:{" "}
                                      <strong>{product["productQuantity"]}</strong>
                                    </h6>
                                    <h6>
                                      Price:{" "}
                                      <strong>{Number(product["cartPrice"]).toFixed(4)} {product['productSelectedPayment']}</strong>
                                    </h6>
                                    {/* <h4>800 SOL</h4> */}
                                  </Col>
                                </Row>
                              ))}
                            </div>
                            <div className="product-subtotal">
                              <h5>
                                SubTotal <span>{(subTotal + taxValue).toFixed(2)} {orderPaymentCurrency}</span>
                              </h5>
                              <h5>
                                Shipping <span>{shippingCost} {orderPaymentCurrency}</span>
                              </h5>
                              <h5>
                                Tax <span>{taxValue.toFixed(4)} {baseProductCurrency}</span>
                              </h5>
                            </div>
                            <div className="product-total">
                              <h3>
                                Total{" "}
                                <span>
                                  {(subTotal + shippingCost + taxValue).toFixed(2)} {orderPaymentCurrency}
                                </span>
                              </h3>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col sm={6} className="desktop-address">
                      {isEditAddress == false && savedAddress ? (
                        <>
                          <div className="checkout-subhead">
                            <h2>
                              Saved Address{" "}
                              <span onClick={AddAddress}>Add new Address</span>
                            </h2>
                          </div>
                          {activeAddress && Object.keys(activeAddress).length === 0 && (<h6 className="text-info">No saved address found. please click add new address. </h6>)}
                          {activeAddress && Object.keys(activeAddress).length > 0 && (
                            <>
                              <h3 className="address-del">Delivering Order to</h3>

                              <div id={activeAddress["id"]} className="address-div active-address" >
                                <div className="address-details">
                                  <div className="address-head-action">
                                    <h3 className="address-edit" onClick={(e) => EditActiveAddress(e)} > Edit </h3>
                                    <h3 onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                  </div>
                                  <h3>{activeAddress["name"]}</h3>
                                  <h3>
                                    {activeAddress["addressLine1"]},{" "}
                                    {activeAddress["addressLine2"]},{" "}
                                    {activeAddress["city"]},{" "} {activeAddress["postalCode"]},{" "}
                                    {activeAddress["country"]},{" "}{activeAddress["mobileNumber"]},{" "}{activeAddress["emailAddress"]}
                                  </h3>
                                </div>
                              </div>
                            </>
                          )}
                          {otherAddress.length > 0 && (<h3 className="address-del">Other Saved Address</h3>)}
                          {otherAddress.map((eachUser, index) => (
                            <div key={index} id={eachUser["id"]} className="address-div" onClick={() => selectAddress(eachUser["id"])}>
                              <div className="address-details">
                                <div className="address-head-action">
                                  <h3 id={index.toString()} className="address-edit" onClick={(e) => EditOtherAddress(e)} > Edit </h3>
                                  <h3 id={eachUser["id"]} onClick={RemoveAddress} className="address-delete" > Remove </h3>
                                </div>
                                <h3 id={eachUser["id"]} onClick={() => selectAddress(eachUser["id"])}>{eachUser["name"]}</h3>
                                <h3 id={eachUser["id"]} onClick={() => selectAddress(eachUser["id"])}>
                                  {eachUser["addressLine1"]},{" "}
                                  {eachUser["addressLine2"]},{" "}
                                  {eachUser["city"]}, {eachUser["postalCode"]},{" "}
                                  {eachUser["country"]},{" "}{eachUser["mobileNumber"]},{" "}{eachUser["emailAddress"]}
                                </h3>
                              </div>
                            </div>
                          ))}
                          {activeAddress && Object.keys(activeAddress).length > 0 &&
                            <>
                              {
                                orderPaymentCurrency != 'USDC' && orderPaymentCurrency != 'SOL' &&

                                <Button
                                  className="shadow-btn shadow-btn-width"
                                  variant="primary"
                                  onClick={payWithSplToken}
                                >
                                  PLACE ORDER <img src={cartArrow} />
                                </Button>
                              }
                              {
                                (orderPaymentCurrency == 'USDC' || orderPaymentCurrency == 'SOL') &&
                                <div className="pt-5">
                                  <HelioPay
                                    cluster="mainnet"
                                    payButtonTitle="Pay and Place Order2"
                                    paymentRequestId="653f49e1f8042a6c66936519"
                                    supportedCurrencies={["SOL"]}
                                    totalAmount={0.0001}
                                    // totalAmount={subTotal + shippingCost + taxValue}
                                    onSuccess={function (event): void {
                                      console.log("onSuccess", event);
                                      paymentSuccess(event);
                                    }}
                                    onError={function (event: any): void {
                                      console.log("onError", event);
                                      toast.error(event, {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                    onPending={function (event): void {
                                      console.log("onPending", event);
                                    }}
                                    onStartPayment={function (): void {
                                      console.log("onStartPayment");
                                      toast("Payment Initiated", {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                  />
                                </div>
                              }
                            </>
                          }
                        </>
                      ) : (
                        <>
                          <div className="checkout-subhead">
                            <h2>Shipping Information</h2>
                          </div>
                          {isEditAddress ? (
                            <>
                              <div>
                                <div className="check-out-form">
                                  <Form.Control
                                    className="form-input"
                                    size="lg"
                                    name="name"
                                    type="text"
                                    placeholder="Name *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["name"]}
                                  />
                                  <Form.Control
                                    as="select"
                                    className="form-input"
                                    name="country"
                                    size="lg"
                                    type="text"
                                    placeholder="Select Country *"
                                    onChange={(e) => handleEditAddressData(e)}
                                  >
                                    <option>Select Country *</option>
                                    <option
                                      value="india"
                                      selected={
                                        editAddress["country"] == "india"
                                          ? true
                                          : false
                                      }
                                    >
                                      India
                                    </option>
                                    <option
                                      value="usa"
                                      selected={
                                        editAddress["country"] == "usa"
                                          ? true
                                          : false
                                      }
                                    >
                                      USA
                                    </option>
                                  </Form.Control>
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine1"
                                    type="text"
                                    placeholder="Address Line 1 *"
                                    onChange={(e) => handleAddressData(e)}
                                    value={editAddress["addressLine1"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine2"
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["addressLine2"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="city"
                                    type="text"
                                    placeholder="City *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["city"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="landmark"
                                    type="text"
                                    placeholder="Landmark (Optional)"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["landmark"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="postalCode"
                                    type="text"
                                    placeholder="Postal Code *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["postalCode"]}
                                  />
                                </div>
                                <div className="checkout-subhead checkout-subhead-contact">
                                  <h2>Contact Information</h2>
                                </div>
                                <div className="check-out-form">
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="mobileNumber"
                                    type="text"
                                    placeholder="Mobile Number *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["mobileNumber"]}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="emailAddress"
                                    type="text"
                                    placeholder="Email Address *"
                                    onChange={(e) => handleEditAddressData(e)}
                                    value={editAddress["emailAddress"]}
                                  />
                                </div>
                                {/* <Form.Check 
                                                type="checkbox"
                                                id="save-info"
                                                name="isSaved"
                                                label="Save this information for next time"
                                                className="form-check"
                                                onChange={(e) => handleAddressData(e)}
                                            /> */}
                                <Button
                                  className="shadow-btn shadow-btn-width"
                                  variant="primary"
                                  onClick={saveEditUserAddress}
                                >
                                  Save Address
                                </Button>
                                <div className="pt-5">
                                  <HelioPay
                                    cluster="mainnet"
                                    payButtonTitle="Pay and Place Order"
                                    paymentRequestId="653f49e1f8042a6c66936519"
                                    supportedCurrencies={["SOL"]}
                                    totalAmount={0.0001}
                                    // totalAmount={subTotal + shippingCost + taxValue}
                                    onSuccess={function (event): void {
                                      console.log("onSuccess", event);
                                      paymentSuccess(event);
                                    }}
                                    onError={function (event: any): void {
                                      console.log("onError", event);
                                      toast.error(event, {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                    onPending={function (event): void {
                                      console.log("onPending", event);
                                    }}
                                    onStartPayment={function (): void {
                                      console.log("onStartPayment");
                                      toast("Payment Initiated", {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <div className="check-out-form">
                                  <Form.Control
                                    className="form-input"
                                    size="lg"
                                    name="name"
                                    type="text"
                                    placeholder="Name *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    as="select"
                                    className="form-input"
                                    name="country"
                                    size="lg"
                                    type="text"
                                    placeholder="Select Country *"
                                    onChange={(e) => handleAddressData(e)}
                                  >
                                    <option selected>Select Country *</option>
                                    <option value="india">India</option>
                                    <option value="usa">USA</option>
                                  </Form.Control>
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine1"
                                    type="text"
                                    placeholder="Address Line 1 *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="addressLine2"
                                    type="text"
                                    placeholder="Address Line 2"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="city"
                                    type="text"
                                    placeholder="City *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="landmark"
                                    type="text"
                                    placeholder="Landmark (Optional)"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="postalCode"
                                    type="text"
                                    placeholder="Postal Code *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                </div>
                                <div className="checkout-subhead checkout-subhead-contact">
                                  <h2>Contact Information</h2>
                                </div>
                                <div className="check-out-form">
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="mobileNumber"
                                    type="text"
                                    placeholder="Mobile Number *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                  <Form.Control
                                    size="lg"
                                    className="form-input"
                                    name="emailAddress"
                                    type="text"
                                    placeholder="Email Address *"
                                    onChange={(e) => handleAddressData(e)}
                                  />
                                </div>
                                <Form.Check
                                  type="checkbox"
                                  id="save-info"
                                  name="isSaved"
                                  label="Save this information for next time"
                                  className="form-check"
                                  onChange={(e) => handleAddressData(e)}
                                />
                                <Button
                                  className="shadow-btn shadow-btn-width"
                                  variant="primary"
                                  onClick={saveUserAddress}
                                >
                                  Save Address
                                </Button>
                                <div className="pt-5">
                                  <HelioPay
                                    cluster="mainnet"
                                    payButtonTitle="Pay and Place Order"
                                    paymentRequestId="653f49e1f8042a6c66936519"
                                    supportedCurrencies={["SOL"]}
                                    totalAmount={0.0001}
                                    // totalAmount={subTotal + shippingCost + taxValue}
                                    onSuccess={function (event): void {
                                      console.log("onSuccess", event);
                                      paymentSuccess(event);
                                    }}
                                    onError={function (event: any): void {
                                      console.log("onError", event);
                                      toast.error(event, {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                    onPending={function (event): void {
                                      console.log("onPending", event);
                                    }}
                                    onStartPayment={function (): void {
                                      console.log("onStartPayment");
                                      toast("Payment Initiated", {
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: false,
                                        pauseOnHover: true,
                                        draggable: false,
                                        progress: undefined,
                                        theme: "light",
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                id="kt_content_container"
                className="d-flex flex-column-fluid align-items-start "
              >
                <div
                  className={`${isMobile
                    ? "px-5"
                    : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                    }`}
                >
                  <div
                    className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                      }`}
                  >
                    <div
                      className={`${isMobile
                        ? ""
                        : "d-flex flex-center flex-column flex-column-fluid"
                        }`}
                    >
                      <div className="row">
                        <div className="col-12">
                          <div className="create-raffle-wallet-connet">
                            <img
                              className="reach-us-img"
                              alt=""
                              src={reach_us_img}
                            />
                            <div className="reach-us-to-whitelist">
                              Please connect your wallet.
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="wallet-button">
                            <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};
