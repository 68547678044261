import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
  useMemo,
  forwardRef,
} from "react";
import { useNavigate, Link } from "react-router-dom";
// import { useProSidebar } from "react-pro-sidebar";
import { CompSideBar } from "../components/sidebar";
import { Row, Col, Card, Form } from "react-bootstrap";
import reach_us_img from "../images/contact-us.png";
// import AddBox from '@material-ui/icons/AddBox';
import { AddBox, ArrowDownward, Check, ChevronLeft, ChevronRight, Clear, DeleteOutline, Edit, FilterList, FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@material-ui/icons";
import MaterialTable from "material-table";
import product1 from "../images/products/product-image.png";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "./order-details.css";




export const OrderDetails = ({ publicKey, userData }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  // const { collapseSidebar } = useProSidebar();


  const tableIcons: any = {
    Add: forwardRef((props: any, ref: any) => <AddBox {...props} {...ref} />),
    Check: forwardRef((props: any, ref: any) => <Check {...props} {...ref} />),
    Clear: forwardRef((props: any, ref: any) => <Clear {...props} {...ref} />),
    Delete: forwardRef((props: any, ref: any) => <DeleteOutline {...props} {...ref} />),
    DetailPanel: forwardRef((props: any, ref: any) => <ChevronRight {...props} {...ref} />),
    Edit: forwardRef((props: any, ref: any) => <Edit {...props} {...ref} />),
    Export: forwardRef((props: any, ref: any) => <SaveAlt {...props} {...ref} />),
    Filter: forwardRef((props: any, ref: any) => <FilterList {...props} {...ref} />),
    FirstPage: forwardRef((props: any, ref: any) => <FirstPage {...props} {...ref} />),
    LastPage: forwardRef((props: any, ref: any) => <LastPage {...props} {...ref} />),
    NextPage: forwardRef((props: any, ref: any) => <ChevronRight {...props} {...ref} />),
    PreviousPage: forwardRef((props: any, ref: any) => <ChevronLeft {...props} {...ref} />),
    ResetSearch: forwardRef((props: any, ref: any) => <Clear {...props} {...ref} />),
    Search: forwardRef((props: any, ref: any) => <Search {...props} {...ref} />),
    SortArrow: forwardRef((props: any, ref: any) => <ArrowDownward {...props} {...ref} />),
    ThirdStateCheck: forwardRef((props: any, ref: any) => <Remove {...props} {...ref} />),
    ViewColumn: forwardRef((props: any, ref: any) => <ViewColumn {...props} {...ref} />)
  };
  const tableData = [{ "id": "1", "orderDate": "23-12-2024", "transactionHash": "dsfgsfsdbvsdvd", "shippingCountry": "India", "amount": "23", "orderStatus": "Order Placed" }]
  const [orderID, SetOrderId] = useState('');
  const [myOrder, setOrder] = useState({});
  const [allProducts, setAllProducts] = useState([]);

  const getMyOrderByID = async (orderID) => {
    console.log(orderID);
    if (orderID) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "GetMyOrdersByID",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ id: orderID }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setOrder(data['orders']);
          setAllProducts(data['orders']['products'])
          // setAllProducts(data['orders']['products']);

        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  const updateOrderDetails = async (e) => {
    const { name, value } = e.target;
    try {
      const response = await fetch(process.env.REACT_APP_API_URL + "UpdateOrder",
        {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ id: orderID, orderStatus: value }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log(data);
      } else {
        console.log(response.status);
      }
    } catch (e) {

    }
  }

  useEffect(() => {
    var url_path = window.location.pathname;
    console.log(url_path);
    var product_id = url_path.split("/")[2];
    console.log(product_id);
    SetOrderId(product_id);

  }, [navigate]);
  useEffect(() => {
    async function data() {
      await getMyOrderByID(orderID);
    }
    data();
  }, [navigate, orderID]);



  return (
    <>
      {true ? (
        <>
          <div>
            <CompSideBar publicKey={publicKey} />
          </div>
          <div className="mainpage-dashboard">
            <div className="container-fluid">
              <div className="breadcrumbs">
                <ul className="breadcrumbs-list">
                  <li><Link to="/">Home</Link></li>
                  <li className="breadcrumbs-item-space">{">"}</li>
                  <li><Link to={"/my-orders/"}>My Orders</Link></li>
                </ul>
              </div>
              <div className="order-details-header">
                {/* <h2 className="sora-bold">All Products from Order ID : {orderID}</h2> */}
                <Card className="product-card">
                  {Object.keys(myOrder).length > 0 &&
                    <Card.Body className="product-card-body">
                      <Row>
                        <Col sm={6}>
                          <h4>Order ID : {orderID}</h4>
                          <h4>Order Date : {myOrder['orderDate']}</h4>
                        </Col>
                        <Col sm={6}>
                          <h4>Order Amount : {myOrder['amount']} {allProducts[0]['productSelectedPayment']}</h4>
                          {userData['role'] != 'super-admin' && <h4>Order Status : {myOrder['orderStatus']}</h4>}
                          {userData['role'] == 'creator' && <h4>Order Profit : {myOrder['totalProfit'].toFixed(5)} {allProducts[0]['productSelectedPayment']}</h4>}
                          {userData['role'] == 'super-admin' &&
                            <>
                              <h4>Order Status</h4>
                              <Form.Control
                                as="select"
                                className="form-input order-status-seleact-box"
                                name="order-status"
                                size="lg"
                                type="text"
                                placeholder="Change the order status"
                                onChange={(e) => updateOrderDetails(e)}
                              >

                                <option
                                  value="Order Placed"
                                  selected={
                                    myOrder['orderStatus'] == "Order Placed"
                                      ? true
                                      : false
                                  }
                                >
                                  Order Placed
                                </option>
                                <option
                                  value="Print In Progress"
                                  selected={
                                    myOrder['orderStatus'] == "Print In Progress"
                                      ? true
                                      : false
                                  }
                                >
                                  Print In Progress
                                </option>
                                <option
                                  value="Ready To Ship"
                                  selected={
                                    myOrder['orderStatus'] == "Ready To Ship"
                                      ? true
                                      : false
                                  }
                                >
                                  Ready To Ship
                                </option>
                                <option
                                  value="Shipped"
                                  selected={
                                    myOrder['orderStatus'] == "Shipped"
                                      ? true
                                      : false
                                  }
                                >
                                  Shipped
                                </option>
                                <option
                                  value="Delivered"
                                  selected={
                                    myOrder['orderStatus'] == "Delivered"
                                      ? true
                                      : false
                                  }
                                >
                                  Delivered
                                </option>

                              </Form.Control>
                            </>
                          }
                        </Col>
                      </Row>
                    </Card.Body>
                  }
                </Card>
              </div>
              <div className="order-details-cards">
                {allProducts.map((product, productIndex) => (
                  <div>
                    <Card className="product-card">
                      <Card.Body className="product-card-body">
                        <Row>
                          <Col sm={2}>
                            <div>
                              <img className="product-image" src={product['imagesFront']} alt="" />
                            </div>
                          </Col>
                          <Col sm={10}>
                            <div className="product-card-heading">
                              <h1>{product['productName']} <span>Price :  {product['price']} {product['productSelectedPayment']}</span></h1>
                            </div>
                            <div className="product-cart-details">
                              <h3>Color: <span>{product['productSelectedColor']}</span></h3>
                              <h3>Size: <span>{product['productSelectedSize']}</span></h3>
                            </div>
                            <div className="product-profit-details">
                              <h3> Product Profit :  <span>{product['product_profit']} {product['productSelectedPayment']}</span></h3>
                            </div>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
            >
              <div
                className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                  }`}
              >
                <div
                  className={`${isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                    }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}