import React, { useEffect, useRef, useState, useReducer } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { Row, Col, Card, Accordion } from "react-bootstrap";
import * as Icon from 'react-bootstrap-icons';
import "./home.css";
import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import explainer_img from "../images/product-explain.gif";
import img_shirt from "../images/shirt-01.png";
import img_truck from "../images/truck-delivery.png";
import img_star from "../images/star-circle.png";
import heading_bg_icon from "../images/Ellipse 313.png"
import h_w_img_1 from "../images/Group 616.png";
import h_w_img_2 from "../images/shirt-01.png";
import h_w_img_3 from "../images/marketing.png";
import h_w_img_4 from "../images/Group 613.png";
import h_w_img_5 from "../images/Group 612.png";
import h_w_img_6 from "../images/truck-delivery-1.png";
import h_w_img_7 from "../images/Group 610.png";
import c_f_img_1 from "../images/Frame-4.png";
import c_f_img_2 from "../images/Frame.png";
import c_f_img_3 from "../images/Frame (1).png";
import c_f_img_4 from "../images/Frame (2).png";
import o_f_img_1 from "../images/Group 697.png";
import o_f_img_2 from "../images/Group 698.png";
import o_f_img_3 from "../images/Group 699.png";
import o_f_img_4 from "../images/Group 700.png";
import o_f_img_5 from "../images/Group 701.png";
import o_f_img_6 from "../images/Group 702.png";
import c_a_img_1 from "../images/eth-dynamic-premium.png";
import c_a_img_2 from "../images/dollar-dollar-premium.png";
import solanalogo from "../images/icons/solanalogo.png";
import usdclogo from "../images/icons/USD_Coin_icon.webp";

import store_img_1 from "../images/store-img2.png";
import { Icons } from "react-toastify";

export const Home = () => {
  const storeSlider = useRef<Slider | null>(null);
  const productSlider = useRef<Slider | null>(null);
  const [sliderKey, setSliderKey] = useState(0);
  // Access the email parameter from the URL
  const { email } = useParams();
  const [isPreLoading, setIsPreLoading] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allStores, setAllStores] = useState([]);
  const navigate = useNavigate();

  console.log("Home Component Rendered");

  useEffect(() => {
    console.log("Home Component Mounted");
    async function fetchData() {
      console.log("Fetching stores...");
      setIsPreLoading(true);
      try {
        const stores = await getStores();
        // (stores); // Assuming getStores returns the array of stores
      } catch (error) {
        console.error("Error fetching stores:", error);
      } finally {
        setIsPreLoading(false);
      }
    }

    fetchData();
    return () => console.log("Home Component Unmounted");
  }, []); // Empty dependency array to run only on component mount



  useEffect(() => {

    const fetchProducts = async () => {
      await getAllProducts();
    };
    fetchProducts();

  }, []);

  const getAllProducts = async () => {
    try {
      setAllProducts([]);
      const response = await fetch(
        process.env.REACT_APP_API_URL + "get_allProducts",
        { mode: "cors" }
      );
      if (response.status === 200) {
        let data = await response.json();
        setAllProducts(data["products"]);
        setIsPreLoading(false);
      } else {
        setIsPreLoading(false);
      }
    } catch (e) {
      setIsPreLoading(false);
      console.log(e);
      return;
    }
  };


  useEffect(() => {
    // Check if the slider has been initialized
    if (productSlider.current) {
      // Define your dynamic settings based on conditions or data
      const dynamicSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: allProducts.length < 3 ? allProducts.length : 3,
        slidesToScroll: 1,
        // Add more dynamic settings as needed
      };

      // Go to the first slide with the updated settings
      productSlider.current.slickGoTo(0, null, dynamicSettings);
    }
  }, [allProducts]);



  const settings = {
    infinite: false,
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    infinite: false,
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  const getStores = async () => {
    console.log("Calling Get store")
    try {

      const response = await fetch(process.env.REACT_APP_API_URL + "get_allStores",
        {
          method: "GET",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        console.log('response' + data['stores']);
        await setAllStores([]);
        await setAllStores(data['stores']);
        return data['stores']


      } else {
        setIsPreLoading(false);
        return []
      }
    } catch (e) {
      setIsPreLoading(false);
      return []
    }
  };


  const redirect_to_product = (id) => {
    if (email) {
      navigate("/store-product-detail/" + id + "/" + email);
    } else {
      navigate("/store-product-detail/" + id);
    }
  };

  const redirect_to_store = (id) => {
    if (email) {
      navigate("/store-detail/" + id + "/" + email);
    } else {
      navigate("/store-detail/" + id);
    }
  };

  const view_all_store = (id) => {
    if (email) {
      navigate("/stores/" + email);
    } else {
      navigate("/stores");
    }
  };

  const view_all_product = (id) => {
    if (email) {
      navigate("/products/" + email);
    } else {
      navigate("/products");
    }
  };


  useEffect(() => {
    console.log("------- Email Received --------");

    console.log(email)
  }, [email]);

  return (
    <>
      <main className="main">
        <section className="first-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={6} >
                <div className="f-s-heading">
                  Bring Your NFT Project to Life with Your Own Custom Store
                </div>
                <div className="f-s-sub-heading">
                  Seamlessly Transform Digital Art into Exclusive Merchandise
                </div>
                <div className="f-s-features">
                  <span><img src={img_shirt} />300+ Products</span> <span><img src={img_truck} />Free Delivery</span> <span><img src={img_star} />Quality assured</span>
                </div>
                <div className="f-s-button">
                  <button
                    className="btn btn-md rounded font-sm hover-up create-product-btn"
                  // onClick={}
                  >
                    Create Your Store Today  <Icon.ArrowRight size={30} className="btn-icons" />
                  </button>
                </div>
              </Col>
              <Col sm={6}>
                <div className="f-s-t-shirt-img">
                  <img src={explainer_img} />
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="second-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} md={12} lg={12}>
                <div className="s-s-heading">
                  Launch Store and publish your designs with in 2 mins
                  {/* <img src={heading_bg_icon} /> */}
                </div>

                <div>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              1
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_1} />
                            </div>
                            <div className="h-w-text">
                              Register and Customize
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              2
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_2} />
                            </div>
                            <div className="h-w-text">
                              Design your  Merch
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              3
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_3} />
                            </div>
                            <div className="h-w-text">
                              Price and Publish
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>

                  </Row>
                  <Row className="h-w-s-row">
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              4
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_4} />
                            </div>
                            <div className="h-w-text">
                              Promote and Engage
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              5
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_5} />
                            </div>
                            <div className="h-w-text">
                              Track and Optimize
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={4}>
                      <div>
                        <Card className="h-w-card">
                          <Card.Body>
                            <div className="h-w-no">
                              6
                            </div>
                            <div className="h-w-img">
                              <img src={h_w_img_6} />
                            </div>
                            <div className="h-w-text">
                              Fulfillment and Support
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </Col>

                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        {/* 
        <section className="third-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  RECENTLY CREATED STORES
                </div>
                <div className="view-all-btn" onClick={view_all_store}>
                  <p>View All</p> <Icon.ArrowRight></Icon.ArrowRight>
                </div>
                <div>
                  {!isPreLoading &&
                    <Slider className="store-slider-container" ref={storeSlider} {...settings}>
                      {allStores.map((store, index) => (
                        <Card key={store['id']} className="r-c-s-card" onClick={() => redirect_to_store(store['id'])}>
                          <Card.Body>
                            <div className="r-c-s-img">
                              <img src={store['storeAvatar']} />
                            </div>
                            <div className="r-c-s-name">
                              {store['storeName']}
                            </div>
                            <div className="r-c-s-category">
                              {store['design_product_count']} Products
                            </div>
                            <div className="r-c-s-currency-logo">
                              <img src={usdclogo} />
                              <img src={solanalogo} />
                            </div>
                          </Card.Body>
                        </Card>
                      ))}

                    </Slider>
                  }

                  <div className="slider-arrows">
                    <button onClick={() => storeSlider?.current?.slickPrev()} className="slider-arrow-left"><Icon.ChevronLeft></Icon.ChevronLeft> </button>
                    <button onClick={() => storeSlider?.current?.slickNext()} className="slider-arrow-right"><Icon.ChevronRight></Icon.ChevronRight></button>
                  </div>

                </div>
              </Col>
            </Row>
          </div>
        </section> */}
        <section className="third-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  How It Works?
                </div>

                <div>
                  <ul className="process">
                    <li className="process__item">
                      <span className="process__number">1</span>
                      <span className="process__title">CHOOSE YOUR NFT</span>
                      <span className="process__subtitle">Simply connect your wallet and select the NFT you love. We'll instantly showcase your chosen NFT on an array of NFT merchandise options. Ready to see your digital art come to life? Let's Begin</span>
                    </li>

                    <li className="process__item">
                      <span className="process__number">2</span>
                      <span className="process__title">PICK A PRODUCT</span>
                      <span className="process__subtitle">Explore our extensive range of NFT merchandise, including canvas prints, posters, t-shirts, phone cases, and beyond. Choose the product that speaks to you and head over to checkout. Discover Our Collection</span>
                    </li>

                    <li className="process__item">
                      <span className="process__number">3</span>
                      <span className="process__title">FINALIZE YOUR ORDER</span>
                      <span className="process__subtitle">Place your order and sit back. We'll dispatch your custom NFT merchandise from our worldwide network of 16 manufacturing centers within 3 - 4 business days. Your favorite NFT is about to become a part of your everyday life.</span>
                    </li>


                  </ul>

                </div>
              </Col>
            </Row>
          </div>
        </section>

        {/* <section className="fourth-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  RECENTLY ADDED PRODUCTS
                </div>
                <div className="view-all-btn" onClick={view_all_product}>
                  <p>View All</p> <Icon.ArrowRight></Icon.ArrowRight>
                </div>
                <div>
                  <Slider className="product-slider-container" ref={productSlider} {...settings2}>
                    {allProducts.map((product, index) => (
                      <Card className="r-a-p-card" onClick={() => redirect_to_product(product['id'])}>
                        <Card.Body>
                          <div className="r-a-p-img">
                            <img src={product['imagesFront']} />
                          </div>
                          <div className="r-a-p-name">
                            {product['productName']}
                          </div>
                          <div className="r-a-p-price">
                            {product['sellingPrice']} {product['acceptedCurrency']}
                          </div>
                        </Card.Body>
                      </Card>
                    ))}

                  </Slider>

                  <div className="slider-arrows">
                    <button onClick={() => productSlider?.current?.slickPrev()} className="slider-arrow-left"><Icon.ChevronLeft></Icon.ChevronLeft> </button>
                    <button onClick={() => productSlider?.current?.slickNext()} className="slider-arrow-right"><Icon.ChevronRight></Icon.ChevronRight></button>
                  </div>

                </div>
              </Col>
            </Row>
          </div>
        </section> */}



        <section className="fifth-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  Who Is Monkey Commerce For?
                </div>
                <div className="s-s-sub-heading">
                  Empowering Creators, Brands, and Communities in the Crypto Space<br />
                  Designed for the Heartbeat of Crypto: Creators, Projects, and Degens
                </div>
                <div>
                  <Row>
                    <Col sm={6}>
                      <Card className="c-f-card">
                        <Card.Body>
                          <div>
                            <img src={c_f_img_1} />
                          </div>
                          <div>
                            <div className="c-f-heading">For Crypto Projects</div>
                            <div className="c-f-content">
                              Unlock new levels of project loyalty and visibility. Create custom merch that strengthens your brand identity and unites your community, all while offering a new stream of revenue to fuel your future innovations.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card className="c-f-card">
                        <Card.Body>
                          <div>
                            <img src={c_f_img_2} />
                          </div>
                          <div>
                            <div className="c-f-heading">Digital Artists</div>
                            <div className="c-f-content">
                              Showcase your digital art in the physical world. Our platform allows you to effortlessly translate your digital creations into merchandise that your audience can wear, use, and cherish.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>

                    <Col sm={6}>
                      <Card className="c-f-card">
                        <Card.Body>
                          <div>
                            <img src={c_f_img_4} />
                          </div>
                          <div>
                            <div className="c-f-heading">NFT Projects</div>
                            <div className="c-f-content">
                              Maximize your project’s impact with custom merchandise that showcases your unique NFTs. Strengthen your community and brand presence with high-quality products that extend your digital assets into everyday life.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col sm={6}>
                      <Card className="c-f-card">
                        <Card.Body>
                          <div>
                            <img src={c_f_img_4} />
                          </div>
                          <div>
                            <div className="c-f-heading">Crypto Enthusiasts & Degens</div>
                            <div className="c-f-content">
                              Wear your passion on your sleeve—literally. Celebrate your favorite cryptocurrencies, tokens, or NFTs with custom gear. From casual enthusiasts to the most dedicated degens, turn your digital assets into personal statements.
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="mx-auto">
                <Card className="c-f-card">
                  <Card.Body>
                    <div>
                      <img src={c_f_img_3} />
                    </div>
                    <div>
                      <div className="c-f-heading">Influencers</div>
                      <div className="c-f-content">
                        Elevate your influence and strengthen your community with custom merchandise that resonates with your followers. Create products that celebrate your brand and engage your audience in a meaningful way.
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </section>

        <section className="six-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  Your NFT Merchandise, Your Brand
                </div>
                <div className="s-s-sub-heading">
                  With Monkey Commerce, transforming your NFT art into a branded merchandise empire has never been easier. From customizable storefronts to global fulfillment, we empower you to create, launch, and thrive in the physical merchandise space. Join us, and let's turn your digital community into a tangible success story.
                </div>
                <Row>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_1} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Seamless Wallet Integration
                          </div>
                          <div className="o-f-content">
                            Quickly connect your digital wallet to access and use your NFT collection for merchandise creation. Compatible with major wallets for a hassle-free start.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_2} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Extensive Product Catalog
                          </div>
                          <div className="o-f-content">
                            Explore over 150+ customizable product options ranging from apparel and accessories to home decor, transforming your NFTs into unique physical items.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_5} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Automated NFT Licensing Verification
                          </div>
                          <div className="o-f-content">
                            Ensure the legality of using your NFTs with our automated check for commercial permissions, respecting copyright and creator terms.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_3} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            High-Quality Customization Options
                          </div>
                          <div className="o-f-content">
                            Personalize products with advanced tools to adjust colors, placement, and sizing, making each piece uniquely yours.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_4} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Global Manufacturing and Fulfillment Network
                          </div>
                          <div className="o-f-content">
                            Enjoy fast, worldwide delivery from our global manufacturing centers, ensuring your merchandise reaches you or your customers promptly.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_6} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Crypto-Friendly Payments
                          </div>
                          <div className="o-f-content">
                            Facilitate purchases with various cryptocurrencies, offering a seamless checkout experience for the crypto community.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_6} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Real-Time Order Tracking
                          </div>
                          <div className="o-f-content">
                            Facilitate purchases with various cryptocurrencies, offering a seamless checkout experience for the crypto community.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={6}>
                    <Card className="o-f-card">
                      <Card.Body>
                        <div>
                          <img src={o_f_img_6} />
                        </div>
                        <div>
                          <div className="o-f-heading">
                            Dedicated Customer Support
                          </div>
                          <div className="o-f-content">
                            Receive comprehensive support throughout your merchandise creation and ordering process, from start to finish.
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
        <section className="fourth-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  Roadmap
                </div>

                <div className="roadmap-web">

                  <ul className="timeline">
                    <li><time>Phase 1: Foundation and Integration
                    </time>
                      <div className="timeline-text">
                        <div>
                          - Establish Multi Chain Integration
                        </div>
                        <div>
                          - Develop NFT to Merch and Merch to NFT Integration
                        </div>
                        <div>
                          - Launch Telegram and Discord Bots
                        </div>
                        <div>
                          - Initial Fundraising through Merchandise
                        </div>
                      </div>
                    </li>
                    <li><time>Phase 2: Innovation and Expansion</time>
                      <div className="timeline-text">
                        <div>
                          - AI Feature Integration
                        </div>
                        <div>
                          - Community Design Contests
                        </div>
                        <div>
                          - Limited Edition Drops
                        </div>
                        <div>
                          - Educational Webinars
                        </div>
                        <div>
                          - Charity Partnerships
                        </div>
                      </div>
                    </li>
                    <li><time>Phase 3: Growth and Diversification</time>
                      <div className="timeline-text">
                        <div>
                          - YouTube Merchandise Integration
                        </div>
                        <div>
                          - Instagram Merchandise Integration
                        </div>
                        <div>
                          - Print AI Art
                        </div>
                        <div>
                          - Gamification Features
                        </div>
                        <div>
                          - User Feedback Mechanisms
                        </div>
                      </div>
                    </li>
                    <li><time >Phase 4: Scaling and Global Expansion</time>
                      <div className="timeline-text">
                        <div>
                          - Scalability Enhancements
                        </div>
                        <div>
                          - Global Expansion
                        </div>
                        <div>
                          - Customization Options
                        </div>
                        <div>
                          - Sustainable Merchandise
                        </div>
                        <div>
                          - NFT Marketplace Integration
                        </div>
                      </div></li>
                    <li><time >Onward…</time>
                      <div className="timeline-text">
                        <div>
                          - Market-X Integration
                        </div>
                        <div>
                          - Launch Raffle Merchandise
                        </div>
                        <div>
                          - Introduce Gift Card System
                        </div>
                        <div>
                          - Referral Program for Individuals
                        </div>
                        <div>
                          - Interactive Merchandise
                        </div>
                        <div>
                          - Integration with Virtual Worlds
                        </div>
                        <div>
                          - Localized Merchandise
                        </div>
                        <div>
                          - Strategic Partnerships and Collaborations
                        </div>
                      </div>
                    </li>

                  </ul>

                </div>
                <div className="roadmap-mobile">
                  <div className="timeline-mobile">
                    <div className="outer">
                      <div className="timeline-mobile-card">
                        <div className="info">
                          <h3 className="title">Phase 1: Foundation and Integration</h3>
                          <p>
                            <ul>
                              <li>- Establish Multi Chain Integration</li>
                              <li>- Develop NFT to Merch and Merch to NFT Integration</li>
                              <li>- Launch Telegram and Discord Bots</li>
                              <li>- Initial Fundraising through Merchandise</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="timeline-mobile-card">
                        <div className="info">
                          <h3 className="title">Phase 2: Innovation and Expansion</h3>
                          <p>
                            <ul>
                              <li>- AI Feature Integration</li>
                              <li>- Community Design Contests</li>
                              <li>- Limited Edition Drops</li>
                              <li>- Educational Webinars</li>
                              <li>- Charity Partnerships</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="timeline-mobile-card">
                        <div className="info">
                          <h3 className="title">Phase 3: Growth and Diversification</h3>
                          <p>
                            <ul>
                              <li>- YouTube Merchandise Integration</li>
                              <li>- Instagram Merchandise Integration</li>
                              <li>- Print AI Art</li>
                              <li>- Gamification Features</li>
                              <li>- User Feedback Mechanisms</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="timeline-mobile-card">
                        <div className="info">
                          <h3 className="title">Phase 4: Scaling and Global Expansion</h3>
                          <p>
                            <ul>
                              <li>- Scalability Enhancements</li>
                              <li>- Global Expansion</li>
                              <li>- Customization Options</li>
                              <li>- Sustainable Merchandise</li>
                              <li>- NFT Marketplace Integration</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="timeline-mobile-card">
                        <div className="info">
                          <h3 className="title">Onward…</h3>
                          <p>
                            <ul>
                              <li>- Market-X Integration</li>
                              <li>- Launch Raffle Merchandise</li>
                              <li>- Introduce Gift Card System</li>
                              <li>- Referral Program for Individuals</li>
                              <li>- Interactive Merchandise</li>
                              <li>- Integration with Virtual Worlds</li>
                              <li>- Localized Merchandise</li>
                              <li>- Strategic Partnerships and Collaborations</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="seven-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="s-s-heading">
                  FREQUENTLY ASKED QUESTIONS
                </div>
                <Row>
                  <Col sm={8} className="mx-auto">
                    <Accordion defaultActiveKey={['0']} alwaysOpen className="faq-accordian">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header> How do I get started with Monkey Commerce?</Accordion.Header>
                        <Accordion.Body>
                          Getting started is easy! Simply connect your digital wallet to our platform, select the NFTs you’d like to turn into merchandise, choose from our extensive range of products, customize to your liking, and place your order. We'll handle the rest, from production to shipping.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>How can I get the customer support?</Accordion.Header>
                        <Accordion.Body>
                          We support a wide variety of the most popular digital wallets, including MetaMask, WalletConnect, Coinbase Wallet, and more. If you're unsure whether your wallet is supported, please check our Wallet Integration Guide or contact our support team.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>Can I use any NFT in my wallet for merchandise?</Accordion.Header>
                        <Accordion.Body>
                          Yes, as long as you hold the rights to use the NFT for merchandise purposes. We provide an automated licensing check to ensure that the NFTs used adhere to the original creator's terms and conditions.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="3">
                        <Accordion.Header>What types of merchandise can I create?</Accordion.Header>
                        <Accordion.Body>
                          Monkey Commerce offers over 150+ product options ranging from apparel, accessories, home decor to tech gadgets. Explore our Product Catalog to find the perfect match for your NFT art.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="4">
                        <Accordion.Header>How does the automated licensing work?</Accordion.Header>
                        <Accordion.Body>
                          Our platform uses smart contracts to verify the rights and permissions associated with each NFT. This ensures that only NFTs cleared for commercial use can be transformed into merchandise, protecting artists' rights and ensuring legal compliance.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="5">
                        <Accordion.Header> How long does it take to receive my order?</Accordion.Header>
                        <Accordion.Body>
                          Production times vary by product, but most orders are dispatched from our manufacturing centers within 3-4 business days. Shipping times depend on your location and the shipping method selected at checkout. Expect global deliveries to take anywhere from 5-15 business days.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="6">
                        <Accordion.Header>Do you offer international shipping?</Accordion.Header>
                        <Accordion.Body>
                          Absolutely! We ship worldwide, ensuring fans and communities across the globe can enjoy your custom NFT merchandise. Shipping rates and times may vary based on the destination.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="7">
                        <Accordion.Header> What is your return policy?</Accordion.Header>
                        <Accordion.Body>
                          We stand behind the quality of our merchandise and offer a satisfaction guarantee. If there's an issue with your order, please contact us within 14 days of receipt to discuss returns or exchanges. Note that customized products are subject to our Custom Merchandise Return Policy.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="8">
                        <Accordion.Header>Can I order merchandise in bulk for events or promotions?</Accordion.Header>
                        <Accordion.Body>
                          Yes, we offer bulk ordering options perfect for events, promotions, or community giveaways. Contact our sales team for more information on bulk discounts and custom orders.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="9">
                        <Accordion.Header>How can I contact Monkey Commerce for more support?</Accordion.Header>
                        <Accordion.Body>
                          Our dedicated support team is here to help! Reach out to us via email at support@monkeycommerce.io, or visit our Contact Us page for more options. We're committed to ensuring a smooth experience for our users.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </section>
        <section className="eight-section section-padding position-relative">
          <div className="container-fluid">
            <Row>
              <Col sm={12} >
                <div className="c-a-container">
                  <Row>
                    <Col sm="8">

                      <img className="c-a-top-image" src={c_a_img_1} />

                      <div className="c-a-heading">
                        Design, Sell and Earn!
                      </div>
                      <div className="c-a-sub-heading">
                        Transform your designs into tangible products and start selling on Monkey Commerce
                      </div>
                    </Col>
                    <Col sm="4" className="mx-auto text-center">
                      <div>
                        <button
                          className="btn btn-md rounded font-sm hover-up create-product-btn"
                        // onClick={}
                        >
                          Get Started <Icon.ArrowRight size={30} className="btn-icons" />
                        </button>
                      </div>

                      <img className="c-a-bottom-image" src={c_a_img_2} />

                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </main>
    </>
  );
};
