import { Row, Col, Card, Accordion } from "react-bootstrap";
import "./footer.css";
import footer_logo from "../images/Monkey Commerce 1.png";
import discord_img from "../images/ic_baseline-discord.png";
import twitter_img from "../images/mdi_twitter.png";
import telegram_img from "../images/mingcute_telegram-fill.png";
import instagram_img from "../images/logos_instagram-icon.png";
import facebook_img from "../images/uil_facebook-f.png";

export const Footer = ({ }) => {
    return (
        <>
            <footer className="main">



                <div className="container-fluid footer-container pb-30 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                    <div className="row align-items-flex-start">

                        <div className="col-xl-3 col-lg-6 col-md-6 footer-first-column">
                            <div className="mb-4 footer-logo">
                                <img src={footer_logo} />
                            </div>
                            <div className="mb-4 footer-text">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                            </div>
                            <div className="mb-4 footer-social-icons">
                                <img src={facebook_img} />
                                <img src={instagram_img} />
                                <img src={twitter_img} />
                                <img src={discord_img} />
                                <img src={telegram_img} />
                            </div>

                        </div>
                        <div className="col-xl-3 col-lg-6 text-center d-xl-block">

                            <ul className="footer-menu-1">
                                <li className="footer-menu-1-heading">
                                    Menu
                                </li>
                                <li>
                                    Stores
                                </li>
                                <li>
                                    Products
                                </li>
                                <li>
                                    About Us
                                </li>
                                <li>
                                    FAQ
                                </li>
                            </ul>
                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 text-end d-md-block">

                            <ul className="footer-menu-1">
                                <li className="footer-menu-1-heading">
                                    Information
                                </li>
                                <li>
                                    Terms & Conditions
                                </li>
                                <li>
                                    Terms of Service
                                </li>
                                <li>
                                    Privacy Policy
                                </li>

                            </ul>

                        </div>
                        <div className="col-xl-3 col-lg-6 col-md-6 text-end d-md-block">

                            <ul className="footer-menu-1">
                                <li className="footer-menu-1-heading">
                                    Contact
                                </li>
                                <li>
                                    contact@monkeycommerce.io
                                </li>
                                <li>
                                    marketing@monkeycommerce.io
                                </li>


                            </ul>

                        </div>
                        <div className="col-12  copy-right-symbol">
                        © ️2023 monkeycommerce.com
                        </div>
                    </div>
                </div>
            </footer>
            {/* <div id="preloader-active">
        <div className="preloader d-flex align-items-center justify-content-center">
            <div className="preloader-inner position-relative">
                <div className="text-center">
                    <img src="/assets-v1/imgs/theme/loading.gif" alt="" />
                </div>
            </div>
        </div>
    </div> */}
        </>
    );
};
