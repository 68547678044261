import React, {
    useEffect,
    useState,
    CSSProperties
} from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate, Link, useParams } from "react-router-dom";
import { Modal, Row, Col, Card, Button, Form, Stack } from "react-bootstrap";
import Swal from 'sweetalert2'
import { ToastContainer, toast } from "react-toastify";
import * as Icon from 'react-bootstrap-icons';
import { useCart } from '../components/CartContext'
import reach_us_img from "../images/contact-us.png";
import product1 from "../images/products/product-image.png";
import product2 from "../images/products/product-image1.png";
import product3 from "../images/products/product-image2.png";
import quantityminus from "../images/icons/quantityminus.png";
import quantityplus from "../images/icons/quantityplus.png";
import quantityminusblue from "../images/icons/quantityminusblue.png";
import quantityplusblue from "../images/icons/quantityplusblue.png";
import solanalogo from "../images/icons/solanalogo.png";
import usdclogo from "../images/icons/USD_Coin_icon.webp";
import timer from "../images/icons/timer.png";
import black from "../images/icons/black.png";
import white from "../images/icons/white.png";
import pink from "../images/icons/pink.png";
import brown from "../images/icons/brown.png";
import blue from "../images/icons/blue.png";
import sizes from "../images/icons/sizes.png";
import sizem from "../images/icons/sizem.png";
import sizel from "../images/icons/sizel.png";
import sizexl from "../images/icons/sizexl.png";
import size2xl from "../images/icons/size2xl.png";
import size3xl from "../images/icons/size3xl.png";
import freeShipping from "../images/icons/free-shipping.png";
import easyReturn from "../images/icons/easy-return.png";
import securePayment from "../images/icons/secure-payment.png";
import support from "../images/icons/support.png";
import cartArrow from "../images/icons/cart-arrow.png";


import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
    WalletDisconnectButton,
    WalletMultiButton,
    useWalletModal
} from "@solana/wallet-adapter-react-ui";
import "./store-product-detail.css";

const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "#fff",
};


export const StoreProductDetail = ({ isApp, isAppEmailAddress, publicKey }) => {
    const navigate = useNavigate();
    const { setVisible } = useWalletModal();
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
    const [storeName, setStoreName] = useState('Trends');
    const [productId, SetProductId] = useState('');
    const [productQuantity, setProductQuantity] = useState(1);
    const [productDetail, setProductDetail] = useState({});
    const [color, setColor] = useState('White');
    const [size, setSize] = useState('32');
    const [solPrice, setSolPrice] = useState();
    const [paymentCurrency, setPaymentCurrency] = useState('USDC');
    const [selectedImage, setSelectedImage] = useState("");
    const { email } = useParams();
    let [loading, setLoading] = useState(true);

    const { addToCart } = useCart();

    const increaseProductQuantity = async (e) => {
        setProductQuantity(productQuantity + 1);
    }
    const decreaseProductQuantity = async (e) => {
        if (productQuantity > 1) {
            setProductQuantity(productQuantity - 1);
        }
    }
    const handleSize = async (e) => {
        setSize(e.target.id)
    }
    const handleColor = async (e) => {
        setColor(e.target.id)
    }
    const handlePayment = async (e) => {
        setPaymentCurrency(e.target.id);
        if (e.target.id == "SOL") {
            getSolPrice();
        }
        else{

        }
    }
    const getSolPrice = async () => {

        try {
            const response = await fetch("https://api.binance.com/api/v1/ticker/price?symbol=SOLUSDT",
                {
                    method: "GET",
                    mode: "cors",
                }
            );
            if (response.status === 200) {
                const data = await response.json();
                console.log(data);
                setSolPrice(data['price']);

            } else {
                console.log(response.status);
            }
        } catch (e) {
            console.log(e);
        }
    }
    const addToCartFun = async (e) => {
        var cartDetails = {}
        if (!publicKey)
        {
            setVisible(true)
            return;
        }
        if (publicKey) {
            cartDetails['walletId'] = publicKey.toString();
        }
        if (email) {
            cartDetails['emailId'] = email
        }
        let price;
        if (paymentCurrency == 'SOL') {
            price = (((Number(productDetail["sellingPrice"])) / Number(solPrice)) * productQuantity).toFixed(5)
        } else {
            price = (Number(productDetail["sellingPrice"]) * productQuantity).toFixed(3)
        }
        cartDetails['product'] = productId;
        cartDetails['quantity'] = productQuantity;
        cartDetails['selectedColor'] = color;
        cartDetails['selectedSize'] = size;
        cartDetails['selectedPayment'] = paymentCurrency;
        cartDetails['price'] = price;
        const response = await fetch(process.env.REACT_APP_API_URL + "createShoppingCart", {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(cartDetails),
        });
        if (response.status === 200) {
            let product_resp = await response.json();
            console.log(product_resp);
            // var r = window.confirm("Cart Updated Successfully!");
            // if (r == true) {
            //     navigate('/shopping-cart'); 
            // }
            addToCart(productQuantity)
            toast("Added to Cart", {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "light",
            });
            if (email) {
                navigate('/shopping-cart/' + email);
            } else {
                navigate('/shopping-cart');
            }

            // Swal.fire({
            //     title: 'Added to Cart',
            //     text: 'Go to Cart',
            //     confirmButtonText: 'OK'
            // }).then(() => {
            //     if (email) {
            //         navigate('/shopping-cart/' + email);
            //     } else {
            //         navigate('/shopping-cart');
            //     }

            // })
        } else {
            console.log("error");
        }
    }
    const getProductByID = async () => {
        console.log(productId);
        if (productId) {
            try {
                const response = await fetch(process.env.REACT_APP_API_URL + "GetDesignProductById",
                    {
                        method: "POST",
                        mode: "cors",
                        headers: new Headers({ "content-type": "application/json" }),
                        body: JSON.stringify({ id: productId }),
                    }
                );
                if (response.status === 200) {
                    const data = await response.json();
                    console.log(data);
                    setProductDetail(data['designProduct_details']);
                    setSelectedImage(data["designProduct_details"]["imagesFront"]);
                    setLoading(false)
                } else {
                    console.log(response.status);
                    setLoading(false)
                }
            } catch (e) {
                console.log(e);
                setLoading(false)
            }
        }
    };
    useEffect(() => {
        var url_path = window.location.pathname;
        console.log(url_path);
        var product_id = url_path.split("/")[2];
        console.log(product_id);
        SetProductId(product_id);

    }, [navigate]);
    useEffect(() => {
        async function data() {
            await getProductByID();
        }
        data();
    }, [navigate, productId]);
    return (
        <>
            <ToastContainer />
            <ClipLoader
                color={color}
                loading={loading}
                cssOverride={override}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
                className="loader"
            />
            {!loading && (
                <>
                    {true ? (
                        <>
                            <div className="mainPage">
                                <div className="breadcrumbs">
                                    <ul className="breadcrumbs-list">
                                        {email && (
                                            <li><Link to={"/" + email}>Home</Link></li>
                                        )}
                                        {!email && (
                                            <li><Link to="/">Home</Link></li>
                                        )}
                                        <li className="breadcrumbs-item-space">{">"}</li>
                                        {email && (
                                            <li><Link to={"/store-detail/" + productDetail['storeID'] + "/" + email}>{productDetail['storeName']}</Link></li>
                                        )}
                                        {!email && (
                                            <li><Link to={"/store-detail/" + productDetail['storeID']}>{productDetail['storeName']}</Link></li>
                                        )}
                                        <li className="breadcrumbs-item-space">{">"}</li>
                                        <li className="breadcrumbs-product-name">{productDetail['productName']}</li>
                                    </ul>
                                </div>
                                <div className="product-details">
                                    <Row>
                                        <Col sm={7}>
                                            <div className="prodcut-images">
                                                <div className="thumbnail-images">
                                                    <img
                                                        src={productDetail["imagesFront"]}
                                                        alt="Thumbnail Front"
                                                        onClick={() =>
                                                            setSelectedImage(productDetail["imagesFront"])
                                                        }
                                                        className={
                                                            selectedImage === productDetail["imagesFront"]
                                                                ? "selected"
                                                                : ""
                                                        }
                                                    />
                                                    <img
                                                        src={productDetail["imagesBack"]}
                                                        alt="Thumbnail Back"
                                                        onClick={() =>
                                                            setSelectedImage(productDetail["imagesBack"])
                                                        }
                                                        className={
                                                            selectedImage === productDetail["imagesBack"]
                                                                ? "selected"
                                                                : ""
                                                        }
                                                    />
                                                </div>
                                                <div className="large-image">
                                                    <img src={selectedImage} alt="Large Product" />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm={5}>
                                            <div className="product-content">
                                                {/* <div className="product-editions">
                                  <span><img src={timer} />Limited editions only: <strong>30 out of 45 Left</strong></span>
                              </div> */}
                                                <div className="product-name">
                                                    <h2>{productDetail['productName']}</h2>
                                                </div>
                                                <div className="productCost">
                                                    {paymentCurrency == 'SOL' && <><img src={solanalogo} alt="" /> <h1>{(((Number(productDetail["sellingPrice"])) / Number(solPrice)) * productQuantity).toFixed(5)} SOL</h1></>}
                                                    {paymentCurrency == 'USDC' && <> <img src={usdclogo} alt="" /> <h1>{(Number(productDetail["sellingPrice"]) * productQuantity).toFixed(3)} USDC</h1></>}
                                                    {(paymentCurrency != 'USDC' && paymentCurrency != 'SOL') && <> <h1>{(Number(productDetail["tokenPrice"]) * productQuantity).toFixed(3)} {productDetail["tokenName"]}</h1></>}
                                                </div>
                                                <div className="product-payment">
                                                    <div className="payment-radio">
                                                        <span className="product-label">Payment accepted in:</span>
                                                        <Form.Check
                                                            inline
                                                            label="USDC"
                                                            name="group1"
                                                            className="fw-bold"
                                                            type="radio"
                                                            id="USDC"
                                                            checked={paymentCurrency == 'USDC' ? true : false}
                                                            onClick={handlePayment}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="SOL"
                                                            name="group1"
                                                            className="fw-bold"
                                                            type="radio"
                                                            id="SOL"
                                                            checked={paymentCurrency == 'SOL' ? true : false}
                                                            onClick={handlePayment}
                                                        />
                                                        {productDetail['tokenPrice'] != '' && productDetail['tokenPrice'] != null &&
                                                            <Form.Check
                                                                inline
                                                                label={productDetail['tokenName']}
                                                                name="group1"
                                                                className="fw-bold"
                                                                type="radio"
                                                                id={productDetail['tokenName']}
                                                                checked={paymentCurrency == productDetail['tokenName'] ? true : false}
                                                                onClick={handlePayment}
                                                            />
                                                        }
                                                    </div>

                                                </div>
                                                {productDetail["selectedColor"] !== undefined && (
                                                    <div className="product-color">
                                                        <p className="product-label">Colour: {color}</p>
                                                        <div>
                                                            {JSON.parse(productDetail["selectedColor"]).map(
                                                                (c, index) => (
                                                                    <Button
                                                                        className={
                                                                            color == c ? "colorBtnActive" : "colorBtn"
                                                                        }
                                                                        style={{
                                                                            backgroundColor: c,
                                                                            // Add more style properties as needed
                                                                        }}
                                                                        id={c}
                                                                        onClick={handleColor}
                                                                    ></Button>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {productDetail["selectedSize"] !== undefined && (
                                                    <div className="product-size">
                                                        <p className="product-label">Size:</p>
                                                        <div>
                                                            {JSON.parse(productDetail["selectedSize"]).map(
                                                                (b, index) => (
                                                                    <Button
                                                                        className={
                                                                            size == b ? "sizeBtnActive" : "sizeBtn"
                                                                        }
                                                                        id={b}
                                                                        onClick={handleSize}
                                                                    >
                                                                        {b}
                                                                    </Button>
                                                                )
                                                            )}


                                                        </div>
                                                    </div>
                                                )}
                                                <div className="product-quantity">
                                                    <p className="product-label">Quantity:</p>
                                                    <div>
                                                        <Icon.DashSquare size={30} className="page-icons" onClick={decreaseProductQuantity} />

                                                        <h1>{productQuantity}</h1>
                                                        <Icon.PlusSquare size={30} className="page-icons" onClick={increaseProductQuantity} />

                                                    </div>
                                                </div>


                                                <div className="desktop-cart-button">
                                                    <Button className="shadow-btn shadow-btn-width" variant="primary" onClick={addToCartFun}>ADD TO CART <Icon.ArrowRight size={36} className="btn-icons" /></Button>
                                                </div>
                                                <div className="mobile-cart-button">
                                                    <Button className="shadow-btn shadow-btn-width" variant="primary" onClick={addToCartFun}>ADD TO CART <Icon.ArrowRight size={30} className="btn-icons" /></Button>
                                                </div>
                                                <div className="product-desc">
                                                    <h3 className="product-label">Product Description:</h3>
                                                    <p>{productDetail['desc']}</p>
                                                </div>
                                                <div className="product-condition">
                                                    <h3 className="product-label">Condition:</h3>
                                                    <p>Require your NFT in the wallet to purchase the item.
                                                        Item available just for the whitelisted wallets.</p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className="product-general-detail">
                                <Row>
                                    <Col sm={6} className="mobile-col-width-6">
                                        <div className="product-general-detail-col">
                                            <div className="desktop-product-icon">
                                                <img src={freeShipping} />
                                            </div>
                                            <div>
                                                <Icon.Truck className="page-icons-2" />

                                                <h2>Free shipping</h2>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mobile-col-width-6">
                                        <div className="product-general-detail-col">
                                            <div className="desktop-product-icon">
                                                <img src={easyReturn} />
                                            </div>
                                            <div>
                                                <Icon.ArrowReturnLeft className="page-icons-2" />

                                                <h2>Easy Return</h2>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="product-general-detail-row">
                                    <Col sm={6} className="mobile-col-width-6">
                                        <div className="product-general-detail-col">
                                            <div className="desktop-product-icon">
                                                <img src={securePayment} />
                                            </div>
                                            <div>
                                                <Icon.ShieldLock className="page-icons-2" />
                                                <h2>100% Secure Payment</h2>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={6} className="mobile-col-width-6">
                                        <div className="product-general-detail-col">
                                            <div className="desktop-product-icon">
                                                <img src={support} />
                                            </div>
                                            <div>
                                                <Icon.ChatDots className="page-icons-2" />
                                                <h2>Support</h2>
                                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa mi. </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                id="kt_content_container"
                                className="d-flex flex-column-fluid align-items-start "
                            >
                                <div
                                    className={`${isMobile
                                        ? "px-5"
                                        : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                                        }`}
                                >
                                    <div
                                        className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                                            }`}
                                    >
                                        <div
                                            className={`${isMobile
                                                ? ""
                                                : "d-flex flex-center flex-column flex-column-fluid"
                                                }`}
                                        >
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="create-raffle-wallet-connet">
                                                        <img
                                                            className="reach-us-img"
                                                            alt=""
                                                            src={reach_us_img}
                                                        />
                                                        <div className="reach-us-to-whitelist">
                                                            Please connect your wallet.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <div className="wallet-button">
                                                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}