import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
} from "react";

import { useNavigate } from "react-router-dom";
// import { useProSidebar } from "react-pro-sidebar";
import { CompSideBar } from "../components/sidebar";
import { Line } from "react-chartjs-2";
import { Modal, Row, Col, Card, Button, Form } from "react-bootstrap";
import reach_us_img from "../images/contact-us.png";
import dashboard1 from "../images/icons/dashboard1.png";
import dashboard2 from "../images/icons/dashboard2.png";
import dashboard3 from "../images/icons/dashboard3.png";
import dashboard4 from "../images/icons/dashboard4.png";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import "./dashboard.css";
export const Dashboard = ({ publicKey, userData }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const [walletID, setWalletID] = useState('');
  const [dashboardData, setDashboardData] = useState({});

  const getMyDashboard = async () => {
    console.log(walletID);
    if (walletID) {
      try {
        console.log(userData)
        const response = await fetch(process.env.REACT_APP_API_URL + "GetMyDashboards",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ "role": userData['role'], 'walletID': userData['walletID'], 'user_id': userData['id'] }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          console.log(data);
          setDashboardData(data)
          // setAllOrders(data['orders']);
          // setAllProducts(data['orders']['products']);

        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    setWalletID(publicKey);
    async function data() {
      await getMyDashboard();
      // await getUserData(walletID);
    }
    data();
  }, [navigate, walletID]);


  const AddNewProduct = () => {
    navigate('/add-products')
  }

  return (
    <>
      {true ? (
        <>
          <div>
            <CompSideBar publicKey={publicKey} />
          </div>
          <div className="mainpage-dashboard">
            <div className="container-fluid">
              <div className="dashboard-title">
                <h1 className="sora-bold">Dashboard</h1>
              </div>
              <div className="dashboard-design">
                <Row>
                  <Col sm={7}>
                    <div className="prize-background-div">
                      <div className="prize-background">
                        <Card className="yellow-card fixed-height">
                          <Card.Body>
                            <div className="design-content">
                              <Row>
                                <Col sm={7}>
                                  <h4 className="sora-bold">
                                    Design and Sell Products
                                  </h4>
                                  <p className="sora-regular">
                                    Lorem ipsum dolor sit amet consectetur
                                    adipiscing elit Ut et massa mi.{" "}
                                  </p>
                                </Col>
                                <Col sm={5}>
                                  <Button
                                    className="shawdow-btn"
                                    variant="primary"
                                    onClick={AddNewProduct}
                                  >
                                    + Create Product
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col sm={5}>
                    <div className="background-border-div">
                      <div className="background-border1">
                        <Card className="dark-card fixed-height">
                          <Card.Body>
                            <div className="design-content text-center">
                              <p className="sora-regular customize">
                                Click here to customise your store settings
                              </p>
                              <Button
                                className="btn-tranparent"
                                variant="primary"
                              >
                                Customise Store
                              </Button>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="order-details-header">
                <h2 className="sora-bold">Orders Received</h2>
              </div>
              <div className="order-details-cards">
                <Row>
                  <Col sm={3}>
                    <div className="background-border-div">
                      <div className="background-border1">
                        <Card className="dark-card">
                          <Card.Body>
                            <div className="orders-content">
                              <div className="orders-icon">
                                <img src={dashboard1} />
                              </div>
                              <div className="orders-details">
                                <h3 className="sora-bold">{Object.keys(dashboardData).length > 0 ? dashboardData['todayOrders'] : 0}</h3>
                                <p className="sora-regular">Today’s Orders</p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="background-border-div">
                      <div className="background-border1">
                        <Card className="dark-card">
                          <Card.Body>
                            <div className="orders-content">
                              <div className="orders-icon">
                                <img src={dashboard2} />
                              </div>
                              <div className="orders-details">
                                <h3 className="sora-bold">
                                  {dashboardData['todayEarnings'] && Object.keys(dashboardData['todayEarnings']).length > 0 ? (
                                    Object.entries(dashboardData['todayEarnings']).map(([currency, earnings]) => (
                                      <span key={currency}>
                                        {currency} : {(earnings as string)}{' '}
                                      </span>
                                    ))
                                  ) : (
                                    <div className="no-earnings">No earnings today</div>
                                  )}


                                </h3>

                                <p className="sora-regular">Today’s Earnings</p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="background-border-div">
                      <div className="background-border1">
                        <Card className="dark-card">
                          <Card.Body>
                            <div className="orders-content">
                              <div className="orders-icon">
                                <img src={dashboard3} />
                              </div>
                              <div className="orders-details">
                                <h3 className="sora-bold">{Object.keys(dashboardData).length > 0 ? dashboardData['liveProducts'] : 0}</h3>
                                <p className="sora-regular">Live Products</p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                  <Col sm={3}>
                    <div className="background-border-div">
                      <div className="background-border1">
                        <Card className="dark-card">
                          <Card.Body>
                            <div className="orders-content">
                              <div className="orders-icon">
                                <img src={dashboard4} />
                              </div>
                              <div className="orders-details">
                                <h3 className="sora-bold">
                                  {dashboardData['totalEarnings'] && Object.keys(dashboardData['totalEarnings']).length > 0 ? (
                                    Object.entries(dashboardData['totalEarnings']).map(
                                      ([currency, earnings]) => (
                                        <span key={currency}>
                                          {currency} : {(earnings as string)}{' '}
                                        </span>
                                      )
                                    )
                                  ) : (
                                    <div className="no-earnings">No earnings</div>
                                  )}


                                </h3>
                                <p className="sora-regular">Total Earnings</p>
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              {/* <div className="chart-div">
                <Row>
                  <Col sm={7}>
                    <Card className="light-card">
                      <Card.Body>
                        <Row className="chart-data">
                          <Col sm={7}>
                            <div className="chart-heading">
                              <p className="sora-regular">Earnings</p>
                              <h3 className="sora-bold">
                                20,000 SOL, 10th Sep ‘23
                              </h3>
                            </div>
                          </Col>
                          <Col sm={5}>
                            <div className="chart-filter">
                              <Form.Control
                                as="select"
                                className="chart-select"
                                size="sm"
                                aria-label="Default select example"
                              >
                                <option value="6">Last 6 Month</option>
                                <option value="2">Last 1 Year</option>
                              </Form.Control>
                            </div>
                          </Col>
                        </Row>
                       
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col sm={5}>
                    <Card className="light-card">
                      <Card.Body>
                        <Row className="chart-data">
                          <Col sm={7}>
                            <div className="chart-heading">
                              <p className="sora-regular">Order Count</p>
                              <h3 className="sora-bold">
                                32 Orders, 19th Nov ‘23{" "}
                              </h3>
                            </div>
                          </Col>
                          <Col sm={5}>
                            <div className="chart-filter">
                              <Form.Control
                                as="select"
                                className="chart-select"
                                size="sm"
                                aria-label="Default select example"
                              >
                                <option value="6">Last 6 Month</option>
                                <option value="2">Last 1 Year</option>
                              </Form.Control>
                            </div>
                          </Col>
                        </Row>
                       
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div> */}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${isMobile
                ? "px-5"
                : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
            >
              <div
                className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                  }`}
              >
                <div
                  className={`${isMobile
                    ? ""
                    : "d-flex flex-center flex-column flex-column-fluid"
                    }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
