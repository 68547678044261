import React, { FC, useEffect, useState, memo, useRef } from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import { useWallet } from "@solana/wallet-adapter-react";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { Home } from "./pages/home";
import { CreateProductList } from "./pages/create-product-list";
import { SignupForm } from "./pages/create-store";
// import { TShirtDesigner } from "./pages/mock-up";
import { TShirtDesigner_1 } from "./pages/mock-up-1";
import { SellerProducts } from "./pages/seller-products";
import { Dashboard } from "./pages/dashboard";
import { PageNotFound } from "./pages/page-not-found";
import { StoreProductDetail } from "./pages/store-product-detail";
import { StoreDetail } from "./pages/store-detail";
import { CheckOut } from "./pages/check-out";
import { ShoppingCart } from "./pages/shopping-cart";
import { MyProducts } from "./pages/my-product";
import { MyOrders } from "./pages/my-orders";
import { EditProducts } from "./pages/edit-product";
import { OrderDetails } from "./pages/order-details";
import { MyCart } from "./pages/my-cart";
import { MyAddress } from "./pages/my-address";
import { AddProducts } from "./pages/add-products";
import { Stores } from "./pages/stores";
import { Products } from "./pages/products";
// import { Navigation } from "./Navigation";

const HomeMemo = memo(Home);

const AppRoutes: FC = () => {
  const { publicKey } = useWallet();
  const [userData, setUserData] = useState({});
  const [userRole, SetUserRole] = useState("end-user");
  const [isApp, SetIsApp] = useState(false);
  const [isAppEmailAddress, SetIsAppEmailAddress] = useState('');
  const { email } = useParams();

  // Check the current route and update hideHeader accordingly
  const location = useLocation();
  const [hideFooter, setHideFooter] = useState(false);
  useEffect(() => {
    
    const currentPath = window.location.pathname;
    const routesToHideFooter = [
      'my-orders',
      'order-detail',
      'my-products',
      'edit-product',
      'my-cart',
      'my-address',
      'add-products',
      'dashboard'
    ];

    const shouldHideFooter = routesToHideFooter.some(route => currentPath.includes(route));
    setHideFooter(shouldHideFooter);
  }, [location]);


  // useEffect(() => {
  //   console.log("AppRoutes Component Mounted");
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const emailAddress = urlParams.get('email');
  //   // Access the email parameter from the URL

  //   if (emailAddress) {
  //     SetIsApp(true)
  //     SetIsAppEmailAddress(emailAddress)
  //     getUser()
  //   } else {
  //     SetIsApp(false)
  //     SetIsAppEmailAddress('')
  //   }
  //   return () => console.log("AppRoutes Component Unmounted");
  // }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      getUser();
    };
    if (publicKey) {
      fetchUserData();
    }

  }, [publicKey])




  const getUser = async () => {
    console.log(isAppEmailAddress)
    if (publicKey) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUser", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ walletID: publicKey.toString() }),
        });
        if (response.status === 200) {
          const data = await response.json();
          console.log(data)
          setUserData(data['userData']);
          SetUserRole(data['userData']["role"]);
          console.log(data['userData']['role']);
        } else {
          console.log(response.status);
        }
      } catch (e: any) {
        console.log(e);
      }
    } else if (isAppEmailAddress) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUser", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ emailID: isAppEmailAddress }),
        });
        if (response.status === 200) {
          const data = await response.json();
          console.log(data)
          setUserData(data['userData']);
          SetUserRole(data['userData']["role"]);
          console.log(data['userData']['role']);
        } else {
          console.log(response.status);
        }
      } catch (e: any) {
        console.log(e);
      }
    }

  };

  // Define routes based on user role
  const routesForUserRole = {
    "end_user": (
      <>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/stores/:email?" element={<Stores />} />
        <Route path="/store-create" element={<SignupForm publicKey={publicKey} userData={userData} />} />
        <Route path="/products/:email?" element={<Products />} />
        <Route path="/store-detail/:id/:email?" element={<StoreDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route
          path="/store-product-detail/:id/:email?"
          element={<StoreProductDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} publicKey={publicKey} />}
        />
        <Route path="/cart-check-out/:email?" element={<CheckOut publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route path="/shopping-cart/:email?" element={<ShoppingCart publicKey={publicKey} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route path="/dashboard/:email?" element={<Dashboard publicKey={publicKey} userData={userData} />} />
        <Route path="/my-orders/:email?" element={<MyOrders publicKey={publicKey} userData={userData} />} />
        <Route path="/order-detail/:id/:email?" element={<OrderDetails publicKey={publicKey} userData={userData} />} />
        <Route path="/my-cart/:email?" element={<MyCart publicKey={publicKey} />} />
        <Route path="/my-address/:email?" element={<MyAddress publicKey={publicKey} />} />

      </>
    ),
    //Define routes for other roles here
    "creator": (
      <>
        <Route path="/store-create" element={<SignupForm publicKey={publicKey} userData={userData} />} />
        <Route path="/seller-products" element={<SellerProducts />} />
        <Route path="/mockup/:id" element={<TShirtDesigner_1 />} />
        <Route path="/dashboard" element={<Dashboard publicKey={publicKey} userData={userData} />} />

        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/stores/:email?" element={<Stores />} />
        <Route path="/products/:email?" element={<Products />} />
        <Route path="/store-detail/:id/:email?" element={<StoreDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route
          path="/store-product-detail/:id/:email?"
          element={<StoreProductDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} publicKey={publicKey} />}
        />
        <Route path="/cart-check-out/:email?" element={<CheckOut publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route path="/shopping-cart/:email?" element={<ShoppingCart publicKey={publicKey} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />

        <Route path="/my-products/:email?" element={<MyProducts publicKey={publicKey} userData={userData} />} />
        <Route path="/my-orders/:email?" element={<MyOrders publicKey={publicKey} userData={userData} />} />
        <Route path="/order-detail/:id/:email?" element={<OrderDetails publicKey={publicKey} userData={userData} />} />
        <Route path="/edit-product/:id" element={<EditProducts publicKey={publicKey} />} />
        <Route path="/my-cart/:email?" element={<MyCart publicKey={publicKey} />} />
        <Route path="/my-address/:email?" element={<MyAddress publicKey={publicKey} />} />
        <Route path="/add-products/:email?" element={<AddProducts publicKey={publicKey} userData={userData} />} />

      </>
    ),
    "super-admin": (
      <>
        <Route path="/product-create" element={<CreateProductList />} />

        <Route path="/store-create" element={<SignupForm publicKey={publicKey} userData={userData} />} />
        <Route path="/seller-products" element={<SellerProducts />} />
        <Route path="/mockup/:id" element={<TShirtDesigner_1 />} />
        <Route path="/dashboard" element={<Dashboard publicKey={publicKey} userData={userData} />} />

        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/stores/:email?" element={<Stores />} />
        <Route path="/products/:email?" element={<Products />} />
        <Route path="/store-detail/:id/:email?" element={<StoreDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route
          path="/store-product-detail/:id/:email?"
          element={<StoreProductDetail isApp={isApp} isAppEmailAddress={isAppEmailAddress} publicKey={publicKey} />}
        />
        <Route path="/cart-check-out/:email?" element={<CheckOut publicKey={publicKey} userData={userData} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />
        <Route path="/shopping-cart/:email?" element={<ShoppingCart publicKey={publicKey} isApp={isApp} isAppEmailAddress={isAppEmailAddress} />} />

        <Route path="/my-products/:email?" element={<MyProducts publicKey={publicKey} userData={userData} />} />
        <Route path="/my-orders/:email?" element={<MyOrders publicKey={publicKey} userData={userData} />} />
        <Route path="/edit-product/:id" element={<EditProducts publicKey={publicKey} />} />
        <Route path="/my-cart/:email?" element={<MyCart publicKey={publicKey} />} />
        <Route path="/my-address/:email?" element={<MyAddress publicKey={publicKey} />} />
        <Route path="/order-detail/:id/:email?" element={<OrderDetails publicKey={publicKey} userData={userData} />} />
        <Route path="/add-products/:email?" element={<AddProducts publicKey={publicKey} userData={userData} />} />
      </>
    ),
  };


  return (
    <>
      <div className="">
        <div className="">
          <div className="">
            <Header  userData={userData} />

            <Routes>
              {routesForUserRole[userRole] || (
                <Route path="*" element={<PageNotFound />} />
              )}
              <Route path="/:email?" element={<HomeMemo />} />
            </Routes>
            {process.env.REACT_APP_FOR != 'sparqy' && !hideFooter &&
              <Footer />
            }
          </div>
        </div>
      </div>
    </>
  );
};

export { AppRoutes };
