import axios from 'axios';

const getAccessToken = async (publicKey) => {
  const url =  process.env.REACT_APP_API_URL+"api/access-token";
  const data = {
    walletID: publicKey,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET
  };
  const headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };

  try {
    const response = await axios.post(url, new URLSearchParams(data), { headers });
    return response.data.access;
  } catch (error) {
    console.error("Error obtaining access token:", error);
    return null;
  }
};

export default getAccessToken;
