import React, { useEffect, useRef, useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import dashboardMenu from "../images/icons/dashboard-menu.png";
import productMenu from "../images/icons/product-menu.png";
import supportMenu from "../images/icons/support-menu.png";
import guidelineMenu from "../images/icons/guideline-menu.png";
import apiMenu from "../images/icons/api-menu.png";
import payoutMenu from "../images/icons/payouts-menu.png";
import * as Icon from 'react-bootstrap-icons';
import active_menu1 from "../images/icons/sui slot icon.png";
import active_menu2 from "../images/icons/coinflip icon.png";
import active_menu3 from "../images/icons/lottery icon.png";
import active_menu4 from "../images/icons/plinko icon.png";

import { Link, useNavigate } from "react-router-dom";
import "./sidebar.css";

export const CompSideBar = ({ publicKey }) => {
  const navigate = useNavigate();
  const [activePageName, setActivePageName] = useState("dashboard");
  const [collapsed, setCollapsed] = useState(false);
  // localStorage.setItem("active_menu", 'dashboard');

  const handleMenuPage = (pageName) => {
    localStorage.setItem("active_menu", pageName);
    setActivePageName(pageName);
  };

  const [userData, setUserData] = useState({});
  const [userRole, setUserRole] = useState("");

  const getUserData = async (walletID) => {
    console.log(walletID);
    if (walletID) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "getUser", {
          method: "POST",
          mode: "cors",
          headers: new Headers({ "content-type": "application/json" }),
          body: JSON.stringify({ walletID: walletID }),
        });
        if (response.status === 200) {
          const data = await response.json();
          setUserData(data["userData"]);
          setUserRole(data["userData"]["role"]);
          console.log(data);
        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    if (publicKey) {
      getUserData(publicKey.toString());
    } else {
      navigate("/");
    }
  }, [publicKey]);

  return (
    <Sidebar
      id="sideBar"
      collapsedWidth="65px"
      style={{ position: "fixed", top: "120px", minHeight: "100vh", maxHeight: "160vh" }}
      collapsed={collapsed}
    >
      <Menu style={{ padding: "0" }}>
        {/* <MenuItem
          className="custom-menu-item"
          icon={<MenuOutlinedIcon />}
          onClick={() => setCollapsed(!collapsed)}
          style={{ textAlign: "center" }}
        >
         
        </MenuItem> */}
        <MenuItem
          className={
            localStorage.getItem("active_menu") == "dashboard"
              ? "custom-menu-item-active custom-menu-item"
              : "custom-menu-item"
          }
          onClick={() => handleMenuPage("dashboard")}
          component={<Link to="/dashboard" />}
        >
          {localStorage.getItem("active_menu") == "dashboard" &&
            <Icon.BarChartSteps className="active-sidebar-icons" />
          }
          {localStorage.getItem("active_menu") != "dashboard" &&
            <Icon.BarChartSteps className="non-active-sidebar-icons" />
          }
          {" "}
          Dashboard
        </MenuItem>

        {userRole == "super-admin" && (
          <>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "addproducts"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("addproducts")}
              component={<Link to="/add-products" />}
            >
              {localStorage.getItem("active_menu") == "addproducts" &&
                <Icon.BagPlus className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "addproducts" &&
                <Icon.BagPlus className="non-active-sidebar-icons" />
              }
              {" "}
              Add Products
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "product"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("product")}
              component={<Link to="/my-products" />}
            >
              {localStorage.getItem("active_menu") == "product" &&
                <Icon.BoxSeam className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "product" &&
                <Icon.BoxSeam className="non-active-sidebar-icons" />
              }

              {" "}
              My Products
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "orders"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("orders")}
              component={<Link to="/my-orders" />}
            >
              {localStorage.getItem("active_menu") == "orders" &&
                <Icon.CardChecklist className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "orders" &&
                <Icon.CardChecklist className="non-active-sidebar-icons" />
              }
              {" "}
              My Orders
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-cart"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-cart")}
              component={<Link to="/my-cart" />}
            >

              {localStorage.getItem("active_menu") == "my-cart" &&
                <Icon.Cart className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-cart" &&
                <Icon.Cart className="non-active-sidebar-icons" />
              }

              {" "}
              My Cart
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-address"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-address")}
              component={<Link to="/my-address" />}
            >

              {localStorage.getItem("active_menu") == "my-address" &&
                <Icon.GeoAlt className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-address" &&
                <Icon.GeoAlt className="non-active-sidebar-icons" />
              }
              {" "}
              My Address
            </MenuItem>
          </>
        )}
        {userRole == "creator" && (
          <>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "addproducts"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("addproducts")}
              component={<Link to="/add-products" />}
            >
              {localStorage.getItem("active_menu") == "addproducts" &&
                <Icon.BagPlus className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "addproducts" &&
                <Icon.BagPlus className="non-active-sidebar-icons" />
              }
              {" "}
              Add Products
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "product"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("product")}
              component={<Link to="/my-products" />}
            >
              {localStorage.getItem("active_menu") == "product" &&
                <Icon.BoxSeam className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "product" &&
                <Icon.BoxSeam className="non-active-sidebar-icons" />
              }{" "}
              My Products
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "orders"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("orders")}
              component={<Link to="/my-orders" />}
            >
              {localStorage.getItem("active_menu") == "orders" &&
                <Icon.CardChecklist className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "orders" &&
                <Icon.CardChecklist className="non-active-sidebar-icons" />
              }
              {" "}
              My Orders
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-cart"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-cart")}
              component={<Link to="/my-cart" />}
            >
              {localStorage.getItem("active_menu") == "my-cart" &&
                <Icon.Cart className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-cart" &&
                <Icon.Cart className="non-active-sidebar-icons" />
              }{" "}
              My Cart
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-address"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-address")}
              component={<Link to="/my-address" />}
            >
              {localStorage.getItem("active_menu") == "my-address" &&
                <Icon.GeoAlt className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-address" &&
                <Icon.GeoAlt className="non-active-sidebar-icons" />
              }{" "}
              My Address
            </MenuItem>
            {/* <MenuItem
              className={
                localStorage.getItem("active_menu") == "transactions"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("transactions")}
              component={<Link to="/transactions" />}
            >
              {localStorage.getItem("active_menu") == "transactions" &&
                <Icon.ArrowLeftRight className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "transactions" &&
                <Icon.ArrowLeftRight className="non-active-sidebar-icons" />
              }
              {" "}
              API
            </MenuItem> */}
          </>
        )}
        {userRole == "end_user" && (
          <>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "orders"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("orders")}
              component={<Link to="/my-orders" />}
            >
              {localStorage.getItem("active_menu") == "orders" &&
                <Icon.CardChecklist className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "orders" &&
                <Icon.CardChecklist className="non-active-sidebar-icons" />
              }{" "}
              My Orders
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-cart"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-cart")}
              component={<Link to="/my-cart" />}
            >
              {localStorage.getItem("active_menu") == "my-cart" &&
                <Icon.Cart className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-cart" &&
                <Icon.Cart className="non-active-sidebar-icons" />
              }{" "}
              My Cart
            </MenuItem>
            <MenuItem
              className={
                localStorage.getItem("active_menu") == "my-address"
                  ? "custom-menu-item-active custom-menu-item"
                  : "custom-menu-item"
              }
              onClick={() => handleMenuPage("my-address")}
              component={<Link to="/my-address" />}
            >
              {localStorage.getItem("active_menu") == "my-address" &&
                <Icon.GeoAlt className="active-sidebar-icons" />
              }
              {localStorage.getItem("active_menu") != "my-address" &&
                <Icon.GeoAlt className="non-active-sidebar-icons" />
              }{" "}
              My Address
            </MenuItem>
          </>
        )}

        {/* <MenuItem
          className={
            localStorage.getItem("active_menu") == "transactions"
              ? "custom-menu-item-active custom-menu-item"
              : "custom-menu-item"
          }
          onClick={() => handleMenuPage("transactions")}
          component={<Link to="/transactions" />}
        >
          {localStorage.getItem("active_menu") == "transactions" &&
            <Icon.Chat className="active-sidebar-icons" />
          }
          {localStorage.getItem("active_menu") != "transactions" &&
            <Icon.Chat className="non-active-sidebar-icons" />
          }{" "}
          Support
        </MenuItem> */}

      </Menu>
    </Sidebar>
  );
};
