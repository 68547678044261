import React, {
  useEffect,
  useCallback,
  useState,
  useReducer,
  useRef,
  useMemo,
  forwardRef,
} from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import { useProSidebar } from "react-pro-sidebar";
import { CompSideBar } from "../components/sidebar";
import { Row, Col, Card, Button, Form } from "react-bootstrap";
import reach_us_img from "../images/contact-us.png";
import { HelioPay } from "@heliofi/react";
// import AddBox from '@material-ui/icons/AddBox';

import MaterialTable from "material-table";
import product1 from "../images/products/product-image.png";

import { LAMPORTS_PER_SOL, PublicKey } from "@solana/web3.js";
import {
  WalletDisconnectButton,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";

import "./edit-product.css";




export const EditProducts = ({ publicKey }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  // const { collapseSidebar } = useProSidebar();

  const [storeId, setStoreId] = useState('');
  const [productId, SetProductId] = useState('');
  const [productTitle, setProductTitle] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [margin, setMargin] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [sellingPrice, setSellingPrice] = useState('');
  const [tags, setTags] = useState('');
  const [network, setNetwork] = useState('Solana');
  const [profit, setProfit] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [noOfItems, setNoOfItems] = useState('');
  const [purchaseLimit, setPurchaseLimit] = useState('');
  const [currencyList, setCurrencyList] = useState('');
  const [errors, setErrors] = useState({
    productTitle: "",
    storeUrl: "",
    storeDescription: "",
    storeBanner: "",
    storeProfile: "",
    website: "",
    discord: "",
    twitter: "",
    telegram: "",
    medium: "",
    youtube: "",
  });
  const handleMultiSelectCurrency = (e) => {

  }
  const calculate_profit_and_selling_price = (margin) => {
    // let selling_price =
    //   Number(baseProduct["price"]) +
    //   (margin / 100) * Number(baseProduct["price"]);
    // setSellingPrice(selling_price);
    // setProfit((selling_price - Number(baseProduct["price"])).toFixed(2));
  };
  const handleInputFocus = (field) => {
    // Clear the error message when the input field is focused
    const newErrors = { ...errors };
    newErrors[field] = "";
    setErrors(newErrors);
  };

  const saveDesignProduct = async (e) => {
    e.preventDefault();
    console.log("button click");
    if (productId) {
      const productData = {};
      productData["id"] = productId;
      productData["productName"] = productTitle;
      console.log(productData);
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "UpdateDesignProduct",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify(productData),
          }
        );
        if (response.status === 200) {
          console.log("saved")
          navigate("/my-products");
        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }
  const getProductByID = async () => {
    console.log(productId);
    if (productId) {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL + "GetDesignProductById",
          {
            method: "POST",
            mode: "cors",
            headers: new Headers({ "content-type": "application/json" }),
            body: JSON.stringify({ id: productId }),
          }
        );
        if (response.status === 200) {
          const data = await response.json();
          const productData = data['designProduct_details'];
          console.log(data['designProduct_details']);
          setProductTitle(productData['productName']);
          setSelectedCurrency(productData['acceptedCurrency'])
          setMargin(productData['profitMargin'])
          setSellingPrice(productData['price'])
        } else {
          console.log(response.status);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  useEffect(() => {
    var url_path = window.location.pathname;
    console.log(url_path);
    var product_id = url_path.split("/")[2];
    console.log(product_id);
    SetProductId(product_id);
  }, [navigate, storeId]);
  useEffect(() => {
    async function data() {
      await getProductByID();
    }
    data();
  }, [navigate, productId]);

  return (
    <>
      {true ? (
        <>
          <div>
            <CompSideBar publicKey={publicKey} />
          </div>
          <div className="mainpage-dashboard">
            <div className="container-fluid">
              <div className="row designer-product-container">
                <div className="col-md-12">
                  <div className="form-outer">
                    <form>
                      <div className="page">
                        <div className="field">
                          <div className="label">Product Title</div>
                          <input
                            value={productTitle}
                            onChange={(e) => setProductTitle(e.target.value)}
                            onFocus={() => handleInputFocus("productTitle")}
                            type="text"
                            placeholder="Enter The Product Title"
                            className="form-control"
                          />
                          {errors["productTitle"] && (
                            <div className="error">{errors["productTitle"]}</div>
                          )}
                        </div>
                        <div className="field">
                          <div className="row">
                            <div className="col-6">
                              <div className="label">Network</div>
                              <input
                                value={network}
                                // onChange={(e) => setProductTitle(e.target.value)}
                                // onFocus={() => handleInputFocus("network")}
                                className="form-control"
                                type="text"
                                readOnly
                              />
                              {errors["network"] && (
                                <div className="error">{errors["network"]}</div>
                              )}
                            </div>
                            <div className="col-6">
                              <div className="label">Currency</div>
                              <input
                                name="currency"
                                type="text"
                                value={selectedCurrency}
                                className="form-control"
                                readOnly={true}
                              />
                              {/* <Form.Select
                                as="select"
                                size="lg"
                                onChange={(e) => handleMultiSelectCurrency(e)}
                              /> */}
                              {errors["currency"] && (
                                <div className="error">{errors["currency"]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <div className="row">
                            <div className="col-4">
                              <div className="label">Margin</div>
                              <input
                                value={margin}
                                onChange={(e) => {
                                  setMargin(e.target.value);
                                  calculate_profit_and_selling_price(e.target.value);
                                }}
                                onFocus={() => handleInputFocus("margin")}
                                type="text"
                                className="form-control"
                              />
                              {errors["margin"] && (
                                <div className="error">{errors["margin"]}</div>
                              )}
                            </div>
                            <div className="col-4">
                              <div className="label">
                                Set Selling Price <sup> Per Item</sup>
                              </div>
                              <input
                                value={sellingPrice}
                                onChange={(e) => setSellingPrice(e.target.value)}
                                onFocus={() => handleInputFocus("sellingPrice")}
                                type="text"
                                readOnly
                                className="form-control"
                              />
                              {errors["sellingPrice"] && (
                                <div className="error">{errors["sellingPrice"]}</div>
                              )}
                            </div>
                            <div className="col-4">
                              <div className="label">
                                Profit <sup>Per Item</sup>
                              </div>
                              <input
                                value={profit}
                                onChange={(e) => setProfit(e.target.value)}
                                onFocus={() => handleInputFocus("profit")}
                                type="text"
                                readOnly
                                className="form-control"
                              />
                              {errors["profit"] && (
                                <div className="error">{errors["profit"]}</div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="field">
                          <div className="label">Product Description</div>
                          <input
                            value={productDescription}
                            onChange={(e) => setProductDescription(e.target.value)}
                            onFocus={() => handleInputFocus("productDescription")}
                            type="text"
                            placeholder="Enter The Product Description"
                            className="form-control"
                          />
                          {errors["productDescription"] && (
                            <div className="error">{errors["productDescription"]}</div>
                          )}
                        </div>
                        <div className="field">
                          <div className="label">Number Of Items</div>
                          <input
                            value={noOfItems}
                            onChange={(e) => setNoOfItems(e.target.value)}
                            onFocus={() => handleInputFocus("noOfItems")}
                            type="text"
                            placeholder="Enter The Number Of Items"
                            className="form-control"
                          />
                          {errors["noOfItems"] && (
                            <div className="error">{errors["noOfItems"]}</div>
                          )}
                        </div>
                        <div className="field">
                          <div className="label">Purchase Limit</div>
                          <input
                            value={purchaseLimit}
                            onChange={(e) => setPurchaseLimit(e.target.value)}
                            onFocus={() => handleInputFocus("purchaseLimit")}
                            type="text"
                            placeholder="Enter The Purchase Limit"
                            className="form-control"
                          />
                          {errors["purchaseLimit"] && (
                            <div className="error">{errors["purchaseLimit"]}</div>
                          )}
                        </div>
                       
                        <div className="field">
                          <div className="label">Tags</div>
                          <input
                            value={tags}
                            onChange={(e) => setTags(e.target.value)}
                            onFocus={() => handleInputFocus("tags")}
                            type="text"
                            className="form-control"
                          />
                          {errors["tags"] && <div className="error">{errors["tags"]}</div>}
                        </div>
                        <div>
                          <button
                            className="btn save-btn-product"
                            onClick={(e) => saveDesignProduct(e)}
                          >
                            Save Product
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id="kt_content_container"
            className="d-flex flex-column-fluid align-items-start "
          >
            <div
              className={`${isMobile
                  ? "px-5"
                  : "d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep first"
                }`}
            >
              <div
                className={`${isMobile ? "" : "d-flex flex-column flex-lg-row-fluid py-10"
                  }`}
              >
                <div
                  className={`${isMobile
                      ? ""
                      : "d-flex flex-center flex-column flex-column-fluid"
                    }`}
                >
                  <div className="row">
                    <div className="col-12">
                      <div className="create-raffle-wallet-connet">
                        <img
                          className="reach-us-img"
                          alt=""
                          src={reach_us_img}
                        />
                        <div className="reach-us-to-whitelist">
                          Please connect your wallet.
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="wallet-button">
                        <WalletMultiButton className="btn connect-wallet-btn fw-bolder w-100 w-lg-auto" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}